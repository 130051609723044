import {
  PRODUCT_VIDEO_PROVIDER,
  PRODUCT_VIDEO_THUMB_TYPE,
  VIMEO_URL_REGEX,
  YOUTUBE_URL_REGEX
} from '../shared/constants';
import {URLS} from '../api/urls';

export enum YOUTUBE_THUMB_VERSION {
  DEFAULT = 'default',
  HQ = 'hqdefault',
  MQ = 'mqdefault',
  SD = 'sddefault',
  MAX = 'maxresdefault'
}

export enum VIMEO_THUMB_VERSION {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small'
}

export class VideoUtils {
  static getYoutubeId(url: string): string {
    const [a, , b] = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (b !== undefined) {
      return b.split(/[^0-9a-z_-]/i)[0];
    } else {
      return a;
    }
  }

  static getVimeoId(url: string): string {
    return url.match(VIMEO_URL_REGEX)[4];
  }

  static getYoutubeVideoThumbnail(url: string, version = YOUTUBE_THUMB_VERSION.HQ): string {
    const id = VideoUtils.getYoutubeId(url);
    return `https://img.youtube.com/vi/${id}/${version}.jpg`;
  }

  static getVimeoThumbnail(url: string, version = VIMEO_THUMB_VERSION.LARGE): string {
    const id = VideoUtils.getVimeoId(url);
    return `https://vumbnail.com/${id}_${version}.jpg`;
  }

  static getThumbnailByProvider(provider: PRODUCT_VIDEO_PROVIDER, url: string, type: PRODUCT_VIDEO_THUMB_TYPE) {
    switch (provider) {
      case PRODUCT_VIDEO_PROVIDER.YOUTUBE: {
        const version =
          type === PRODUCT_VIDEO_THUMB_TYPE.VIDEO_PREV ? YOUTUBE_THUMB_VERSION.HQ : YOUTUBE_THUMB_VERSION.DEFAULT;
        return VideoUtils.getYoutubeVideoThumbnail(url, version);
      }
      case PRODUCT_VIDEO_PROVIDER.VIMEO: {
        const version =
          type === PRODUCT_VIDEO_THUMB_TYPE.VIDEO_PREV ? VIMEO_THUMB_VERSION.LARGE : VIMEO_THUMB_VERSION.MEDIUM;
        return VideoUtils.getVimeoThumbnail(url, version);
      }
      default: {
        return URLS.OVERLAY_GALLERY_VIDEO_IMAGE_FALLBACK;
      }
    }
  }

  static generateURL(url: string, platform: PRODUCT_VIDEO_PROVIDER): string | undefined {
    switch (platform) {
      case PRODUCT_VIDEO_PROVIDER.YOUTUBE:
        return `https://www.youtube.com/embed/${VideoUtils.getYoutubeId(url)}`;
      case PRODUCT_VIDEO_PROVIDER.VIMEO:
        return `https://player.vimeo.com/${VideoUtils.getVimeoId(url)}`;
      case PRODUCT_VIDEO_PROVIDER.DIRECT_LINK:
        return url;
      default:
        console.error('The existing link is not correct');
        return undefined;
    }
  }

  static findVideoPlatform(url: string): PRODUCT_VIDEO_PROVIDER {
    if (!url) {
      return undefined;
    }

    if (url.match(YOUTUBE_URL_REGEX)) {
      return PRODUCT_VIDEO_PROVIDER.YOUTUBE;
    }
    if (url.match(VIMEO_URL_REGEX)) {
      return PRODUCT_VIDEO_PROVIDER.VIMEO;
    }
    return PRODUCT_VIDEO_PROVIDER.DIRECT_LINK;
  }
}
