import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';

import {ShopifyService} from '../../../../api/services/shopify.service';
import {QuantityPickerView} from './quantity-picker.view';
import {ShoppingCartVariant} from '../../../../interfaces/shopping-cart.interface';
import {InputEventUtils} from '../../../../utils/input_event_utils';
import {ShoppingCartService} from '../../../../api/services/shopping-cart.service';
import {AppUtils} from '../../../../utils/app_utils';
import {Product} from '../../../../interfaces/product.interface';

export class QuantityPicker {
  private increaseButton: HTMLElement;
  private decreaseButton: HTMLElement;
  private quantityLabel: HTMLElement;
  private limitationLabel: HTMLElement;
  private variant: ShoppingCartVariant;
  private quantity: number = 1;
  private availableQuantity: number = 0;
  private readonly selectedProductSubjectRef: Function;

  constructor(
    private product: Product,
    private mainContainer: HTMLElement,
    private shopifyService: ShopifyService,
    private shoppingCartService: ShoppingCartService
  ) {
    this.changeQuantity = this.changeQuantity.bind(this);
    this.initComponent();

    const quantityButtons = this.mainContainer.querySelectorAll('.shopify-product-details-item-quantity-button');
    quantityButtons.forEach(button => {
      InputEventUtils.addSelectEvents(button, this.changeQuantity);
    });

    this.selectedProductSubjectRef = pipe(
      this.shopifyService.selectedProductSubject,
      subscribe({
        next: (variant: any) => {
          if (variant) {
            this.variant = variant;
            this.quantity = 1;
            this.updateAvailableQuantity();
            this.updateQuantityLabel();
            this.updateDisableState();
          }
        }
      })
    );
  }

  get PRODUCT_QUANTITY() {
    return this.quantity;
  }

  get PRODUCT_VARIANT_ID() {
    return this.variant?.variant_id;
  }

  get PRODUCT_VARIANT_INVENTORY_QUANTITY() {
    return this.variant?.per_order_limit || this.variant?.inventory_quantity;
  }

  get PRODUCT_VARIANT_AVAILABLE_QUANTITY() {
    return this.availableQuantity;
  }

  private initComponent() {
    const quantityPickerContainer = this.mainContainer.querySelector('.app-shopify-quantity-picker-container');
    const quantityPicker = document.createElement('div');
    quantityPickerContainer.append(quantityPicker);
    quantityPicker.outerHTML = QuantityPickerView(this.product?.per_order_limit);
    this.increaseButton = quantityPickerContainer.querySelector('.app-shopping-cart-item-increase');
    this.decreaseButton = quantityPickerContainer.querySelector('.app-shopping-cart-item-decrease');
    this.quantityLabel = quantityPickerContainer.querySelector('.shopify-product-details-item-quantity-value');
    this.limitationLabel = quantityPickerContainer.querySelector('.shopify-product-details-item-quantity-limit');
  }

  private changeQuantity(e) {
    const operation = e.target.classList.contains('app-shopping-cart-item-increase') ? 'increase' : 'decrease';
    switch (operation) {
      case 'increase': {
        if (this.quantity + 1 <= this.variant.inventory_quantity) {
          if (this.variant?.per_order_limit && this.quantity + 1 > this.variant?.per_order_limit) {
            return;
          }
          this.quantity++;
          this.updateQuantityLabel();
        }
        break;
      }
      case 'decrease': {
        if (this.quantity - 1 >= 1) {
          this.quantity--;
          this.updateQuantityLabel();
        }
        break;
      }
    }
    this.updateDisableState();
  }

  private updateQuantityLabel() {
    this.quantityLabel.innerText = this.quantity.toString();
    if (this.variant?.isAutoSelected) {
      this.limitationLabel?.classList.add('is-hidden');
    } else {
      this.limitationLabel?.classList.remove('is-hidden');
    }
  }

  private updateDisableState() {
    this.variant?.per_order_limit && this.quantity >= this.variant?.per_order_limit ||
    this.quantity >= this.variant?.inventory_quantity ?
      this.increaseButton.classList.add('disabled') :
      this.increaseButton.classList.remove('disabled');

    this.quantity <= 1 ?
      this.decreaseButton.classList.add('disabled') :
      this.decreaseButton.classList.remove('disabled');
  }

  updateAvailableQuantity() {
    const variantsWithSameId = this.shoppingCartService.shoppingCart?.checkout.filter(item => item.variantId === this.variant?.variant_id);
    const overallQuantity = AppUtils.overallQuantity(this.shoppingCartService.shoppingCart, this.variant, this.variant?.per_order_limit);
    if (variantsWithSameId?.length) {
      if (this.variant.per_order_limit) {
        this.availableQuantity = this.variant.per_order_limit - overallQuantity;
        return;
      }
      this.availableQuantity = this.variant.inventory_quantity - overallQuantity;
    } else {
      if (this.variant?.per_order_limit) {
        this.availableQuantity = this.variant.per_order_limit - overallQuantity;
        return;
      }
      this.availableQuantity = this.variant?.inventory_quantity;
    }
  }

  dispose() {
    this.selectedProductSubjectRef();
  }
}
