import {ApiService} from '../api';
import {URLS, UrlUtils} from '../urls';

interface CountryDataInterface {
  detected_values: {
    country_name: string;
    country: {
      handle: string;
      name: string;
    }
  };
  features: {};
  suggestions: {};
}

export class OutOfStockService extends ApiService {
  getCountry(shopName: string) {
    const url = UrlUtils.insertValueToUrl(URLS.SHOPIFY_COUNTRY, shopName);

    return this.get(url)
      .then(resp => resp.json())
      .then((data: CountryDataInterface) => data?.detected_values?.country?.handle)
      .catch(e => console.error(e));
  }
}
