import {CurvesMetaData, ItemData} from '../interfaces/planogram.interface';
import {SphereItem} from '../sphere_item';
import {CurveGeometry} from '../geometries/curve_geometry';
import standardVertexShader from '../../shaders/standard_vertex_shader.glsl';
import curveFragmentShader from '../../shaders/curve_fragment_shader.glsl';
import {AppUtils} from '../utils/app_utils';
import {Planogram} from '../planogram';
import {DoubleSide, Group, Mesh, ShaderMaterial, Vector3} from 'three';

export class CurveComponent extends SphereItem {
  constructor(itemData: ItemData<CurvesMetaData>, planogram: Planogram) {
    super(itemData, planogram);
  }

  onClick(position: Vector3): void {}

  onVisibilityChange(): void {}

  isDraggable(): boolean {
    return false;
  }

  createMesh() {
    const {points, thickness, scale, opacity, dashed, soft, color} = this.data as CurvesMetaData;

    this.geometry = new CurveGeometry(
      points,
      thickness,
      scale,
      this.x + this.width / 2,
      this.y + this.height / 2,
      this.planogram.largeRadius,
      this.planogram.fixedRadius,
      this.planogram.height,
      this.planogram.width
    );

    this.material = new ShaderMaterial({
      vertexShader: standardVertexShader,
      fragmentShader: curveFragmentShader,
      uniforms: {
        color: {value: AppUtils.hex2rgbArray(color)},
        opacity: {value: opacity},
        thickness: {value: thickness},
        curveLength: {value: this.geometry.curveLength},
        dashed: {value: dashed},
        soft: {value: soft}
      },
      side: DoubleSide,
      transparent: true,
      depthTest: false,
      depthWrite: false
    });

    const mesh = new Mesh(this.geometry, this.material);
    mesh.userData = {
      component: this
    };
    mesh.renderOrder = this.renderOrder;

    if (this.hasInput()) {
      mesh.layers.enable(2);
    }

    this.object3D = new Group();
    this.object3D.renderOrder = this.renderOrder;
    this.object3D.add(mesh);

    return Promise.resolve();
  }
}
