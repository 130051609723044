import makeBehaviorSubject from 'callbag-behavior-subject';

import {ApiService} from '../api';
import {URLS, UrlUtils} from '../urls';
import {ACCESS_TOKEN_KEY, ACCOUNT_OVERLAY_TYPE} from '../../shared/constants';
import {UserAccountData} from '../../interfaces/user-account-data.interface';
import {sphereEventHandler} from '../../custom_event_utils';
import {SPHERE_EVENT_NAMES} from '../../event-names';
import {L10nUtils} from '../../utils/l10n_utils';

export class AccountService extends ApiService {
  accountActiveStateSubject: makeBehaviorSubject<boolean>;
  accountLoadingStateSubject: makeBehaviorSubject<boolean>;
  accountOverlayTypeSubject: makeBehaviorSubject<ACCOUNT_OVERLAY_TYPE>;
  accountUserDataSubject: makeBehaviorSubject<UserAccountData>;

  constructor() {
    super();
    this.accountActiveStateSubject = makeBehaviorSubject(false);
    this.accountLoadingStateSubject = makeBehaviorSubject(false);
    this.accountOverlayTypeSubject = makeBehaviorSubject(ACCOUNT_OVERLAY_TYPE.SIGN_IN);
    this.accountUserDataSubject = makeBehaviorSubject(null);
  }

  setActiveState(state) {
    sphereEventHandler.emit(state ? SPHERE_EVENT_NAMES.ACCOUNT.OPEN : SPHERE_EVENT_NAMES.ACCOUNT.CLOSE);
    this.accountActiveStateSubject(1, state);
  }

  setLoadingState(state) {
    this.accountLoadingStateSubject(1, state);
  }

  setOverlayType(type: ACCOUNT_OVERLAY_TYPE) {
    this.accountOverlayTypeSubject(1, type);
  }

  setUserData(userData: UserAccountData) {
    this.accountUserDataSubject(1, userData);
  }

  signIn(email, password) {
    const currentLang = L10nUtils.getCurrentLanguage();
    const url = UrlUtils.getUrlWithQueries(URLS.USER_SIGN_IN, {lang: currentLang});
    const params = {email, password};
    return this.post(url, params)
      .then(this.saveAccessToken)
      .then(resp => resp.json());
  }

  signUp(email, password, firstName?, lastName?) {
    const currentLang = L10nUtils.getCurrentLanguage();
    const url = UrlUtils.getUrlWithQueries(URLS.USER_SIGN_UP, {lang: currentLang});
    const params = {email, password, first_name: firstName, last_name: lastName};
    return this.post(url, params)
      .then(this.saveAccessToken)
      .then(resp => resp.json());
  }

  logOut() {
    return this.get(URLS.USER_LOG_OUT)
      .then(this.removeAccessToken)
      .then(resp => resp.json());
  }

  resetPassword(email) {
    const params = {email};
    return this.post(URLS.USER_RESET_PASSWORD, params)
      .then(resp => resp.json());
  }

  verifyToken() {
    const currentLang = L10nUtils.getCurrentLanguage();
    const url = UrlUtils.getUrlWithQueries(URLS.USER_VERIFY_TOKEN, {lang: currentLang});
    return this.get(url)
      .then(resp => resp.json());
  }

  saveAccessToken(resp: Response) {
    const token = resp.headers.get(ACCESS_TOKEN_KEY);
    window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
    return resp;
  }

  removeAccessToken(resp: Response) {
    window.localStorage.removeItem(ACCESS_TOKEN_KEY);
    return resp;
  }
}
