import {RotationUtils} from './utils/rotation_utils';
import {SphereGeometry} from './geometries/sphere_geometry';
import {Planogram} from './planogram';
import {MathUtils, Vector2, Vector3} from 'three';

export class ViewableLimits {
  private topLimitPoint: Vector3;
  private bottomLimitPoint: Vector3;
  private maxTilt: number;
  private minTilt: number;

  constructor(private planogram) {
    this.topLimitPoint = this._calculateFlattenedLimitPoint(this.planogram.topLimit);
    this.bottomLimitPoint = this._calculateFlattenedLimitPoint(this.planogram.bottomLimit);
  }

  newAngle(camera, currentAngle, zoomAdjustAngle, tiltAmount) {
    this._updateTiltLimits(camera.position, camera.fov);
    if (currentAngle + zoomAdjustAngle + tiltAmount > this.maxTilt) {
      return this.maxTilt - zoomAdjustAngle;
    } else if (currentAngle + zoomAdjustAngle + tiltAmount < this.minTilt) {
      return this.minTilt - zoomAdjustAngle;
    }
    return currentAngle + tiltAmount;
  }

  maxFOV(camPosition) {
    this.topLimitPoint = this._calculateFlattenedLimitPoint(this.planogram.topLimit);
    this.bottomLimitPoint = this._calculateFlattenedLimitPoint(this.planogram.bottomLimit);
    const points = this._limitPointsFromOrigin(camPosition);
    this.maxTilt = Math.PI - points.positive.angle();
    this.minTilt = Math.PI - points.negative.angle();
    return MathUtils.radToDeg(this.maxTilt - this.minTilt);
  }

  _updateTiltLimits(camPosition, fov) {
    const fovOffset = MathUtils.degToRad(fov / 2.0);
    const point = this._limitPointsFromOrigin(camPosition);
    this.maxTilt = Math.PI - point.positive.angle() - fovOffset;
    this.minTilt = Math.PI - point.negative.angle() + fovOffset;
    if (this.maxTilt < this.minTilt) {
      this.maxTilt = (this.maxTilt + this.minTilt) / 2;
      this.minTilt = this.maxTilt;
    }
  }

  _limitPoint(limitHeight) {
    const geom = new SphereGeometry(
      Planogram.ALPHA,
      this.planogram.largeRadius,
      this.planogram.fixedRadius,
      1,
      1,
      SphereGeometry.calcAzimuthStartRadians(this.planogram.width / 2, 1, this.planogram.width),
      SphereGeometry.calcAzimuthLengthRadians(1, this.planogram.width),
      this.planogram.height,
      limitHeight,
      this.planogram.topLimit - this.planogram.bottomLimit,
      false
    );
    const point = new Vector3(geom.vertices[0], geom.vertices[1], geom.vertices[2]);
    geom.dispose();
    return point;
  }

  getViewPortLimits() {}

  _limitPointsFromOrigin(camPosition) {
    const clonedCamPosition = camPosition.clone();
    const flatCamPosition = RotationUtils.calculateZXFlattenAngle(clonedCamPosition);
    clonedCamPosition.applyAxisAngle(new Vector3(0, 1, 0), flatCamPosition);

    const positiveLimitFromOrigin = new Vector2(
      this.topLimitPoint.x - clonedCamPosition.x,
      this.topLimitPoint.y - clonedCamPosition.y
    );
    const negativeLimitFromOrigin = new Vector2(
      this.bottomLimitPoint.x - clonedCamPosition.x,
      this.bottomLimitPoint.y - clonedCamPosition.y
    );
    return {positive: positiveLimitFromOrigin, negative: negativeLimitFromOrigin};
  }

  _calculateFlattenedLimitPoint(limitHeight) {
    const limitPoint = this._limitPoint(limitHeight);
    const flatLimit = RotationUtils.calculateZXFlattenAngle(limitPoint);
    limitPoint.applyAxisAngle(new Vector3(0, 1, 0), flatLimit - Math.PI);
    return limitPoint;
  }
}
