import {Vector2, Box2} from 'three';

export function modulo(a: number, m: number) {
  return ((a % m) + m) % m;
}

function moduloDistance(a: number, b: number, m: number) {
  return Math.min(modulo(Math.abs(b - a), m), modulo(Math.abs(-a - m + b), m), modulo(Math.abs(m - a + b), m));
}

export class Modulo {
  private divisor: Vector2;

  constructor(divisor: Vector2) {
    this.divisor = divisor.clone();
  }

  moduloX(x: number) {
    return modulo(x, this.divisor.x);
  }

  moduloY(y: number) {
    return modulo(y, this.divisor.y);
  }

  moduloV(vector: Vector2) {
    vector.x = modulo(vector.x, this.divisor.x);
    vector.y = modulo(vector.y, this.divisor.y);
    return vector;
  }

  moduloB(box: Box2) {
    this.moduloV(box.min);
    this.moduloV(box.max);
    return box;
  }

  distanceX(a: number, b: number) {
    return moduloDistance(a, b, this.divisor.x);
  }

  distanceY(a: number, b: number) {
    return moduloDistance(a, b, this.divisor.y);
  }

  distanceV(a: Vector2, b: Vector2) {
    return new Vector2(this.distanceX(a.x, b.x), this.distanceY(a.y, b.y));
  }

  distanceVB(point: Vector2, box: Box2) {
    const pointModulo = this.moduloV(point.clone());
    const viewportCenter = this.moduloV(box.getCenter(new Vector2()));
    return this.distanceV(pointModulo, viewportCenter);
  }

  normalDistanceVB(point: Vector2, box: Box2) {
    const distance = this.distanceVB(point, box);
    // account for viewport being a non-square rectangle
    const viewportSize = this.sizeB(box);
    distance.x *= viewportSize.y / viewportSize.x;
    return distance;
  }

  sizeB(box: Box2) {
    return this.distanceV(box.min, box.max);
  }

  iterateB(box: Box2, callback: (v: Vector2) => void) {
    const start = this.moduloV(box.min.clone());
    const stop = this.moduloV(box.max.clone());
    const it = start.clone();
    do {
      callback(it.clone());
      if (it.y === stop.y) {
        it.y = start.y;
        it.x = this.moduloX(it.x + 1);
      } else {
        it.y = this.moduloY(it.y + 1);
      }
    } while (!(it.x === stop.x && it.y === stop.y));
    return box;
  }
}
