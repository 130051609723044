export class HTMLUtils {
  static setScale(htmlElement: HTMLElement, percentage = 100): void {
    if (!htmlElement) {
      console.warn('Argument is not HTMLElement.');
      return;
    }

    const parsedValue = parseInt(percentage.toString(), 10) / 100;
    const root = document.documentElement;
    root.style.setProperty(`--control-button-scale`, parsedValue.toString());
    htmlElement.setAttribute('style', `transform: scale(${parsedValue})`);
  }

  static removeChildElements(container: Element): void {
    while (container.lastChild) {
      container.removeChild(container.lastChild);
    }
  }
}

export function appendHtml(element: HTMLElement, html: string) {
  const template = document.createElement('div');
  template.innerHTML = html.trim();
  for (let i = 0; i < template.childNodes.length; i++) {
    element.appendChild(template.childNodes[i]);
  }
}
