import {URLS} from './api/urls';
import {sphereEventHandler} from './custom_event_utils';
import {SPHERE_EVENT_NAMES} from './event-names';
import {OtherAsset} from './interfaces/planogram.interface';
import {Planogram} from './planogram';
import {CookiesManagement} from './cookies_management';

function createImage(title: OtherAsset['title'], defaultSrc: string, assets: OtherAsset[]): HTMLImageElement {
  const img = document.createElement('img');
  img.src = assets.find(asset => asset.title === title)?.url ?? defaultSrc;
  return img;
}

function toggleVisibility(element: HTMLElement, isVisible: boolean): void {
  if (isVisible) {
    element.classList.remove('is-hidden');
  } else {
    element.classList.add('is-hidden');
  }
}

function updateButtonImage(button: HTMLElement, oldImg: HTMLImageElement, newImg: HTMLImageElement): void {
  button.removeChild(oldImg);
  button.appendChild(newImg);
}

function loadAudio(audioElement: HTMLAudioElement, src: string): void {
  audioElement.src = src;
}

const interruptEvents = [
  SPHERE_EVENT_NAMES.VIDEO.PLAY_WITH_AUDIO,
  SPHERE_EVENT_NAMES.OVERLAY.SHOW_CONTENT,
  SPHERE_EVENT_NAMES.CART.OPEN,
  SPHERE_EVENT_NAMES.ACCOUNT.OPEN
];

const resumeEvents = [
  SPHERE_EVENT_NAMES.VIDEO.STOP_WITH_AUDIO,
  SPHERE_EVENT_NAMES.OVERLAY.CLOSE_CONTENT,
  SPHERE_EVENT_NAMES.CART.CLOSE,
  SPHERE_EVENT_NAMES.ACCOUNT.CLOSE
];

export class SphereAudio {
  private environmentalAudio: HTMLAudioElement;
  private actionAudio: HTMLAudioElement;
  private muteButton: HTMLElement;
  private stopButton: HTMLElement;
  private muteImg: HTMLImageElement;
  private unmuteImg: HTMLImageElement;
  private environmentalAudioSrc: string;
  private isEnvironmentalAudioPlaying: boolean = false;
  private isActionAudioPlaying: boolean = false;
  private isEnvironmentalAudioMuted: boolean = true;
  private interruptionCount: number = 0;
  private muteRef: Function;
  private unmuteRef: Function;

  constructor(planogram: Planogram) {
    this.initButtons(planogram);
    this.initImages(planogram);
    this.initAudio(planogram);
  }

  playActionAudio(src: string): void {
    loadAudio(this.actionAudio, src);
    this.actionAudio.muted = false;
    this.environmentalAudio.muted = true;
    this.actionAudio.play().then(() => {
      this.isActionAudioPlaying = true;
    });
    this.showStopButton();
  }

  private mute(): void {
    if (!this.isEnvironmentalAudioMuted && this.environmentalAudioSrc) {
      this.isEnvironmentalAudioMuted = true;
      this.updateAudioState();
    }
  }

  private unmute(): void {
    if (this.isEnvironmentalAudioMuted && this.environmentalAudioSrc) {
      this.isEnvironmentalAudioMuted = false;
      this.updateAudioState();
    }
  }

  private toggleMute(): void {
    this.isEnvironmentalAudioMuted = !this.isEnvironmentalAudioMuted;
    this.updateAudioState();
  }

  private updateAudioState(): void {
    this.environmentalAudio.muted =
      this.isEnvironmentalAudioMuted || this.isActionAudioPlaying || this.interruptionCount > 0;
    if (!this.isEnvironmentalAudioPlaying && !this.environmentalAudio.muted) {
      this.environmentalAudio.play().then(() => (this.isEnvironmentalAudioPlaying = true));
    }
    updateButtonImage(
      this.muteButton,
      this.isEnvironmentalAudioMuted ? this.unmuteImg : this.muteImg,
      this.isEnvironmentalAudioMuted ? this.muteImg : this.unmuteImg
    );
  }

  private initAudio(planogram: Planogram): void {
    this.environmentalAudio = document.createElement('audio');
    // safari IOS will pause audio if another media element starts playing
    this.environmentalAudio.onpause = () => (this.isEnvironmentalAudioPlaying = false);
    this.actionAudio = document.createElement('audio');
    this.actionAudio.volume = planogram.volume * 0.01;
    this.environmentalAudio.volume = planogram.volume * 0.01;
    this.actionAudio.onended = () => this.stopActionAudio();
    this.muteRef = this.mute.bind(this);
    this.unmuteRef = this.unmute.bind(this);

    this.environmentalAudioSrc = planogram.audioSettings?.url;

    interruptEvents.forEach(event => {
      sphereEventHandler.listen(event, this.handleInterrupt);
    });
    resumeEvents.forEach(event => {
      sphereEventHandler.listen(event, this.handleResume);
    });
    sphereEventHandler.listen(SPHERE_EVENT_NAMES.COOKIES.ALLOW_AUDIO, this.unmuteRef);
    sphereEventHandler.listen(SPHERE_EVENT_NAMES.COOKIES.DISABLE_AUDIO, this.muteRef);

    if (this.environmentalAudioSrc) {
      this.muteButton.appendChild(this.muteImg);
      this.showMuteButton();
      loadAudio(this.environmentalAudio, planogram.audioSettings.url);
      this.environmentalAudio.muted = true;
      this.environmentalAudio.loop = true;
      this.environmentalAudio.preload = 'auto';
    }
  }

  private initButtons(planogram: Planogram): void {
    this.muteButton = document.getElementById('audio-mute-button');
    this.muteButton.onclick = () => {
      CookiesManagement.allowAudio = CookiesManagement.initialAudioValue = !CookiesManagement.allowAudio;
      this.toggleMute();
    };
    this.stopButton = document.getElementById('audio-stop-button');
    this.stopButton.onclick = () => this.stopActionAudio();
    this.muteButton.style.backgroundColor = planogram.audioBackgroundColor;
    this.stopButton.style.backgroundColor = planogram.audioBackgroundColor;
  }

  private initImages(planogram: Planogram): void {
    const stopImg = createImage('stop', URLS.AUDIO_STOP_ICON, planogram.otherAssets);
    this.stopButton.appendChild(stopImg);

    this.muteImg = createImage('mute', URLS.AUDIO_MUTE_ICON, planogram.otherAssets);
    this.unmuteImg = createImage('unmute', URLS.AUDIO_UNMUTE_ICON, planogram.otherAssets);
  }

  private stopActionAudio(): void {
    this.actionAudio.src = '';
    this.isActionAudioPlaying = false;
    this.environmentalAudio.muted = this.isEnvironmentalAudioMuted;
    this.hideStopButton();
  }

  private handleInterrupt = () => {
    this.interruptionCount++;
    if (this.isActionAudioPlaying) this.stopActionAudio();
    this.environmentalAudio.muted = true;
  };

  private handleResume = () => {
    this.interruptionCount--;
    if (this.interruptionCount <= 0) {
      this.interruptionCount = 0;
      this.environmentalAudio.muted = this.isEnvironmentalAudioMuted;
      if (!this.isEnvironmentalAudioPlaying) {
        this.environmentalAudio.play().then(() => (this.isEnvironmentalAudioPlaying = true));
      }
    }
  };

  private showMuteButton(): void {
    toggleVisibility(this.muteButton, true);
  }
  private hideMuteButton(): void {
    toggleVisibility(this.muteButton, false);
  }
  private showStopButton(): void {
    toggleVisibility(this.stopButton, true);
  }
  private hideStopButton(): void {
    toggleVisibility(this.stopButton, false);
  }

  private disposed = false;

  dispose(): void {
    if (this.disposed) return;
    this.disposed = true;

    this.environmentalAudio.pause();
    this.environmentalAudio = null;
    this.actionAudio.pause();
    this.actionAudio = null;
    this.muteImg.remove();
    this.unmuteImg.remove();
    this.muteButton.onclick = null;
    this.stopButton.onclick = null;
    this.stopButton.firstChild?.remove();
    this.hideStopButton();
    this.hideMuteButton();
    interruptEvents.forEach(event => {
      sphereEventHandler.off(event, this.handleInterrupt);
    });
    resumeEvents.forEach(event => {
      sphereEventHandler.off(event, this.handleResume);
    });
    sphereEventHandler.off(SPHERE_EVENT_NAMES.COOKIES.ALLOW_AUDIO, this.unmuteRef);
    sphereEventHandler.off(SPHERE_EVENT_NAMES.COOKIES.DISABLE_AUDIO, this.muteRef);
  }
}
