import {Animation} from './animation';
import {TransitionType} from '../interfaces/planogram.interface';
import {CubicBezierCurve, Vector2, Vector3} from 'three';

function getCurve(transitionType: TransitionType): CubicBezierCurve {
  const points = ZoomToAnimation.ANIMATION_CURVES[transitionType];
  return new CubicBezierCurve(
    new Vector2(0, 0),
    new Vector2(points[0], points[1]),
    new Vector2(points[2], points[3]),
    new Vector2(1, 1)
  );
}

export class ZoomToAnimation extends Animation {
  private startTime: number;
  private duration: number;
  private delay: number;
  private startPoint: Vector3;
  private endPoint: Vector3;
  private startFov: number;
  private endFov: number;
  private animationComplete: boolean;
  private curve: CubicBezierCurve;
  private panBeforeZoom: boolean;
  private currentTarget: Vector3;

  static ANIMATION_CURVES: Record<TransitionType, number[]> = {
    linear: [0, 0, 1, 1],
    easeIn: [0.42, 0, 1, 1],
    easeOut: [0, 0.3, 0.6, 1],
    easeInOut: [0.42, 0, 0.58, 1],
    bounce: [0.34, 1.56, 0.64, 1]
  };

  constructor(
    currentFOV: number,
    currentPoint: Vector3,
    targetFOV: number,
    targetPoint: Vector3,
    endCallback: Function, // TODO: refactor to use promises/chains instead of callback
    zoomStopPoint: number,
    delay: number = 0,
    duration: number,
    transitionType: TransitionType,
    panBeforeZoom: boolean
  ) {
    super(endCallback);

    this.startTime = Date.now();
    this.duration = duration;
    this.delay = delay;
    this.startPoint = currentPoint;
    this.endPoint = targetPoint;
    this.startFov = currentFOV;
    this.endFov = targetFOV / zoomStopPoint;
    this.panBeforeZoom = panBeforeZoom;
    this.animationComplete = false;
    this.curve = getCurve(transitionType);
    this.currentTarget = this.startPoint.clone();
  }

  update() {
    if (this.animationComplete) {
      return;
    }

    const currentTime = Date.now();
    const elapsedTime = currentTime - this.startTime;

    if (elapsedTime < this.delay) {
      return {fov: this.startFov, targetPoint: {point: this.startPoint}};
    }

    const progress = Math.min((elapsedTime - this.delay) / this.duration, 1);

    if (progress >= 1) {
      this.animationComplete = true;
    }

    let currentFov = this.startFov;
    const bezierProgress = this.curve.getPoint(progress).y;

    if (this.panBeforeZoom) {
      currentFov = this.startFov + (this.endFov - this.startFov) * bezierProgress;
      this.currentTarget.lerpVectors(this.currentTarget, this.endPoint, bezierProgress);
    } else {
      currentFov = this.startFov + (this.endFov - this.startFov) * bezierProgress;
      this.currentTarget.lerpVectors(this.startPoint, this.endPoint, bezierProgress);
    }

    return {fov: currentFov, targetPoint: {point: this.currentTarget}};
  }
}
