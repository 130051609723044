import {ApiService} from '../api';
import {URLS, UrlUtils} from '../urls';
import {L10nUtils} from '../../utils/l10n_utils';
import {CookiesPopupData} from '../../interfaces/cookies.interface';

const storedSettings: Map<string, CookiesPopupData> = new Map();

export class CookiesService extends ApiService {

  getSettings(): Promise<CookiesPopupData> {
    const currentLang = L10nUtils.getCurrentLanguage();
    const settings = CookiesService.getSettings(currentLang);
    const url = UrlUtils.insertValueToUrl(URLS.COOKIES_SETTINS, currentLang);

    if (settings) {
      return Promise.resolve(settings);
    }

    return this.get(url)
      .then(resp => resp.json())
      .then(data => {
        CookiesService.setSettings(currentLang, data);

        return data;
      });
  }

  protected static getSettings(languageCode) {
    return storedSettings.get(languageCode);
  }

  protected static setSettings(languageCode, data) {
    storedSettings.set(languageCode, data);
  }
}
