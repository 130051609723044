import {InputEventUtils} from '../../utils/input_event_utils';
import {ACCOUNT_OVERLAY_TYPE} from '../../shared/constants';
import {AccountBaseOverlay} from '../account-base-overlay/account-base-overlay';
import {Metrics} from '../../metrics';
import {AppState} from '../../shared/app.state';
import {MATOMO_EVENT_NAMES} from '../../metric-events';

export class AccountSignUp extends AccountBaseOverlay {
  protected init() {
    const linkElToSignIn = this.wrapperEl.querySelector('.link-to-sign-in');

    InputEventUtils.addSelectEvents(
      linkElToSignIn,
      () => {
        Metrics.storeTheEvent(
          AppState.planogramName,
          'open',
          MATOMO_EVENT_NAMES.OPEN_LOG_IN
        );
        this.accountService.setOverlayType(ACCOUNT_OVERLAY_TYPE.SIGN_IN)
      }
    );
}

  protected onSubmit(e) {
    Metrics.storeTheEvent(
      AppState.planogramName,
      'click',
      MATOMO_EVENT_NAMES.CLICK_USER_PROFILE_OVERLAY_CREATE_ACC
    );
    e.preventDefault();
    const data = new FormData(this.formEl);
    const getValue = key => data.get(key).toString();

    this.accountService.setLoadingState(true);
    this.accountService
      .signUp(
        getValue('email'),
        getValue('password'),
        getValue('first-name'),
        getValue('last-name')
      )
      .then(resp => {
        this.accountService.setUserData(resp.user);
        this.hideError();
        this.clearForm();
      })
      .catch(error => this.onError(error))
      .finally(() => this.accountService.setLoadingState(false));
  }
}
