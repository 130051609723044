import {ApiService} from '../api';
import {URLS, UrlUtils} from '../urls';
import {CheckoutData} from '../../interfaces/checkout.interface';

export class CheckoutService extends ApiService {

  getCheckoutInfo(code: string | number): Promise<CheckoutData> {
    const url = UrlUtils.insertValueToUrl(URLS.CHECKOUT_PRODUCT_INFO, code);

    return this.get(url).then(resp => resp.json());
  }
}
