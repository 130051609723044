import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';

import {ProductInfoService} from './product_info.service';
import {Product} from '../../interfaces/product.interface';
import {L10nUtils} from '../../utils/l10n_utils';
import {URLS, UrlUtils} from '../urls';
import {CurrencyService} from './currency.service';

export class ShopifyProductInfoService extends ProductInfoService {
  constructor(private currencyService: CurrencyService) {
    super();

    pipe(
      this.currencyService.changeCurrencySubject,
      subscribe({next: () => {
          ProductInfoService.clearProductInfoCache();
        }})
    );
  }

  getView(code: string): Promise<Product> {
    const productInfo = ProductInfoService.getProductInfo(code);

    if (productInfo) {
      return Promise.resolve(productInfo);
    }

    const url = UrlUtils.insertFewValuesToUrl(
      URLS.SHOPIFY_PRODUCT_VIEW,
      {
        code,
        lang: L10nUtils.getCurrentLanguage(),
        currency_code: this.currencyService.selectedCurrencyCode
      }
    );

    return this.get(url)
      .then(resp => resp.json())
      .then(product => {
        ProductInfoService.setProductInfo(product.identifier, product);
        return product;
      });
  }
}
