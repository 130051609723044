import {AccountService} from '../../api/services/account.service';
import {InputEventUtils} from '../../utils/input_event_utils';
import {ACCOUNT_OVERLAY_TYPE} from '../../shared/constants';

export class AccountEmailSent {
  constructor(private accountService: AccountService, private wrapperEl: HTMLElement) {
    this.init();
  }

  private init() {
    const linkElToSignIn = this.wrapperEl.querySelector('.link-to-sign-in');

    InputEventUtils.addSelectEvents(
      linkElToSignIn,
      () => this.accountService.setOverlayType(ACCOUNT_OVERLAY_TYPE.SIGN_IN)
    );
  }
}
