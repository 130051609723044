import infosService from '../api/services/infos-base_overlay.service';
import {CUSTOM_OVERLAYS} from '../shared/constants';
import {InfosData} from '../interfaces/infos.interface';
import {infosOverlayView} from './infos-base_overlay-view';
import {InputEventUtils} from '../utils/input_event_utils';
import {FontLoader} from '../font_loader';
import {AppUtils} from '../utils/app_utils';
import {OverlayScrollbar} from '../components/overlay_scrollbar';
import {SPHERE_EVENT_NAMES as EVENTS} from '../event-names';
import {sphereEventHandler} from '../custom_event_utils';

export class InfoOverlay {
  constructor(private container: HTMLElement,
              planogramId: number,
              versionId: number,
              infoType: CUSTOM_OVERLAYS,
              private closeCallback: Function) {

    infosService.getView(planogramId, versionId, infoType).then((data: InfosData) => {
      if (data) {
        if (data.planogram_info_fonts && data.planogram_info_fonts.length) {
          const infosFonts = data.planogram_info_fonts.map(fontData => {
            const modifiedFontData = AppUtils.deepClone(fontData);
            modifiedFontData.assignment = `infos-${fontData.assignment}`;
            return modifiedFontData;
          });
          FontLoader.mountCustomFonts(infosFonts);
        }

        this.initTemplateContent(this.container, data);
        sphereEventHandler.emit(EVENTS.OVERLAY.SHOW_INFO_OVERLAY_CONTENT, data);

        return new OverlayScrollbar('.text-content');
      } else {
        if (this.closeCallback) {
          this.closeCallback();
        }
        console.error(`${infoType} infos overlay is empty/disabled in Pubtool`);
      }
    });
  }

  initTemplateContent(container: HTMLElement, data: InfosData) {
    container.innerHTML = infosOverlayView(data);
    const closeButton = document.querySelector('.infos-overlay .overlay-button-close');

    if (closeButton) {
      InputEventUtils.addSelectEvents(closeButton, this.closeOverlay.bind(this));
    } else {
      console.error('element not found - ".overlay-button-close"');
    }
  }

  closeOverlay(e?: Event): void {
    if (e) {
      e.stopPropagation();
    }
    if (this.closeCallback) {
      this.closeCallback();
    } else {
      console.error('"Close" function is not set up');
    }
  }

  dispose() {
    FontLoader.unmountCustomFont('infos');
  }

  // Using in ProductCanvas
  handleClick(e) {
    return false;
  }
}
