import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';

import {AccountService} from '../../api/services/account.service';
import {UserAccountData} from '../../interfaces/user-account-data.interface';
import {InputEventUtils} from '../../utils/input_event_utils';
import {L10nUtils} from '../../utils/l10n_utils';
import {ShoppingCartService} from '../../api/services/shopping-cart.service';
import {Metrics} from '../../metrics';
import {AppState} from '../../shared/app.state';
import {MATOMO_EVENT_NAMES} from '../../metric-events';

export class AccountProfile {
  private greetingEl: HTMLElement;
  private logOutBtn: HTMLElement;

  constructor(
    private accountService: AccountService,
    private shoppingCartService: ShoppingCartService,
    private wrapperEl: HTMLElement
  ) {
    this.init();

    pipe(
      this.accountService.accountUserDataSubject,
      subscribe({
        next: this.setUserProfileData.bind(this)
      })
    );

    InputEventUtils.addSelectEvents(this.logOutBtn, this.onLogOut.bind(this));
  }

  private init() {
    this.greetingEl = this.wrapperEl.querySelector('.account-profile-greeting span');
    this.logOutBtn = this.wrapperEl.querySelector('.log-out-btn');
  }

  private setUserProfileData(userData: UserAccountData) {
    this.greetingEl.innerText = `${L10nUtils.l10n('account.profile.greeting.text')}, ${userData?.display_name || 'User'}`;
  }

  private onLogOut() {
    Metrics.storeTheEvent(
      AppState.planogramName,
      'click',
      MATOMO_EVENT_NAMES.CLICK_USER_PROFILE_OVERLAY_LOGOUT
    );
    this.accountService.setLoadingState(true);

    if (this.shoppingCartService.isMultipassEnabled) {
      setTimeout(() => {
        this.accountService.setUserData(null);
        this.accountService.setActiveState(false);
        this.shoppingCartService.clearShoppingCartToken();
        this.accountService.setLoadingState(false);
      }, 1000);
      return;
    }

    this.accountService.logOut()
      .then(() => {
        this.accountService.setUserData(null);
        this.accountService.setActiveState(false);
        this.shoppingCartService.clearShoppingCartState();
      })
      .finally(() => this.accountService.setLoadingState(false));
  }
}
