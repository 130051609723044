import {PlanogramsService} from '../api/services/planograms.service';
import {VirtualBoundaries} from './vt_pipeline.interface';
import {NAVIGATION_BUTTON_TYPE, PURCHASING_FLOW, SPHERE_ITEM_TYPES} from '../shared/constants';
import {SharingButton} from './sharing-button.interface';
import {EcommerceOverlaySettings} from './ecommerce-overlay-settings';
import {Vector2, Vector3} from 'three';
import {PlanogramFonts} from './planogram-fonts.interface';

export interface Thumbnails {
  VARIANT_LARGE_WEBP: string;
  VARIANT_SMALL_WEBP: string;
  VARIANT_MEDIUM_WEBP: string;
  VARIANT_THUMBNAIL_PNG: string;
  VARIANT_THUMBNAIL_JPEG: string;
  VARIANT_THUMBNAIL_WEBP: string;
  VARIANT_THUMBNAIL_PNG_600: string;
}

export interface PlanogramListData {
  id: number; // 27
  name: string; // "starwars"
  full_name: string; // "LEGO Star Wars sphere"
  client_name: string; // "lego"
  config_file_path: string; // "/spheres/starwars/planogram.json"
  path: string; // "/lego/starwars"
}

export interface VirtualTextureData {
  height: number;
  pageBorderWidth: number;
  pageSize: number;
  pagesHigh: number;
  pagesWide: number;
  permanentLod: number;
  width: number;
  worstLod: number; // worst LOD of background, not sphere items
  x: number;
  y: number;
}

export interface PosterData {
  url: string;
  naturalWidth: number;
  naturalHeight: number;
  thumbnails: Thumbnails;
}

export interface MediaMetaData extends MetaData {
  volume: number;
  loop: boolean;
  autoplay: boolean;
  hideControls: boolean;
  videoUrl?: string;
  source?: string;
  controls: boolean;
  poster?: PosterData;
  share?: boolean;
}

export interface FontFamily {
  name: string;
  file_url: string;
  font_weight?: string;
  font_family: string;
}

export interface TextMetaData extends MetaData {
  text: string;
  fontFamily?: FontFamily;
  fontSize: number;
  lineHeight: number;
  alignment: string;
  alpha?: number;
  letterSpacing?: number;
  weight?: string;
  topOffsetFromCenter: number;
  textLines?: string[];
  textPosition?: [number, number];
}

export interface ClusterMetaData extends MetaData {
  clusterLink: string;
  clusterSeoFields: {
    title: string;
  };
}

export type MetaDataType =
  | MetaData
  | ImageMetaData
  | MediaMetaData
  | TextMetaData
  | ParticlesMetaData
  | ThreeDMetaData
  | ShapesMetaData
  | ClusterMetaData
  | CurvesMetaData;

export interface ItemData<T = MetaDataType> {
  id: string | number;
  parentId?: number | string;
  childrenIds?: (number | string)[];
  type: SPHERE_ITEM_TYPES | ACTION_TYPE;
  x: number;
  y: number;
  width: number; // 0 for points
  height: number; // -||-
  rotation: [number, number, number];
  action?: Action;
  name?: string;
  data?: T;

  // added at run time (client side)
  virtualBoundaries: VirtualBoundaries; // used for determining if element is inside view port
  parent?: ItemData;
  children?: ItemData[];

  // todo: to be reviewed
  renderOrder?: number;

  instagram_feed?: boolean;
}

export interface ItemLOD {
  lod: number;
  textures: ItemTile[];
  url_start?: string;
  gridSize: number;
}

export interface ItemTile {
  url: string;
  uv?: UV;
  boundaries: VirtualBoundaries;
  size: number;
  center: Vector2;
}

export interface UV {
  x: number;
  y: number;
  height: number;
  width: number;
}

export interface ImageMetaData extends LODSMetaData {
  thumbnails?: Thumbnails;
  url?: string;
  imageName: string;
  id: string;
  image_name: string;
  picture?: PictureData;
  hover: boolean; // scale on hover?
}

export interface LODSMetaData extends MetaData {
  lods: ItemLOD[];
  naturalResolution?: [number, number];
  fit_size?: [number, number];
  full_size?: [number, number];
}

export interface PictureData {
  id: number;
  name: string;
  url: string;
  thumbnails?: Thumbnails;
}

export interface ProductMetaData extends LODSMetaData {
  id: number;
  name: string;
}

export interface AccessiblityData {
  title?: string;
  description?: string;
}

export interface MetaData {
  opacity?: number;
  code: string;
  link?: string;
  product?: {
    id: number | string;
    identifier: string;
    name: string;
  };
  accessibility?: AccessiblityData;
  item?: {
    id: number;
    name: string;
    action: Action;
    link: string;
  };
  name: string;
  description: string;
  color?: string;
  rotation?: Vector3;
  position?: Vector3;
  aspectRatio?: number;
}

export interface ThreeDMetaData extends MetaData {
  scale: number;
  source: string;
  type: string; // type of 3D object (fbx, obj, ...) -> used to select appropriate loader
}

export interface ParticlesMetaData extends MetaData {
  texture: string;
  count: string;
  spread: number;
  speed: number;
  positioningFunction: string; // predefined function: to be defined later on
}

export interface ShapesMetaData extends MetaData {
  shapeType: string;
  soft: boolean;
  dashed: boolean;
  thickness: number;
  indentation: number;
  cornerRadius: number;
  fillColor: [number, number, number, number];
  borderColor: [number, number, number, number];
}

export interface CurvesMetaData extends MetaData {
  points: number[];
  thickness: number;
  dashed: boolean;
  soft: boolean;
  scale: [number, number, number];
}

export interface Action {
  type: ACTION_TYPE;
  data?:
    | LinkActionData
    | ProductActionData
    | VideoActionData
    | ImageActionData
    | ClusterActionData
    | ContentOverlayActionData
    | SocialContentActionData;
}

enum LINK_TYPE {
  NEW_TAB = 'new-tab',
  SAME_TAB = 'same-tab',
  IFRAME = 'iframe'
}

// ACTION_TYPE.OPEN_LINK
export interface LinkActionData {
  url: string;
  itemUuid: string;
  type?: LINK_TYPE; // default value LINK_TYPES.NEW_TAB
}

export enum SOCIAL_MEDIA_TYPE {
  INSTAGRAM = 'Instagram',
  TIKTOK = 'TikTok',
  TWITTER = 'X',
  TWITCH = 'Twitch',
  LINKFIRE = 'Linkfire',
  YOUTUBE = 'Youtube',
  SNAPCHAT = 'Snapchat',
  FACEBOOK = 'Facebook'
}

export interface SocialContentActionData extends ItemActionData {
  contentValue: string; // the actual url from PubTool
  source: SOCIAL_MEDIA_TYPE; // social media type
  contentType: string; // content type from PubTool
}

// ACTION_TYPE.ANIMATE_TO_ITEM
interface ItemActionData {
  itemId: string | number; // uniq id of item on current Sphere
}

export enum PRODUCT_OVERLAY_VIEW {
  GALLERY = 'gallery',
  DETAILS = 'details',
  CHECKOUT = 'checkout'
}

export interface AnimateActionData {
  itemUuid: string;
  sphereName?: string; // actions sphere name to link to
  itemId?: string | null;
  applyItemsActionInTheEnd?: boolean; // if after animation the action should be fired on the element
  productId?: number;
  productName?: string;
  productIdentifier?: string;
  clusterLink?: string;
  itemType?: SPHERE_ITEM_TYPES;
  imageName?: string;
  imageId?: string;
}

// ACTION_TYPE.NAVIGATE_TO_PRODUCT_OVERLAY
export interface ProductActionData extends ItemActionData {
  productId: number; // uniq id of a product
  productName: string;
  productIdentifier: string; // uniq id of a product
  view?: PRODUCT_OVERLAY_VIEW; // default view PRODUCT_OVERLAY_VIEWS.GALLERY
}

enum VIDEO_TYPE {
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  DIRECTLY_UPLOADED = 'directly-uploaded'
}

// ACTION_TYPE.NAVIGATE_TO_VIDEO_OVERLAY
interface VideoActionData extends ItemActionData {
  url: string; // absolute URL to video
  type: VIDEO_TYPE; // no default type!
}

enum INFO_OVERLAY_TYPE {
  COPYRIGHT = 'copyright',
  PRIVACY_POLICY = 'privacy-policy',
  ABOUT_US = 'about-us',
  CONTACT_US = 'contact-us'
}

export enum ANIMATION_TYPE {
  ZOOM = 'zoom',
  DRAG = 'drag',
  CLICK = 'click'
}

export enum ANIMATION_POSITION {
  CENTER = 'center',
  TOP = 'top',
  BOTTOM = 'bottom'
}

// ACTION_TYPE.NAVIGATE_TO_INFO_OVERLAY
export interface ClusterActionData extends ItemActionData {
  clusterLink: string;
}

// ACTION_TYPE.NAVIGATE_TO_IMAGE_OVERLAY
interface ImageActionData extends ItemActionData {
  imageId: string | number; // uniq Image id (URL will be separately requested according to env)
}

export interface ContentOverlayActionData {
  url: string;
  iframeLink: string;
  transparent: boolean;
}

export enum ACTION_TYPE {
  OPEN_LINK = 'open-link',
  ANIMATE = 'animate',
  PRODUCT_OVERLAY = 'product-overlay',
  PRODUCT = 'product',
  VIDEO_OVERLAY = 'video-overlay',
  CLUSTER = 'cluster',
  CLUSTER_CAPTION = 'cluster-caption',
  PRIVACY_POLICY = 'privacy-policy',
  SINGLE_IMAGE = 'single-image',
  PRODUCT_LINK = 'product-link',
  LINK = 'external-link',
  VIDEO = 'video',
  IMAGE = 'image',
  IFRAME = 'iframe-link',
  CONTENT_OVERLAY = 'iframe',
  INFO = 'info',
  PRODUCT_RELEASE_COUNTDOWN = 'product-release-countdown',
  UNKNOWN = 'unknown',
  ABOUT_US = 'about-us',
  CONTACT_US = 'contact-us',
  COPYRIGHT = 'copyright',
  COOKIES_POLICY = 'cookies-policy',
  SOCIAL_CONTENT_OVERLAY = 'social-link',
  AUDIO = 'audio'
}

export enum ALIGNMENT_TYPES {
  LEFT = 'left',
  MIDDLE = 'middle',
  RIGHT = 'right',
  DISTRIBUTE = 'distribute'
}

interface PlanogramVersionSettingsImage {
  id: number;
  url: string;
}

export interface PlanogramVersionSearchSettings {
  id: number; // id of planogram_version
  planogram_name: string; // name of the planogram
  search_setting: {
    id: number;
    control_button: {
      id: number;
      url: string; // url to img
    };
  };
}

export interface PlanogramVersionSettings {
  enabled: boolean;
  navigation_type: string; // 'url' or 'planogram' or 'back'
  navigation_value: string; // absolute link in case of 'url', planogram name in case of 'planogram'
  language_code: string;
  open_in_new_page: boolean;
  element_type: NAVIGATION_BUTTON_TYPE;
  title: string;
  show_shadow: boolean;
  show_title: boolean;
  planogram_version_control_button_font: PlanogramFonts;
}

export interface PlanogramVersionControlButtonMenuItem {
  id: number;
  menu_id: number;
  menu_item_font: PlanogramFonts;
  navigation_type: 'internal' | 'external';
  navigation_value: string;
  control_button: PlanogramVersionSettingsImage;
  control_button_id: number;
  show_title: boolean;
  title: string;
  open_in_new_page: boolean;
  without_navigation: boolean;
}

export interface PlanogramVersionControlButtonMenu {
  background_container: boolean;
  background_transparent: boolean;
  color: string;
  id: number;
  menu_items: PlanogramVersionControlButtonMenuItem[];
  show_shadow: boolean;
}
export interface PlanogramVersionSettingsControlButton extends PlanogramVersionSettings {
  control_button: PlanogramVersionSettingsImage;
  image_size: number; // size in %
  menu?: PlanogramVersionControlButtonMenu;
}

export interface PlanogramVersionSettingsLogo extends PlanogramVersionSettings {
  logo: PlanogramVersionSettingsImage;
}

export interface PlanogramVersionSettingsInfoButton {
  id: number;
  color: string;
  custom_icon_url: string; // custom uploaded icon
  default_icon_url: string; // initial icon from system; not changeable
}

export interface EcommercePlatformCurrency {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  symbol: string;
}

export interface AnimationOptions {
  duration?: number;
  delay?: number;
  clusterAnimation?: boolean;
  transitionType?: TransitionType;
  panBeforeZoom?: boolean;
}

export interface AnimationPathItem {
  name: string;
  planogramName: string;
  language: string;
  itemId: string;
  transitionType: TransitionType;
  panBeforeZoom: boolean;
  duration: number;
  delay: number;
}

export interface AnimationPath {
  name: string;
  autoplay: boolean;
  items: AnimationPathItem[];
  applyFinalItemsAction: boolean;
  loop: boolean;
}

export interface PlanogramVersion {
  id: number; // 24 (version id)
  planogram_id: number; // 84
  version: number; // 3
  status: string; // published
  actions_json: Partial<PlanogramData>;
  enabled_gallery_overlay: boolean;
  info_button_setting: PlanogramVersionSettingsInfoButton;
  tiles_path: string;
  tiles_paths?: {
    webp?: string;
  };
  planogram_version_control_buttons: PlanogramVersionSettingsControlButton[];
  planogram_version_logo: PlanogramVersionSettingsLogo;
  planogram_legacy: boolean;
  primary_color: string;
  secondary_color: string;
  sharing_button: SharingButton;
  camera_position: number;
  client: {
    is_external_domain: boolean; // flag for setting Cookies in correct scope
    product_images_required: boolean;
    name: string;
    ecommerce_platform_enabled: boolean; // flag for ecommerce platforms
    shopping_platform: {
      title: string;
      currency: string;
    };
    currencies: Array<EcommercePlatformCurrency>;
    shopify_multipass_enabled: boolean;
    shopify_multipass_url: string;
  };
  ecommerce_overlay_settings_pdp: EcommerceOverlaySettings;
  ecommerce_overlay_settings_shopping_cart: EcommerceOverlaySettings;
  ecommerce_overlay_settings_buttons: EcommerceOverlaySettings;
  ecommerce_overlay_settings_sign_in: EcommerceOverlaySettings;
  seo_title: string;
  seo_desc: string;
  planogram_version_products_seo: [{id: number; seo_title: string}];
  planogram_version_items_seo: [{id: number; seo_title: string}];
  iframe_primary_color: string;
  iframe_secondary_color: string;
  client_social_medias: ClientSocialMedia[];
  animation_settings: AnimationSettings;
  audio: AudioSettings | null;
  other_assets: OtherAsset[];
  audio_background_color: string;
  volume: number;
  entrance_animation: EntranceAnimationSettings;
  navigation_alignment: ALIGNMENT_TYPES;
  navigation_distribute_evenly: boolean;
}

export class PlanogramData {
  id: number;
  versionId: number;
  legacy: boolean;
  name: string;
  isExternalDomain: boolean;
  ecommerceEnabled: boolean;
  isMultipassKeyAvailable: boolean;
  multipassRedirectUrl: string;
  ecommercePlatformName: string;
  ecommercePlatformCurrency: string;
  ecommercePlatformCurrencies: Array<EcommercePlatformCurrency>;
  ecommerceOverlaySettingsPdp: EcommerceOverlaySettings;
  ecommerceOverlaySettingsShoppingCart: EcommerceOverlaySettings;
  ecommerceOverlaySettingsSignIn: EcommerceOverlaySettings;
  ecommerceOverlaySettingsButtons: EcommerceOverlaySettings;
  isShoppingCartEnabled: boolean;
  afterParse: Function;
  planogramsService: PlanogramsService;
  faceNormalsHelper: any;
  enabledGalleryOverlay: boolean;
  tilesPath: string;
  planogramVersionControlButtons: PlanogramVersionSettingsControlButton[];
  planogramVersionLogo: PlanogramVersionSettingsLogo;
  infoButtonSetting: PlanogramVersionSettingsInfoButton;
  primaryColor: string;
  secondaryColor: string;
  sharingButton: SharingButton;
  cameraPosition: number;
  seoTitle: string;
  seoDescription: string;
  iframePrimaryColor: string;
  iframeSecondaryColor: string;
  animationSettings: AnimationSettings;
  audioSettings: AudioSettings;
  otherAssets: OtherAsset[];
  audioBackgroundColor: string;
  volume: number;
  navigationAlignment: ALIGNMENT_TYPES;
  navigationDistributeEvenly: boolean;

  // Planogram JSON parsed fields
  fingerprint: string; // TODO Unused variables
  width: number;
  height: number;
  topLimit: number;
  bottomLimit: number;
  backgroundColor: Array<number>;
  startPoint: number;
  planogramVersion: string;
  clustersOrder: Array<string>;
  animation_paths: Array<AnimationPath>;
  itemsOrder: Array<string>;
  items: Array<ItemData>;
  virtualTexture: VirtualTextureData;
  productSEO: {[id: string]: {title: string}};
  itemSEO: {[id: string]: {title: string}};
  clientSocialMedias: ClientSocialMedia[];
  entranceAnimation: EntranceAnimationSettings;

  get fixedRadius() {
    return this.width / (Math.PI * 2);
  }

  get curvature() {
    return 3;
  }

  get largeRadius() {
    return this.fixedRadius * this.curvature;
  }

  static get ALPHA() {
    return (10.0 * Math.PI * 2) / 360;
  }

  // How many parts to split the sphere into
  static PAGES_WIDE = 256;
  static PAGES_HIGH = 64;

  static pages() {
    return new Vector2(PlanogramData.PAGES_WIDE, PlanogramData.PAGES_HIGH);
  }
}

export interface PurchasingFlowSettingsData {
  id: number;
  purchasing_flow: PURCHASING_FLOW; //  “buy-button” or "checkout"
}

export interface ClientSocialMedia {
  close_bg_color: string;
  close_icon_color: string;
  container_color: string;
  container_transparent: boolean;
  content: ClientSocialMediaContent[];
  id: number;
  social_media_content_types: Array<string>;
  social_media_id: number;
  social_media_name: string;
  social_media_title: string;
  hide_overlay_container: boolean;
  scroll_container_fill: string;
  scroll_indicator_fill: string;
  show_loader: boolean;
}

export interface ClientSocialMediaContent {
  content_type: string;
  content_value: string;
}

export type TransitionType = 'linear' | 'easeIn' | 'easeOut' | 'easeInOut' | 'bounce';

export interface AnimationSettings {
  duration: number;
  autoplay_delay: number;
  transition_type: TransitionType;
  pan_before_zoom: boolean;
}

export interface AudioSettings {
  id: number;
  seo_desc: string;
  seo_title: string;
  url: string;
  file_name: string;
  content_type: string;
}

export interface EntranceAnimationSettings {
  entrance_animation_fonts: EntranceAnimationTypes[];
  position: ANIMATION_POSITION;
  background_color: string;
  with_animation: boolean;
  disable_glow: boolean;
  hide_guidance: boolean;
  repeat_animation: boolean;
}

interface EntranceTextSettings extends PlanogramFonts {
  title: string;
}

export interface EntranceAnimationTypes {
  action_type: ANIMATION_TYPE;
  desktop: EntranceTextSettings;
  mobile: EntranceTextSettings;
  complete: boolean;
  hidden: boolean;
  other_asset: {
    id: number;
    url: string;
  } | null;
}

export interface OtherAsset {
  id: number;
  path: string;
  url: string;
  image_name: string;
  seo_title: string;
  accessibility_description: string | null;
  thumbnails: Thumbnails;
  is_system: boolean;
  title: 'stop' | 'mute' | 'unmute';
}
