import makeBehaviorSubject from 'callbag-behavior-subject';
import subscribe from 'callbag-subscribe';
import pipe from 'callbag-pipe';

import {EcommercePlatformCurrency} from '../../interfaces/planogram.interface';
import {CURRENCY_CODE_KEY, CURRENCY_KEY} from '../../shared/constants';
import Router from '../../router';

export class CurrencyService {
  private readonly currencySubjectRef: Function;
  changeCurrencySubject: makeBehaviorSubject<string>;

  get selectedCurrencyCode() {
    return new URL(window.location.href).searchParams.get(CURRENCY_KEY) ||
      window.localStorage.getItem(CURRENCY_CODE_KEY);
  }

  constructor(public currenciesList: Array<EcommercePlatformCurrency>, detectedCurrencyCode: string) {
    const initialCurrencyCode = this.getCurrencyByCode(this.selectedCurrencyCode)?.name ?? detectedCurrencyCode;
    Router.removeParamFromUrl(CURRENCY_KEY);
    this.changeCurrencySubject = makeBehaviorSubject(initialCurrencyCode);

    this.currencySubjectRef = pipe(
      this.changeCurrencySubject,
      subscribe({
        next: (currencyCode: string) => {
          window.localStorage.setItem(CURRENCY_CODE_KEY, currencyCode);
        }
      })
    );
  }

  setCurrency(currencyCode: string) {
    this.changeCurrencySubject(1, currencyCode);
  }

  getCurrencyByCode(currencyCode: string): EcommercePlatformCurrency {
    return this.currenciesList.find(c => c.name === currencyCode);
  }

  dispose() {
    this.currencySubjectRef();
  }
}
