import {Product} from '../../interfaces/product.interface';
import {L10nUtils} from '../../utils/l10n_utils';
import {URLS} from '../../api/urls';

export const productInfoKhaliliView = (product: Product, planogramName: string) => {
  const checkMarkSrc = URLS.OVERLAY_CHECK_MARK;
  const shareLinkSrc = URLS.OVERLAY_SHARE_LINK_ICON;

  const twitterShareLinkSrc = URLS.OVERLAY_TWITTER_ICON;
  const whatsappShareLinkSrc = URLS.OVERLAY_WHATSAPP_ICON;
  const telegramShareLinkSrc = URLS.OVERLAY_TELEGRAM_ICON;
  const viberShareLinkSrc = URLS.OVERLAY_VIBER_ICON;
  const khaliliLogo = URLS.OVERLAY_KHALILI_LOGO;
  const hajjLogo = URLS.OVERLAY_HAJJ_LOGO;

  return `
    <div id="product-details-container" class="app-khalili-template is-view-gallery">
        <div class="app-product-overlay ${planogramName}-overlay">
            <div class="app-gallery-gradient"></div>
            <div class="app-product-overlay-controls">
              <div class="overlay-button overlay-button-back overlay-back"  role="button" tabindex="0">
                <span></span>
              </div>
              <div class="overlay-button overlay-button-close"  role="button" tabindex="0">
                <span></span>
              </div>
            </div>
            <div class="app-product-content-wrapper">
              <div class="app-product-details-header">
                <div class="app-product-title">${product.name}</div>
              </div>
              <div class="app-product-carousel">
                <div id="app-image-carousel" class="splide">
                  <div class="splide__arrows">
                    <button class="splide__arrow splide__arrow--prev">
                      <span class="overlay-arrow overlay-arrow-prev"></span>
                    </button>
                    <button class="splide__arrow splide__arrow--next">
                      <span class="overlay-arrow overlay-arrow-next"></span>
                    </button>
                  </div>
                  <div class="splide__track">
                    <ul class="splide__list">
                    </ul>
                  </div>
                </div>
                <div class="app-thumbnail-carousel-container">
                  <div class="app-product-gallery-buttons-container">
                    <div id="info-gallery-button" role="button" tabindex="0" class="no-checkout">
                      <div>
                        <img alt="Info icon">
                      </div>
                    </div>
                    <div id="checkout-gallery-button" role="button" tabindex="0" class="is-completely-hidden">
                      <div>
                        <img alt="Checkout icon">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="app-product-details-container">
                <div class="app-product-details-scrollable-area">
                  <div class="text-wrapper allow-scroll">
                    <div class="app-product-additional-fields-wrapper">
                      ${product.location ? `<div class="app-product-additional-field">${product.location}</div>` : ''}
                      ${product.time_period ? `<div class="app-product-additional-field">${product.time_period}</div>` : ''}
                      ${product.color_description ? `<div class="app-product-additional-field">${product.color_description}</div>` : ''}
                      ${product.product_size ? `<div class="app-product-additional-field">${product.product_size}</div>` : ''}
                      ${product.inventory_number ? `<div class="app-product-additional-field">${product.inventory_number}</div>` : ''}
                    </div>
                    <div class="app-share-links is-hidden">
                      <img class="share-button-whatsapp share-button is-hidden" src="${whatsappShareLinkSrc}" role="button" tabindex="0">
                      <img class="share-button-telegram share-button is-hidden" src="${telegramShareLinkSrc}" role="button" tabindex="0">
                      <img class="share-button-viber share-button is-hidden" src="${viberShareLinkSrc}"  role="button" tabindex="0">
                      <img class="share-button-twitter share-button is-hidden" src="${twitterShareLinkSrc}"  role="button" tabindex="0">
                      <div class="share-link-holder">
                        <img class="share-button-copy_link share-button is-hidden" src="${shareLinkSrc}" role="button" tabindex="0">
                        <div class="share-success-message">
                          <img class="check-mark" src="${checkMarkSrc}">
                          <span>${L10nUtils.l10n('product-details-overlay.share.notification')}</span>
                        </div>
                      </div>
                    </div>
                    <div class="app-product-brand-wrapper">
                      <div class="app-product-brand-icon">
                        <img src="${hajjLogo}" alt="hajj logo">
                      </div>
                      <div class="app-product-brand-icon">
                        <img src="${khaliliLogo}" alt="khalili logo">
                      </div>
                    </div>
                  </div>
                  <div class="text-fade"></div>
                </div>
                ${product.description?.length ?
                `<div class="app-product-description">
                  <div id="product-description-button" class="overlay-btn app-product-description-title">
                    <div class="text">
                      ${L10nUtils.l10n('product-details-overlay.information-button.text')}
                      <div class="overlay-button overlay-button-close app-product-description-icon-close" role="button" tabindex="0">
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <div class="app-product-description-text-wrapper">
                    <div class="app-product-description-text allow-scroll">
                      ${product.description}
                    </div>
                    <div class="text-fade"></div>
                  </div>
                </div>` :
                ''}
              </div>
            </div>
          </div>
        </div>
    </div>
  `;
};
