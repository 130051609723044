function renderSelectItems(option, variantName) {
  const selectBoxItem = (data, index) => {
    return `
      <div class="app-shopify-product-variants-select-box-value">
        <input type="radio" id="${variantName}-${index}" name="option${option.position}" value="${data.values[index]}">
        <div class="app-shopify-product-variants-select-box-text-wrapper input-field-border">
          <div class="left-border">
            <div class="square"></div>
          </div>
          <div class="right-border">
            <div class="square"></div>
          </div>
          <p class="app-shopify-product-variants-select-box-text">${data.values[index] !== 'Default Title' ? data.values[index] : ''}</p>
        </div>
      </div>
    `;
  };
  const mainContainer = document.createElement('div');

  for (let i = 0; i < option.values.length; i++) {
    const container = document.createElement('div');
    mainContainer.append(container);
    container.outerHTML = selectBoxItem(option, i);
  }

  return mainContainer.innerHTML;
}

function renderListItems(option, optionName) {
  const listItem = (data, index) => {
    return `
      <li>
        <label for="${optionName}-${index}">${data.values[index]}</label>
      </li>
    `;
  };
  const mainContainer = document.createElement('div');

  for (let i = 0; i < option.values.length; i++) {
    const container = document.createElement('div');
    mainContainer.append(container);
    container.outerHTML = listItem(option, i);
  }

  return mainContainer.innerHTML;
}

export const variantsComponentView = (option) => {
  const selectBoxIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0H24V24H0z" transform="translate(-838.000000, -334.000000) translate(713.000000, 334.000000) translate(125.000000, 0.000000)"/>
      <path class="icon" fill="#000" fill-rule="nonzero" d="M12.994 13.994l3.499-3.5c.55-.55.16-1.494-.619-1.494H8.876c-.777 0-1.17.943-.619 1.494l3.5 3.5c.341.341.895.341 1.237 0zm-.619-.619l-3.5-3.5h7l-3.5 3.5z" transform="translate(-838.000000, -334.000000) translate(713.000000, 334.000000) translate(125.000000, 0.000000)"/>
    </g>
  </svg>
  `;
  const parsedVariantName = option.name.toLowerCase();

  return `
    <div class="app-shopify-product-variants-wrapper ${option.values[0] === 'Default Title' ? 'is-completely-hidden' : ''}">
      <div class="app-shopify-product-variants-type">
        <span>${option.values[0] !== 'Default Title' ? option.name : ''}</span>
      </div>
      <div class="app-shopify-product-variants option${option.position} ${option.values.length <= 1 ? 'disabled' : ''}">
        <div class="app-shopify-product-variants-select-box">
          ${renderSelectItems(option, parsedVariantName)}
          ${option.values.length > 1 ? selectBoxIcon : ''}
        </div>
        <ul class="app-shopify-product-variants-select-box-list allow-scroll ${option.values.length <= 1 ? 'is-hidden' : ''}">
          ${renderListItems(option, parsedVariantName)}
        </ul>
      </div>
    </div>
  `;
};
