export abstract class Animation {
  private chainedAnimation: Animation;

  protected constructor(private readonly endCallback?) {
  }

  abstract update(): void;

  addChainedAnimation(animation: Animation) {
    this.chainedAnimation = animation;
  }

  getAdjustment() {
    let adjustment;
    if (this.chainedAnimation) {
      adjustment = this.chainedAnimation.update();
    }
    if (!adjustment) {
      adjustment = this.update();
      if (!adjustment && this.endCallback) {
        this.endCallback();
      }
    }
    return adjustment;
  }
}
