import {ProductInfoBase} from './product-info-base_overlay';
import {productInfoKhaliliView} from './product-info-khalili.view';

export class ProductInfoKhalili extends ProductInfoBase {
  protected supportGalleryView = false;

  protected setCustomButtons() {}

  protected initProductPrice() {}

  protected initDescriptionControls() {
    if (this.product.description?.length) {
      super.initDescriptionControls();
    } else {
      this.mainContainer.classList.add('without-information-tab');
    }
  }

  handleClick(e) {
    if (this.isViewGallery()) {
      const tokens = ['app-product-overlay', 'app-product-content-wrapper', 'app-product-carousel'];
      if (e.target.closest('#app-image-carousel')) {
        tokens.push('splide__slide');
      }
      return tokens.some(token => e.target.classList.contains(token));
    } else {
      return e.target === this.mainContainer;
    }
  }

  protected setItemContent() {
    this.container.innerHTML = productInfoKhaliliView(
      {...this.product},
      this.item.planogram.name
    );
  }

  protected setThumbsSwitchLength() {
    // no thumbnail for this client
  }
}
