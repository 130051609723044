import * as MobileDetect from 'mobile-detect';
import * as Bowser from 'bowser';

import {Thumbnails} from '../interfaces/planogram.interface';
import {isTouchSupported, isMacOS} from './platform';
import {ProductImageVariants} from '../interfaces/product.interface';

export class BrowserUtils {
  private static _md: MobileDetect;

  private static get md() {
    if (!this._md) {
      this._md = new MobileDetect(navigator.userAgent);
    }
    return this._md;
  }

  private static _bowser: Bowser.Parser.Parser | undefined;

  private static get bowser() {
    if (!this._bowser) {
      this._bowser = Bowser.getParser(navigator.userAgent);
    }
    return this._bowser;
  }

  static _isSafari: boolean | undefined;

  static isSafari(): boolean {
    if (this._isSafari === undefined) {
      this._isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }
    return this._isSafari;
  }

  static get browser() {
    const browserDetails = this.bowser.getBrowser();
    return {...browserDetails, majorVersion: (browserDetails.version || '').split('.')[0]};
  }

  static get os() {
    const osDetails = this.bowser.getOS();
    return {...osDetails, majorVersion: (osDetails.version || '').split('.')[0]};
  }

  static supportsWebp() {
    // Webp format is supported on all modern non Safari browsers
    if (!this.isSafari()) {
      return true;
    }

    // On mobile Safari webp format is supported from Safari version 14 (Safari version is tight to the OS version).
    if (this.isMobile() || this.isTablet()) {
      return Number(this.os.majorVersion) >= 14;
    }

    // On desktop Safari webp support starts with the Safari version 14, but only on OS X 11 (Big Sur) and onwards.
    return Number(this.browser.majorVersion) >= 14 && Number(this.os.majorVersion) >= 11;
  }

  static isTablet(): boolean {
    return !!this.md.tablet();
  }

  static isMobile(): boolean {
    return !!this.md.mobile();
  }

  static isMobileSafari(): boolean {
    return this.isMobile() && this.isSafari();
  }

  static pickImageVariant(
    thumbnails: Thumbnails | ProductImageVariants,
    maxQuality: boolean = false,
    isEmailVariant?: boolean
  ): string {
    if (isEmailVariant) {
      return thumbnails.VARIANT_THUMBNAIL_PNG_600;
    }

    let imagePath = thumbnails.VARIANT_LARGE_WEBP;
    if (maxQuality && imagePath) {
      return imagePath;
    }

    const imageMediumPath = thumbnails.VARIANT_MEDIUM_WEBP;
    const imageSmallPath = thumbnails.VARIANT_SMALL_WEBP;

    if ((BrowserUtils.isTablet() || (isTouchSupported && isMacOS)) && imageMediumPath) {
      imagePath = imageMediumPath;
    }

    if (BrowserUtils.isMobile() && imageSmallPath) {
      imagePath = imageSmallPath;
    }

    return imagePath;
  }
}
