import {EntranceGuide} from './entrance-guide';
import {isTouchSupported} from './utils/platform';

export const entranceGuideView = () => {
  const deviceView = isTouchSupported ? 'mobile' : 'desktop';

  return `
    <div
      id="entrance-guide"
      class="with-scroll is-hidden ${EntranceGuide.settings.disable_glow ? 'without-glow' : ''} ${EntranceGuide.settings.position}"
    >
        ${EntranceGuide.settings.entrance_animation_fonts.map(animation => {
          if (animation.hidden) {
            return;
          }
          return `
            <div class="${animation.action_type}-indicator guide-indicator is-hidden">
              <div class="indicator-container">
                <div class="indicator-text">${animation[deviceView].title}</div>
                ${animation.other_asset?.url ?
                  `<img alt="" class="indicator-icon" src="${animation.other_asset.url}">` :
                  ''
                }
              </div>
            </div>
          `;
        }).join('')}
    </div>
`;
};
