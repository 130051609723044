import {L10nUtils} from '../../utils/l10n_utils';
import {button} from '../components/button';

export const emailSentView = () => {
  return `
    <div class="account-view" id="account-email-sent">
      <div class="account-overlay-title">
        <span>${L10nUtils.l10n('account.panel.email-sent.title')}</span>
      </div>
      <div class="account-overlay-sent-message">
        <span>${L10nUtils.l10n('account.panel.email-sent.message')}</span>      
      </div>
      ${button({
        text: L10nUtils.l10n('account.panel.email-sent.sign-in-button.text'),
        className: 'link-to-sign-in'
      })}
    </div>
  `;
};
