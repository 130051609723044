import {ApiService} from '../api';
import {URLS, UrlUtils} from '../urls';

export class FontsService extends ApiService {

  getFonts(planogramId) {
    const url = UrlUtils.insertValueToUrl(URLS.CUSTOM_FONTS, planogramId);

    return this.get(url)
      .then(resp => resp.json());
  }
}
