import {Product} from '../../interfaces/product.interface';
import {L10nUtils} from '../../utils/l10n_utils';

export const productInfoPinsView = (product: Product, planogramName: string) => {

  return `
    <div id="product-details-container" class="app-hp24-pins is-view-gallery">
        <div class="app-product-overlay ${planogramName}-overlay">
            <div class="app-gallery-gradient"></div>
            <div class="app-product-overlay-controls">
              <div class="overlay-button overlay-button-back overlay-back" role="button" tabindex="0">
                <span></span>
              </div>
              <div class="overlay-button overlay-button-close" role="button" tabindex="0">
                <span></span>
              </div>
            </div>
            <div class="app-product-content-wrapper">
              <div class="app-product-details-header">
                <div class="app-product-code-row">
                  <div class="app-product-code">
                    ${product.identifier}
                  </div>
                  <span class="app-product-label-wrapper ${!product.label ? 'is-hidden' : ''}">
                    <span class="app-product-label">${product.label}</span>
                  </span>
                </div>
                <div class="app-product-title ">
                  ${product.name}
                </div>
              </div>
              <div class="app-product-carousel">
                <div id="app-image-carousel" class="splide">
                  <div class="splide__arrows">
                    <button class="splide__arrow splide__arrow--prev">
                      <span class="overlay-arrow overlay-arrow-prev"></span>
                    </button>
                    <button class="splide__arrow splide__arrow--next">
                      <span class="overlay-arrow overlay-arrow-next"></span>
                    </button>
                  </div>
                  <div class="splide__track">
                    <ul class="splide__list">
                    </ul>
                  </div>
                </div>
                <div class="app-thumbnail-carousel-container">
                  <div class="app-product-gallery-buttons-container">
                    <div id="info-gallery-button" role="button" tabindex="0">
                      <div>
                        <img alt="${L10nUtils.l10n('product-details-overlay.pins.info-gallery-button.alt-text')}">
                      </div>
                    </div>
                    <div id="checkout-gallery-button" class="is-completely-hidden" role="button" tabindex="0">
                      <div>
                        <img alt="${L10nUtils.l10n('product-details-overlay.pins.checkout-gallery-button.alt-text')}">
                      </div>
                    </div>
                  </div>
                  <div id="app-thumbnail-carousel" class="splide">
                    <div id="app-thumbnail-splide-arrows" class="splide__arrows">
                      <button class="splide__arrow splide__arrow--prev">
                        <span class="overlay-arrow overlay-arrow-prev"></span>
                      </button>
                      <button class="splide__arrow splide__arrow--next">
                        <span class="overlay-arrow overlay-arrow-next"></span>
                      </button>
                    </div>
                    <div class="splide__track">
                      <ul class="splide__list">
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="app-product-details-container">
                <div class="app-product-price is-hidden">
                  <span class="product-price-value"></span>
                  <span class="product-gold-price-container ${!product.discounted_price_in_user_currency_str ? 'is-hidden' : ''}">
                    <span class="product-gold-price-tip">${L10nUtils.l10n('product-details-overlay.pins.gold-price.tip')}</span>
                    <span class="product-gold-price-label">${L10nUtils.l10n('product-details-overlay.pins.gold-price.label')}</span>
                    <span class="product-gold-price-value">${product.discounted_price_in_user_currency_str}</span>
                  </span>
                </div>
                <div class="app-product-fields-container ${(!product.features &&
                    !product.edition && !product.collection && !product.released) ? 'is-hidden' : ''}"">
                  <div class="app-product-fields allow-scroll">
                    <table>
                      <tbody>
                        <tr class="product-field-row ${!product.features ? 'is-hidden' : ''}">
                          <td class="product-field-name">${L10nUtils.l10n('product-details-overlay.pins.metadata.features')}:</td>
                          <td class="product-field-info">${product.features}</td>
                        </tr>
                        <tr class="product-field-row ${!product.edition ? 'is-hidden' : ''}">
                          <td class="product-field-name">${L10nUtils.l10n('product-details-overlay.pins.metadata.features')}:</td>
                          <td class="product-field-info">${product.edition}</td>
                        </tr>
                        <tr class="product-field-row ${!product.collection ? 'is-hidden' : ''}">
                          <td class="product-field-name">${L10nUtils.l10n('product-details-overlay.pins.metadata.collection')}:</td>
                          <td class="product-field-info">${product.collection}</td>
                        </tr>
                        <tr class="product-field-row ${!product.released ? 'is-hidden' : ''}">
                          <td class="product-field-name">${L10nUtils.l10n('product-details-overlay.pins.metadata.released')}:</td>
                          <td class="product-field-info">${product.released}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="text-fade"></div>
                </div>
                <div class="app-buy-buttons-wrapper"></div>
                <div class="app-product-description">
                  <div id="product-description-button" class="overlay-btn app-product-description-title">
                    <div class="text">
                      ${L10nUtils.l10n('product-details-overlay.pins.information-button.text')}
                      <div class="overlay-button overlay-button-close app-product-description-icon-close" role="button" tabindex="0">
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <div class="app-product-description-text-wrapper">
                    <div class="app-description-arrow-close">
                      <span class="overlay-arrow overlay-arrow-next"></span>
                    </div>
                    <div class="app-product-description-text allow-scroll">
                      ${product.description}
                    </div>
                    <div class="text-fade"></div>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  `;
};
