import {Product} from '../../interfaces/product.interface';
import {isMobile} from '../../utils/platform';
import {InputEventUtils} from '../../utils/input_event_utils';
import {ProductInfoBase} from './product-info-base_overlay';
import {L10nUtils} from '../../utils/l10n_utils';
import {productInfoLegoView} from './product-info-lego.view';

const sanitizeHtml = require('sanitize-html');

export class ProductInfoLego extends ProductInfoBase {
  private productTitle: string;

  protected async init(): Promise<void> {
    await super.init();
    this.handlePolybagsProduct();
  }

  protected initOverlay() {
    super.initOverlay();
    this.initInfoTextControls();
  }

  handleClick(e) {
    if (this.isViewGallery()) {
      const tokens = ['app-product-overlay', 'app-product-content-wrapper', 'app-product-carousel'];
      if (e.target.closest('#app-image-carousel')) {
        tokens.push('splide__slide');
      }
      return tokens.some(token => e.target.classList.contains(token));
    } else {
      return e.target === this.mainContainer;
    }
  }

  protected setItemContent() {
    const description = this.product.description
      ? sanitizeHtml(this.product.description)
      : this.getDefaultDescription(this.product);
    const sanitizedProduct = {...this.product, description};
    this.productTitle = this.product.name;

    this.container.innerHTML = productInfoLegoView(sanitizedProduct, this.item.planogram.name);
  }

  private initInfoTextControls() {
    const infoButton = document.querySelector('.info-icon');
    const productOverlay = document.querySelector('.app-product-overlay');
    const toggleClassName = 'is-info-text-active';
    const parentContainer = document.querySelector('.app-product-price');

    const toggleInfoText = (e: Event): void => {
      const target = e.target as HTMLElement;
      e.stopPropagation();
      if (!parentContainer) {
        console.error('element not found - ".app-product-price"');
      }

      if (target.classList.contains('info-icon')) {
        parentContainer.classList.toggle(toggleClassName);
      } else {
        parentContainer.classList.remove(toggleClassName);
      }
    };

    if (isMobile) {
      InputEventUtils.addSelectEvents(infoButton, toggleInfoText);
      InputEventUtils.addSelectEvents(productOverlay, toggleInfoText);
    } else {
      InputEventUtils.addHoverEvent(infoButton, toggleInfoText);
    }
  }

  private getDefaultDescription(item: Product): string {
    return `
    <p class="${!item.name ? 'is-hidden' : ''}">${item.name}</p>
    <p>Code: ${item.identifier}</p>
    <p class="${!item.pieces ? 'is-hidden' : ''}">${L10nUtils.l10n('product-details-overlay.metadata.pieces')}: ${item.pieces}</p>
    <p class="${!item.age ? 'is-hidden' : ''}">${L10nUtils.l10n('product-details-overlay.metadata.age')}: ${item.age}</p>
    <p class="${!item.year ? 'is-hidden' : ''}">${L10nUtils.l10n('product-details-overlay.metadata.year')}: ${item.year}</p>
    `;
  }

  private handlePolybagsProduct() {
    if (this.product.is_polybags !== 'yes') {
      return;
    }
    // Hide price
    const priceContainer = document.querySelector('.app-product-price');
    priceContainer?.classList.add('is-hidden');

    // Hide custom buy button
    const customButton = document.getElementById('custom-button');
    customButton?.classList.add('is-hidden');

    // Hide rakuten buy button
    const rakutenButton = document.getElementById('rakuten-button');
    rakutenButton?.classList.add('is-hidden');
  }
}
