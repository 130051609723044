import {Options} from '@splidejs/splide';

import {ProductInfoAjTraceyView} from './product-info-ajtracey-view';
import {ProductInfoBase} from './product-info-base_overlay';
import {ProductSlides} from '../../product_slides';

export class ProductInfoAjTracey extends ProductInfoBase {
  protected async init(): Promise<void> {
    await super.init();
    this.handleStoryMode();
  }

  handleClick(e) {
    if (this.isViewGallery()) {
      const tokens = ['app-product-overlay', 'app-product-content-wrapper', 'app-product-carousel'];
      if (e.target.closest('#app-image-carousel')) {
        tokens.push('splide__slide');
      }
      return tokens.some(token => e.target.classList.contains(token));
    } else {
      return e.target === this.mainContainer;
    }
  }

  protected initDescriptionControls() {
    // No description
  }

  protected setItemContent() {
    this.container.innerHTML = ProductInfoAjTraceyView(
      {...this.product},
      this.item.planogram.name
    );
  }

  private handleStoryMode() {
    if (this.product.price_in_user_currency_str) {
      return;
    }

    // Hide custom button if price is empty
    const customButton = document.getElementById('custom-button');
    const galleryCheckoutButtonContainer = document.getElementById('checkout-gallery-button');
    const galleryInfoButtonContainer = document.getElementById('info-gallery-button');
    const checkoutButton = document.getElementById('checkout-button');

    this.mainContainer.classList.remove('checkout-flow');
    this.mainContainer.classList.add('story-mode');
    customButton?.classList.add('is-hidden');
    galleryInfoButtonContainer?.classList.add('no-checkout');
    galleryCheckoutButtonContainer?.classList.add('is-hidden');
    checkoutButton?.classList.add('is-hidden');
  }

  protected setThumbsSwitchLength() {
    if (this.gallerySplide && this.thumbnailSplide) {
      const thumbsLength = this.thumbnailSplide.length - 1;
      const maxLength = 5;
      const perPage = Math.min(thumbsLength, maxLength);

      this.thumbnailSplide.options = {perPage, focus: perPage - 1};
      this.gallerySplide.emit('resize', {});
      this.thumbnailSplide.emit('resize', {});
    }
  }

  protected getThumbsCarouselConfig(productSlides: ProductSlides): Options {
    return {
      autoWidth: true,
      autoHeight: true,
      gap: '1.46vw',
      speed: 150,
      rewind: true,
      pagination: false,
      isNavigation: true,
      lazyLoad: 'sequential',
      start: productSlides.imageIndex,
      perPage: Math.min(productSlides.length - 1, 5),
      focus: 4,
      perMove: 1,
      keyboard: false,
      breakpoints: {
        1200: {
          gap: 16
        }
      }
    };
  }
}
