import {ApiService} from '../api';
import {URLS, UrlUtils} from '../urls';
import {Product} from '../../interfaces/product.interface';
import {ButtonData} from '../../interfaces/buttons.interface';
import {PurchasingFlowSettingsData} from '../../interfaces/planogram.interface';
import {L10nUtils} from '../../utils/l10n_utils';

const storedButtonSettings: Map<string, Array<ButtonData>> = new Map();
const storedPurchasingFlowSettings: Map<string, PurchasingFlowSettingsData> = new Map();
const storedProductInfo: Map<string, Product> = new Map();

export class ProductInfoService extends ApiService {

  getView(code): Promise<Product> {
    const currentLang = L10nUtils.getCurrentLanguage();
    const productInfo = ProductInfoService.getProductInfo(code);
    const url = UrlUtils.insertFewValuesToUrl(
      URLS.PRODUCT_VIEW,
      {code, lang: currentLang}
    );

    if (productInfo) {
      return Promise.resolve(productInfo);
    }

    return this.get(url)
      .then(resp => resp.json())
      .then(product => {
        ProductInfoService.setProductInfo(product.identifier, product);
        return product;
      });
  }

  getEbayAffiliateInfo(item) {
    const url = UrlUtils.insertValueToUrl(URLS.PRODUCT_DETAILS, item.code || item.identifier);

    return this.get(url)
      .then(resp => resp.text())
      .then(resp => JSON.parse(resp));
  }

  getCustomButtonsSettings(): Promise<Array<ButtonData>> {
    const currentLang = L10nUtils.getCurrentLanguage();
    const buttonSettings = ProductInfoService.getButtonsInfo();
    const url = UrlUtils.insertValueToUrl(URLS.CUSTOM_BUTTONS, currentLang);

    if (buttonSettings) {
      return Promise.resolve(buttonSettings);
    }

    return this.get(url)
      .then(resp => resp.json())
      .then(buttons => {
        ProductInfoService.setButtonsInfo(buttons);
        return ProductInfoService.getButtonsInfo();
      });
  }

  getPurchasingFlowSettings(): Promise<PurchasingFlowSettingsData> {
    const purchasingFlowSettings = ProductInfoService.getPurchasingFlowSettings();

    if (purchasingFlowSettings) {
      return Promise.resolve(purchasingFlowSettings);
    }

    return this.get(URLS.PURCHASING_FLOW_SETTINGS)
      .then(resp => resp.json())
      .then((settings: PurchasingFlowSettingsData) => {
        ProductInfoService.setPurchasingFlowSettings(settings);
        return ProductInfoService.getPurchasingFlowSettings();
      });
  }

  static clearProductInfoCache() {
    storedProductInfo.clear();
  }

  static clearCustomButtonsSettingsCache() {
    storedButtonSettings.clear();
  }

  protected static getProductInfo(productIdentifier) {
    return storedProductInfo.get(productIdentifier);
  }

  protected static setProductInfo(productIdentifier, productData) {
    storedProductInfo.set(productIdentifier, productData);
  }

  private static getButtonsInfo() {
    return storedButtonSettings.get('buttons');
  }

  private static setButtonsInfo(buttons) {
    storedButtonSettings.set('buttons', buttons);
  }

  private static getPurchasingFlowSettings(): PurchasingFlowSettingsData {
    return storedPurchasingFlowSettings.get('purchasingFlow');
  }

  private static setPurchasingFlowSettings(settings: PurchasingFlowSettingsData) {
    return storedPurchasingFlowSettings.set('purchasingFlow', settings);
  }
}
