import makeBehaviorSubject from 'callbag-behavior-subject';
import {ApiService} from '../api';
import {URLS, UrlUtils} from '../urls';
import {SearchResultsData} from '../../interfaces/search-resutls.interface';
import {searchEventHandler, sphereEventHandler} from '../../custom_event_utils';
import {SEARCH_EVENT_NAMES as SEARCH_EVENTS, SPHERE_EVENT_NAMES as EVENTS} from '../../event-names';
import Router from '../../router';

export class SearchService extends ApiService {
  planogramName: string;
  searchResultsSubject: makeBehaviorSubject<object>;
  searchSettingsSubject: makeBehaviorSubject<object>;
  searchFilterSettingsSubject: makeBehaviorSubject<object>;
  private controllerAbort: AbortController;
  private planogramVersionId: number;

  constructor() {
    super();
    this.searchResultsSubject = makeBehaviorSubject(null);
    this.searchSettingsSubject = makeBehaviorSubject(null);
    this.searchFilterSettingsSubject = makeBehaviorSubject(null);

    sphereEventHandler.listen(EVENTS.PLANOGRAM_CHANGED, (planogram) => {
      this.planogramVersionId = planogram.versionId;
      this.planogramName = planogram.name;
    });
  }

  updateSearchFilters(results: SearchResultsData) {
    this.searchFilterSettingsSubject(1, results);
  }

  updateSearchSettings() {
    const searchSettingsUrl = UrlUtils.insertValueToUrl(
      URLS.SEARCH_SETTINGS,
      this.planogramVersionId
    );

    this.get(searchSettingsUrl)
      .then(resp => resp.json())
      .catch(err => {
        this.searchSettingsSubject(2, err);
      })
      .then(settings => {
        if (settings?.enabled === true) {
          this.searchSettingsSubject(1, settings);
        } else {
          this.searchSettingsSubject(2, undefined);
          console.warn('Search is disabled for this Sphere.');
        }
      });
  }

  filterResults(query: string, filterId = ''): Promise<SearchResultsData> {
    this.initAbortController();
    const filterUrl = UrlUtils.insertFewValuesToUrl(
      URLS.FILTER_URL,
      {planogramId: this.planogramVersionId, query: query, filterId: filterId}
    );

    return this.getWithAbort(filterUrl, this.controllerAbort.signal)
      .then(resp => resp.json())
      .then(results => {
        this.controllerAbort = null;
        this.searchResultsSubject(1, {results, filterId: filterId ? parseInt(filterId) : ''});
        return results;
      })
      .catch(err => {
        if (err instanceof DOMException && err.name === 'AbortError') {
          console.log('fetch aborted');
        } else {
          console.error(err);
        }
      });
  }

  toggleSearch(planogramName: string, isSearchActive: boolean) {
    Router.navigateToSearch(planogramName, isSearchActive);
  }

  updateSearchQuery(planogramName: string, searchQuery: string) {
    Router.updateSearchQuery(planogramName, searchQuery);
  }

  cancelPendingRequests() {
    if (!this.controllerAbort) {
      return;
    }

    searchEventHandler.emit(SEARCH_EVENTS.REQUEST_STATUS, {state: false});
    this.controllerAbort.abort();
  }

  private initAbortController() {
    this.cancelPendingRequests();
    this.controllerAbort = new AbortController();
  }
}
