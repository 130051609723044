import {L10nUtils} from '../utils/l10n_utils';
import {signInView} from './account-sign-in/account-sign-in.view';
import {resetPassView} from './account-reset-pass/account-reset-pass.view';
import {profileView} from './account-profile/account-profile.view';
import {signUpView} from './account-sign-up/account-sign-up.view';
import {emailSentView} from './account-email-sent/account-email-sent.view';
import {closeButton} from './components/close-button';
import {backButton} from './components/back-button';
import {loader} from '../components/loader';

export const accountView = (isHarryPotterClient: boolean) => {
  return `
    <div class="account-backdrop"></div>
    <div class="account-profile-drawer ${isHarryPotterClient ? 'app-shopify-harry-potter-template' : ''}">
        ${profileView()}
        ${loader({message: L10nUtils.l10n('account.profile.loader.text')})}
    </div>
    <div class="account-overlay-wrapper ${isHarryPotterClient ? 'app-shopify-harry-potter-template' : ''}">
        <div class="account-overlay-header">
            ${backButton()}
            ${closeButton()}
        </div>
        <div class="account-overlay-content">
          ${signInView()}
          ${signUpView()}
          ${resetPassView()}
          ${emailSentView()}
        </div>
        ${loader({message: L10nUtils.l10n('account.panel.loader.text')})}
    </div>
  `;
};
