import {LayoutSwitcher} from '../layout-switcher/layout-switcher';
import {sphereEventHandler} from '../../custom_event_utils';
import {SPHERE_EVENT_NAMES as EVENTS} from '../../event-names';
import {CurrencyService} from '../../api/services/currency.service';
import {CURRENCY_CODE_KEY, LAYOUT_SWITCHER_TYPE} from '../../shared/constants';

export class CurrencySwitcher {
  private readonly container: HTMLElement;
  private layoutSwitcher: LayoutSwitcher;
  private isOverlayActive: boolean;
  static readonly hideClass = 'is-hidden';

  constructor(private currencyService: CurrencyService) {
    this.onStorageChange = this.onStorageChange.bind(this);
    this.container = document.getElementById('currency-switcher');
    const { currenciesList } = this.currencyService;

    if (!currenciesList || currenciesList.length <= 1) {
      this.setAvailability(false);
      return;
    }

    this.initLayoutSwitcher();
    this.setAvailability(true);

    sphereEventHandler.listen(EVENTS.OVERLAY.SHOW_CONTENT, () => {
      this.isOverlayActive = true;
    });

    window.addEventListener('storage', this.onStorageChange);
  }

  private initLayoutSwitcher() {
    const { selectedCurrencyCode, currenciesList } = this.currencyService;

    this.layoutSwitcher = new LayoutSwitcher({
      container: this.container,
      type: LAYOUT_SWITCHER_TYPE.TEXT,
      children: currenciesList.map(({name, symbol}) => ({element: name, id: name, symbol: symbol})),
      handleSelect: (data) => this.currencyService.setCurrency(data)
    });
    this.layoutSwitcher.setActiveState(selectedCurrencyCode);
  }

  private setAvailability(data: boolean): void {
    if (data && !this.isOverlayActive) {
      this.container.classList.remove(CurrencySwitcher.hideClass);
    } else {
      this.container.classList.add(CurrencySwitcher.hideClass);
    }
  }

  private onStorageChange({storageArea, key, newValue}: StorageEvent) {
    if (storageArea === window.localStorage && key === CURRENCY_CODE_KEY && newValue) {
      this.layoutSwitcher.setActiveState(newValue);
    }
  }

  dispose() {
    window.removeEventListener('storage', this.onStorageChange);
    this.layoutSwitcher?.dispose();
  }
}
