import {HP_OVERLAY_THEMES, Product} from '../../../../interfaces/product.interface';
import {CheckboxFieldView} from '../components/checkbox-field/checkbox-field-component.view';
import '../../../../../styles/overlay/harry-potter-shopify/input-fields/index.scss';
import {InputFieldsSeekerTShirtComponentView} from './input-fields-seeker-t-shirt/input-fields-seeker-t-shirt-component.view';
import {InputFieldsTriwizardShirtComponentView} from './input-fields-triwizard-shirt/input-fields-triwizard-shirt-component.view';
import {InputFieldsCrestKeyringComponentView} from './input-fields-crest-keyring/input-fields-crest-keyring-component.view';
import {
  InputFieldsLetterKeychainView
} from './input-fields-letter-keychain/input-fields-letter-keychain-component.view';
import {InputFieldsNotebookComponentView} from './input-fields-notebook/input-fields-notebook-component.view';
import {InputFieldsRobeComponentView} from './input-fields-robe/input-fields-robe-component.view';
import {InputFieldsHogwartsTrunkComponentView} from './input-fields-hogwarts-trunk/input-fields-hogwarts-trunk-component.view';

export const InputFieldsComponentView = (product: Product) => {
  let withNoPersonalization = true;
  let inputs: string;

  switch (product.theme_name) {
    case HP_OVERLAY_THEMES.SEEKER_TSHIRT: {
      inputs = InputFieldsSeekerTShirtComponentView(product);
      break;
    }

    case HP_OVERLAY_THEMES.TRIWIZARD_SHIRT: {
      inputs = InputFieldsTriwizardShirtComponentView(product);
      break;
    }

    case HP_OVERLAY_THEMES.CREST_KEYRING: {
      inputs = InputFieldsCrestKeyringComponentView(product);
      withNoPersonalization = false;
      break;
    }

    case HP_OVERLAY_THEMES.LETTER_KEYCHAIN: {
      inputs = InputFieldsLetterKeychainView(product);
      withNoPersonalization = false;
      break;
    }

    case HP_OVERLAY_THEMES.NOTEBOOK: {
      inputs = InputFieldsNotebookComponentView(product);
      withNoPersonalization = false;
      break;
    }

    case HP_OVERLAY_THEMES.ROBE: {
      inputs = InputFieldsRobeComponentView(product);
      break;
    }

    case HP_OVERLAY_THEMES.HOGWARTS_TRUNK_US: {
      inputs = InputFieldsHogwartsTrunkComponentView(product);
      withNoPersonalization = false;
      break;
    }

    default: {
      inputs = '';
      withNoPersonalization = false;
    }
  }

  const inputsContainer = `<div class="app-shopify-product-input-fields-container">${inputs}</div>`;

  const withNoPersonalizationCheckbox = (component: string) => {
    return `
      <div class="input-fields-checkbox input-field-wrapper">${CheckboxFieldView('No Personalisation', 'harry-potter-personalization')}</div>
      <div class="input-fields-slide-container">${component}</div>
    `;
  };

  return `
    <div class="app-shopify-product-input-fields">
      ${withNoPersonalization ? withNoPersonalizationCheckbox(inputsContainer) : inputsContainer}
    </div>
  `;
};
