import {Product} from '../../../../../interfaces/product.interface';
import {InputFieldView} from '../../components/input-field/input-field-component.view';

export const InputFieldsRobeComponentView = (product: Product) => {
  return `
    <div class="input-field-wrapper">
      ${InputFieldView({label: product.input_fields[0].name, maxlength: 13})}
    </div>
  `;
};
