import {InputEventUtils} from '../utils/input_event_utils';
import {CookiesManagement} from '../cookies_management';
import {CameraControls} from './camera_controls';
import {SphereApp} from '../sphere_app';
import {sphereEventHandler} from '../custom_event_utils';
import {SPHERE_EVENT_NAMES} from '../event-names';
import {throttle, debounce} from 'throttle-debounce';
import {Vector2} from 'three';

const INPUT_DELAY_MILISECONDS = 150;

const MOUSE_BUTTONS = {
  LEFT: 1,
  MIDDLE: 4,
  RIGHT: 2
};

const WHEEL_ZOOM_FACTOR = 0.881;

const TRACKPAD_ZOOM_FACTOR = 0.93;

export class MouseControls {
  private pointerOnLeftDown: Vector2;
  private mouseDownEvent: MouseEvent;

  constructor(private domElement: HTMLElement, private cameraControls: CameraControls, private sphereApp: SphereApp) {
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onWheel = this.onWheel.bind(this);
    this.domElement.addEventListener('mousedown', e => this.onMouseDown(e), {capture: false});
    this.domElement.addEventListener('mousemove', e => this.onMouseMove(e), {capture: false});
    this.domElement.addEventListener('mouseup', e => this.onMouseUp(e), {capture: false});
    this.domElement.addEventListener('wheel', e => this.onWheel(e), {passive: false});
  }

  private onMouseDown(event: MouseEvent) {
    this.cameraControls.clearAnimation();
    this.pointerOnLeftDown = undefined;
    if (event.buttons === MOUSE_BUTTONS.LEFT && InputEventUtils.onSphereSurface(event)) {
      event.preventDefault();
      this.mouseDownEvent = event;
      this.pointerOnLeftDown = new Vector2(event.clientX, event.clientY);
      this.cameraControls.onMovementStart(this.pointerOnLeftDown);
    }
  }

  private throttleSetCursor = throttle(INPUT_DELAY_MILISECONDS, true, (e: MouseEvent) => this.sphereApp.setCursor(e));
  private debounceSetCursor = debounce(INPUT_DELAY_MILISECONDS, (e: MouseEvent) => this.sphereApp.setCursor(e));

  private onMouseMove(event: MouseEvent) {
    const x = event.clientX;
    const y = event.clientY;
    if (this.mouseDownEvent) {
      event.preventDefault();
      if (event.buttons === MOUSE_BUTTONS.LEFT) {
        this.sphereApp.isClusterSelected = false;

        this.cameraControls.tiltAndPanTo({x, y});
      } else {
        event.stopPropagation();
      }
    } else {
      this.throttleSetCursor(event);
      this.debounceSetCursor(event);
    }
  }

  private onMouseUp(event: MouseEvent) {
    const isRedirectAnimationProcessing = CookiesManagement.isRedirectAnimationProcessing;

    if (isRedirectAnimationProcessing) {
      this.cameraControls.clearAnimation(true);
    }
    if (!this.mouseDownEvent) {
      return;
    }
    event.preventDefault();
    if (this.isLeftButton()) {
      if (InputEventUtils.isClick(this.mouseDownEvent, event)) {
        sphereEventHandler.emit(SPHERE_EVENT_NAMES.CONTROL.CLICK, event);
        this.sphereApp.inputHandler.handleClick(event.clientX, event.clientY);
      } else {
        event.stopPropagation();
      }
      this.cameraControls.onMovementEnd();
    }
    this.mouseDownEvent = undefined;
  }

  private onWheel(event: WheelEvent) {
    this.cameraControls.clearAnimation();
    if (InputEventUtils.onSphereSurface(event)) {
      event.preventDefault();
      this.handleWheel(event);
    } else {
      event.stopPropagation();
    }
  }

  private handleWheel(event: WheelEvent) {
    const cursorPoint = {x: event.clientX, y: event.clientY};
    let zoomFactor;
    if (event.deltaY < 0) {
      if (this.isTrackPad(event)) {
        zoomFactor = TRACKPAD_ZOOM_FACTOR;
      } else {
        zoomFactor = WHEEL_ZOOM_FACTOR;
      }
    } else if (event.deltaY > 0) {
      if (this.isTrackPad(event)) {
        zoomFactor = 1 / TRACKPAD_ZOOM_FACTOR;
      } else {
        zoomFactor = 1 / WHEEL_ZOOM_FACTOR;
      }
    }

    if (zoomFactor) {
      this.sphereApp.isClusterSelected = false;
      this.cameraControls.zoomToPoint(cursorPoint, zoomFactor);
    }
  }

  private isTrackPad(event: WheelEvent) {
    return Number.isInteger(event.deltaY);
  }

  private isLeftButton() {
    return this.pointerOnLeftDown !== undefined;
  }

  dispose() {
    this.domElement.removeEventListener('mousedown', this.onMouseDown, {capture: false});
    this.domElement.removeEventListener('mousemove', this.onMouseMove, {capture: false});
    this.domElement.removeEventListener('mouseup', this.onMouseUp, {capture: false});
    this.domElement.removeEventListener('wheel', this.onWheel);
  }
}
