import {ApiService} from '../api';
import {URLS} from '../urls';

export class AppService extends ApiService {

  getAppVersion() {
    return this.get(URLS.APP_VERSION)
      .then(resp => resp.text());
  }
}
