import {InputFieldsBaseComponent} from '../input-fields-base/input-fields-base-component';
import {Product} from '../../../../../interfaces/product.interface';
import {InputFieldComponent} from '../../components/input-field/input-field-component';

export class InputFieldsHogwartsTrunkComponent extends InputFieldsBaseComponent {
  constructor(container: HTMLElement, product: Product) {
    super(container, product);

    const inputFields = this.product.input_fields;
    const inputWrapper = this.container.querySelector('.input-field-component') as HTMLElement;

    this.setField(
      inputFields[0].name,
      new InputFieldComponent(inputWrapper, {
        uppercase: true,
        validationPattern: /[^a-zA-Z]+$/g
      })
    );
  }
}
