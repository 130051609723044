import {Vector2} from 'three';
import {ItemLOD, ItemTile, UV} from '../interfaces/planogram.interface';
import {PhysicalPage, VirtualBoundaries, VirtualCoordinates} from '../interfaces/vt_pipeline.interface';
import {ArrayUtils} from '../utils/array_utils';

export class ItemVirtualPagesManager {
  private virtualTextureSize: number;
  private virtualTableHead: [number, number];
  private itemsVirtualPageMap: Map<string | number, ItemVirtualPage> = new Map();
  private itemsVirtualTable: boolean[][];

  constructor(virtualTextureSize: number) {
    this.virtualTextureSize = virtualTextureSize;
    this.itemsVirtualTable = ArrayUtils.createMultiArray(virtualTextureSize, virtualTextureSize);
    this.virtualTableHead = [0, 0];
  }

  createItemVirtualPage(id: string, lods: ItemLOD[]) {
    const bestLod = lods?.reduce((acc, cur) => (acc.lod > cur.lod ? cur : acc));

    if (!bestLod) {
      console.error('No lod on item', id);
      return;
    }
    const pageSize: number = Math.round(Math.sqrt(bestLod.textures.length));

    let foundSpot = false;
    let tableX = this.virtualTableHead[0];
    let tableY = this.virtualTableHead[1];

    while (!foundSpot && tableY < this.virtualTextureSize) {
      if (tableX + pageSize > this.virtualTextureSize) {
        tableX = 0;
        tableY += 1;
        continue;
      }

      foundSpot = true;
      loop: for (let x = tableX; x < tableX + pageSize; x++) {
        for (let y = tableY; y < tableY + pageSize; y++) {
          if (!!this.itemsVirtualTable[x][y]) {
            foundSpot = false;
            break loop;
          }
        }
      }
      if (!foundSpot) {
        tableX++;
      }
    }
    if (!foundSpot) {
      console.error('No spot found!');
      return undefined;
    }

    for (let x = tableX; x < tableX + pageSize; x++) {
      for (let y = tableY; y < tableY + pageSize; y++) {
        this.itemsVirtualTable[x][y] = true;
      }
    }

    this.virtualTableHead[0] = tableX + pageSize;
    this.virtualTableHead[1] = tableY;

    const virtualCoordinates = {
      x: tableX,
      y: tableY,
      size: pageSize,
      lod: undefined
    };

    this.itemsVirtualPageMap.set(id, new ItemVirtualPage(lods, virtualCoordinates));
  }

  getItemVirtualPage(itemId: string) {
    const found = this.itemsVirtualPageMap.get(itemId);
    if (!found) {
      throw new Error(`Item not found in virtual page map! ${itemId}`);
    }
    return found;
  }

  getVirtualSlot(id: string, x: number, y: number, lod: number) {
    return this.getItemVirtualPage(id).root.getVirtualSlotAtCoordinates(x, y, lod);
  }
}

/**
 *  Manages all the lods for a specific item.
 */
class ItemVirtualPage {
  virtualCoordinates: VirtualCoordinates;
  highestLod: number = 0;
  root: VirtualSlot;

  constructor(lods: ItemLOD[], virtualCoordinates: VirtualCoordinates) {
    this.virtualCoordinates = virtualCoordinates;
    this.generateVirtualSlots(lods);
  }

  private generateVirtualSlots(lods: ItemLOD[]) {
    lods.sort((a, b) => {
      return b.lod - a.lod;
    });

    const worstLod = lods[0];

    const virtualSlot = new VirtualSlot(
      undefined,
      0,
      0,
      {...this.virtualCoordinates, lod: worstLod.lod},
      worstLod.textures[0]
    );

    this.root = virtualSlot;

    // Build the lod tree
    this.generateSlot(virtualSlot, worstLod.gridSize, lods, 1);
  }

  private generateSlot(parent: VirtualSlot, parentGridSize: number, lods: Array<ItemLOD>, lodIndex: number) {
    const itemLod = lods[lodIndex];
    if (!itemLod) {
      return;
    }
    const gridSize = Math.round(Math.sqrt(itemLod.textures.length));

    // Checks if the parent splits into more tiles
    if (gridSize === parentGridSize) {
      const {x, y} = parent;
      const newVirtualSlot = new VirtualSlot(
        parent,
        x,
        y,
        {...this.virtualCoordinates, lod: itemLod.lod},
        itemLod.textures[x * gridSize + y]
      );

      parent.setDirectChild(newVirtualSlot);
      this.generateSlot(newVirtualSlot, gridSize, lods, lodIndex + 1);
    } else {
      const slotVirtualSize = this.virtualCoordinates.size / gridSize;

      const parentX = parent.x * 2;
      const parentY = parent.y * 2;
      for (let relativeX = 0; relativeX < 2; relativeX++) {
        for (let relativeY = 0; relativeY < 2; relativeY++) {
          const x = parentX + relativeX;
          const y = parentY + relativeY;
          const slotVirtualCoordinates = {
            x: this.virtualCoordinates.x + x * slotVirtualSize,
            y: this.virtualCoordinates.y + (gridSize - 1 - y) * slotVirtualSize,
            size: slotVirtualSize,
            lod: itemLod.lod
          };
          const index = x * gridSize + y;
          const texture = itemLod.textures[index];
          if (!texture) continue;
          const newVirtualSlot = new VirtualSlot(parent, x, y, slotVirtualCoordinates, texture);

          parent.setChild(relativeX + relativeY * 2, newVirtualSlot);
          this.generateSlot(newVirtualSlot, gridSize, lods, lodIndex + 1);
        }
      }
    }
  }
}

/**
 * Represents the individual texture of an item at a certain LOD. These are then loaded into the
 * physical texture when coming into viewport
 */
export class VirtualSlot {
  x: number;
  y: number;

  center: Vector2;

  // The coordinates on the virtual texture
  vtCoordinates: VirtualCoordinates;

  // The location on the viewport
  virtualBoundaries: VirtualBoundaries;

  physicalPage: PhysicalPage;
  lod: number;
  url?: string;
  uv: UV;
  permanent: boolean = false;

  parent: VirtualSlot;

  // If the LOD doesn't split into more textures
  directChild: VirtualSlot;

  children = new Array<VirtualSlot>(4);

  requested: boolean = false;

  // Tells us if the slot is present on a physical page
  get hasPhysicalPage(): boolean {
    return !!this.physicalPage;
  }

  constructor(parent: VirtualSlot, x: number, y: number, vtCoordinates: VirtualCoordinates, itemTile: ItemTile) {
    this.x = x;
    this.y = y;
    this.parent = parent;
    this.vtCoordinates = vtCoordinates;
    this.center = itemTile.center;
    this.url = itemTile.url;
    this.uv = itemTile.uv || {x: 0, y: 0, width: 1, height: 1};
    this.virtualBoundaries = itemTile.boundaries;
    this.lod = this.vtCoordinates.lod;
  }

  gethigherLODVirtualSlot(): VirtualSlot | undefined {
    if (!this.parent) {
      return undefined;
    }
    if (this.parent.hasPhysicalPage) {
      return this.parent;
    } else {
      return this.parent.gethigherLODVirtualSlot();
    }
  }

  getVirtualSlotAtCoordinates(x: number, y: number, lod: number): VirtualSlot {
    if (this.lod < lod) {
      console.error("Couldn't find the virtual slot at the specified coordinates");
      return undefined;
    }

    if (this.lod === lod) {
      return this;
    }

    if (this.directChild) {
      return this.directChild.getVirtualSlotAtCoordinates(x, y, lod);
    }

    const {childX, childY} = this.getChildAtCoordinates(x, y, lod);

    return this.children[childX + childY * 2].getVirtualSlotAtCoordinates(x, y, lod);
  }

  private getChildAtCoordinates(x: number, y: number, lod: number): {childX: number; childY: number} {
    const factor = 2 ** (lod - this.lod + 1);
    return {
      childX: Math.floor(x * factor) % 2,
      childY: Math.floor(y * factor) % 2
    };
  }

  setDirectChild(virtualSlot: VirtualSlot) {
    this.directChild = virtualSlot;
  }

  setChild(location: number, virtualSlot: VirtualSlot) {
    this.children[location] = virtualSlot;
  }
}
