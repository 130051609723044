import {productReleaseCountdownOverlay} from './product-release-countdown-overlay.view';
import {CountdownTimeValues, ProductReleaseCountdownData} from '../../interfaces/product.interface';
import {getParsedTime} from '../../utils/countdown_utils';

export class ProductReleaseCountdownOverlay {

  private productReleaseCountdownContainer: HTMLElement;

  private countdownPartsElements: {
    days: HTMLElement,
    hours: HTMLElement,
    minutes: HTMLElement,
    seconds: HTMLElement
  };

  private changeURLAfterClosingOverlay = true;
  private intervalId: number;
  private countdownState: CountdownTimeValues;

  private readonly showProductInfoOverlayCallback: Function;
  private readonly productReleaseData: ProductReleaseCountdownData;

  constructor(private container: HTMLElement,
              private options: any,
              private closeCallback: Function) {
    this.showProductInfoOverlayCallback = options.showProductInfoOverlayCallback;
    this.productReleaseData = options.productReleaseData;

    if (!this.productReleaseData) {
      this.closeOverlay();
      return;
    }

    this.setContent();
    this.initOverlayElements();
    this.showCountdown();
  }

  // Is for parent Overlay class
  dispose() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  // Is for parent Overlay class
  handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
    this.closeOverlay();
    return false;
  }

  // Is for parent Overlay class; Do not rename this method
  closeOverlay(e?: Event) {
    if (e) {
      e.stopPropagation();
    }
    if (this.closeCallback) {
      this.closeCallback(this.changeURLAfterClosingOverlay);
    } else {
      console.error('"Close" function is not set up');
    }
  }

  private setContent() {
    this.container.innerHTML = productReleaseCountdownOverlay(this.productReleaseData);
  }

  private initOverlayElements() {
    this.productReleaseCountdownContainer = this.container.querySelector('#product-release-countdown-container');
    this.countdownPartsElements = {
      days: this.productReleaseCountdownContainer.querySelector('.app-countdown-days'),
      hours: this.productReleaseCountdownContainer.querySelector('.app-countdown-hours'),
      minutes: this.productReleaseCountdownContainer.querySelector('.app-countdown-minutes'),
      seconds: this.productReleaseCountdownContainer.querySelector('.app-countdown-seconds')
    };
  }

  private openProductOverlay() {
    this.changeURLAfterClosingOverlay = false;
    this.closeOverlay();
    this.showProductInfoOverlayCallback();
  }

  private showCountdown() {
    const serverDateTime = new Date(this.productReleaseData.server_time);
    const releaseDateTime = new Date(this.productReleaseData.release_time);

    const currentAndReleaseDiff = serverDateTime.getTime() - releaseDateTime.getTime();

    if (currentAndReleaseDiff < 0) {
      this.runInterval(Math.abs(currentAndReleaseDiff));
    } else {
      // out of limits; hide countdown and show coming soon
      this.openProductOverlay();
    }
  }

  private runInterval(initialDistance: number) {
    let distance = initialDistance;

    this.countdownState = new CountdownTimeValues();

    this.handleCountdownChanges(getParsedTime(distance));
    this.intervalId = window.setInterval(() => {
      distance -= 1000;
      if (distance <= 0) {
        clearInterval(this.intervalId);
        this.openProductOverlay();
        return;
      }
      this.handleCountdownChanges(getParsedTime(distance));
    }, 1000);
  }

  private handleCountdownChanges(data: CountdownTimeValues) {
    if (data.days === '0') {
      this.productReleaseCountdownContainer.classList.add('app-countdown-without-days');
    } else {
      this.productReleaseCountdownContainer.classList.remove('app-countdown-without-days');
    }
    this.countdownPartsElements.days.innerText = data.days;
    this.countdownPartsElements.hours.innerText = data.hours;
    this.countdownPartsElements.minutes.innerText = data.minutes;
    this.countdownPartsElements.seconds.innerText = data.seconds;
  }
}
