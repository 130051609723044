import {shoppingCartItemComponentView} from './shopping-cart-item_component.view';
import {InputEventUtils} from '../../utils/input_event_utils';
import {ShoppingCartVariant} from '../../interfaces/shopping-cart.interface';
import {ShoppingCartItems} from '../shopping-cart-items';
import {Metrics} from '../../metrics';
import {MATOMO_EVENT_NAMES} from '../../metric-events';
import {AppState} from '../../shared/app.state';
import {CurrencyService} from '../../api/services/currency.service';
import {CurrencyUtils} from '../../utils/currency_utils';
import {ShoppingCartService} from '../../api/services/shopping-cart.service';
import {AppUtils} from '../../utils/app_utils';

export class ShoppingCartItemComponent {
  private readonly mainContainer: HTMLElement;
  private overallQuantity: number;

  constructor(private shoppingCartItems: ShoppingCartItems,
    private product: ShoppingCartVariant,
    private currencyService: CurrencyService,
    private shoppingCartService: ShoppingCartService
  ) {
    this.mainContainer = document.createElement('div');
    InputEventUtils.addSelectEvents(
      this.mainContainer,
      this.handleClicks.bind(this)
    );
  }

  renderItem() {
    this.mainContainer.classList.add('app-shopping-cart-item');
    if (this.product.quantity_available <= 0) {
      this.product.quantity = 0;
      this.mainContainer.classList.add('out-of-stock');
    }
    const shoppingCartItemContainer = document.createElement('div');
    this.mainContainer.append(shoppingCartItemContainer);
    shoppingCartItemContainer.outerHTML = shoppingCartItemComponentView(this.product, this.getProductPrice());
    this.updateDisableState();
    if (this.product.per_order_limit && this.overallQuantity > this.product.per_order_limit) {
      this.mainContainer.classList.add('limit-error');
    }
    return this.mainContainer;
  }

  private handleClicks(e) {
    if (this.shoppingCartItems.processing) {
      return;
    }
    e.stopPropagation();
    const quantityEl = e.target.closest('.app-shopping-cart-item-quantity-button');
    const removeEl = e.target.closest('.app-shopping-cart-item-delete');
    const productTitle = e.target.closest('.app-shopping-cart-item-title');

    if (productTitle) {
      this.shoppingCartItems.navigateToProduct(this.product);
    }

    if (quantityEl) {
      this.changeQuantity(quantityEl);
    }

    if (removeEl) {
      this.deleteItem();
    }
  }

  private changeQuantity(el: HTMLElement) {
    const operation = el.classList.contains('app-shopping-cart-item-increase') ? 'increase' : 'decrease';
    const currQuantity = this.product.quantity;
    switch (operation) {
      case 'increase': {
        if (this.overallQuantity + 1 <= this.product.quantity_available) {
          if (this.product?.per_order_limit && this.overallQuantity + 1 > this.product?.per_order_limit) {
            return;
          }
          this.product.quantity += 1;
          Metrics.storeTheEvent(
            AppState.planogramName,
            'click',
            MATOMO_EVENT_NAMES
              .CLICK_SHOPPING_CART_OVERLAY_INCREASE_ITEM_QUANTITY(this.product?.product_id, this.product?.title)
          );
          this.shoppingCartItems.updateShoppingCart(this.product);
        }
        break;
      }
      case 'decrease': {
        if (currQuantity - 1 >= 1) {
          this.product.quantity -= 1;
          Metrics.storeTheEvent(
            AppState.planogramName,
            'click',
            MATOMO_EVENT_NAMES
              .CLICK_SHOPPING_CART_OVERLAY_DECREASE_ITEM_QUANTITY(this.product?.product_id, this.product?.title)
          );
          this.shoppingCartItems.updateShoppingCart(this.product);
        }
        break;
      }
    }
    this.updateDisableState();
  }

  private updateDisableState() {
    const increaseButton = this.mainContainer.querySelector('.app-shopping-cart-item-increase');
    const decreaseButton = this.mainContainer.querySelector('.app-shopping-cart-item-decrease');
    this.countOverallQuantity();

    if (increaseButton) {
      this.product?.per_order_limit && this.overallQuantity >= this.product?.per_order_limit ||
      this.overallQuantity >= this.product.quantity_available ?
        increaseButton.classList.add('disabled') :
        increaseButton.classList.remove('disabled');
    }

    if (decreaseButton) {
      this.product.quantity <= 1 ?
        decreaseButton.classList.add('disabled') :
        decreaseButton.classList.remove('disabled');
    }
  }

  private countOverallQuantity() {
    this.overallQuantity = AppUtils.overallQuantity(this.shoppingCartService.shoppingCart, this.product, this.product?.per_order_limit);
  }

  private deleteItem() {
    Metrics.storeTheEvent(
      AppState.planogramName,
      'click',
      MATOMO_EVENT_NAMES.CLICK_SHOPPING_CART_OVERLAY_REMOVE_PRODUCT(this.product?.product_id, this.product?.title)
    );
    this.shoppingCartItems.removeItemFromCart(this.product);
  }

  private getProductPrice() {
    const {selectedCurrencyCode, currenciesList} = this.currencyService;
    const totalPrice = (parseFloat(this.product.price) * this.product.quantity).toFixed(2);

    return CurrencyUtils.getPriceWithSymbolOrCode(totalPrice, selectedCurrencyCode, currenciesList);
  }
}
