import Router from './router';
import {InputEventUtils} from './utils/input_event_utils';
import {Privacy} from './overlays/privacy';
import {ProductInfoLego} from './overlays/product-info/product-info-lego';
import {ProductInfoPins} from './overlays/product-info/product-info-pins';
import {ProductInfoService} from './api/services/product_info.service';
import {CookiesTable} from './overlays/cookies-table';
import {CUSTOM_OVERLAYS, PRODUCT_PAGE_ROUTES, SPHERE_ITEM_TYPES} from './shared/constants';
import {SphereApp} from './sphere_app';
import {overlayEventsHandler, searchEventHandler, sphereEventHandler} from './custom_event_utils';
import {SphereItem} from './sphere_item';
import {ImageView} from './overlays/image-view';
import {VideoOverlay} from './overlays/video-overlay';
import {Metrics} from './metrics';
import {MATOMO_EVENT_NAMES} from './metric-events';
import {
  ACTION_TYPE,
  ContentOverlayActionData,
  ItemData,
  LinkActionData,
  SOCIAL_MEDIA_TYPE,
  SocialContentActionData
} from './interfaces/planogram.interface';
import {SEARCH_EVENT_NAMES as SEARCH_EVENTS, SPHERE_EVENT_NAMES as EVENTS} from './event-names';
import {IframeOverlay} from './overlays/iframe-overlay';
import {UrlUtils} from './api/urls';
import {AppUtils} from './utils/app_utils';
import {WebUtils} from './utils/web_utils';
import {ProductReleaseCountdownOverlay} from './overlays/product-release-countdown/product-release-countdown-overlay';
import {Product, ProductReleaseCountdownData} from './interfaces/product.interface';
import {ProductInfoBase} from './overlays/product-info/product-info-base_overlay';
import {InfoOverlay} from './overlays/infos-base_overlay';
import {ProductInfoSelfridges} from './overlays/product-info/product-info-selfridges';
import {ProductInfoUal} from './overlays/product-info/product-info-ual';
import {CheckoutOverlay} from './overlays/checkout/checkout';
import {ProductInfoSothebys} from './overlays/product-info/product-info-sothebys';
import {ProductInfoAjTracey} from './overlays/product-info/product-info-ajtracey';
import {ProductInfoNinjago} from './overlays/product-info/product-info-ninjago';
import {ProductInfoDefault} from './overlays/product-info/product-info-default';
import {updateUIElementsVisibility} from './utils/ui_elements_utils';
import {ProductInfoKhalili} from './overlays/product-info/product-info-khalili';
import {ShopifyProductInfo} from './overlays/shopify-product-info/shopify-product_info';
import {ShopifyProductInfoService} from './api/services/shopify-product_info.service';
import {ShopifyHarryPotterProductInfo} from './overlays/shopify-product-info/harry-potter-overlays/shopify-harry-potter-product_info';
import {ContentOverlay} from './overlays/content-overlay';
import {VideoComponent} from './components/video';
import {ShareOverlay} from './overlays/share-overlay';
import {SocialContentOverlay} from './overlays/social-content_overlay';
import {CurrencyService} from './api/services/currency.service';
import {Planogram} from './planogram';
import {ShoppingCartService} from './api/services/shopping-cart.service';

export class Overlay {
  // TODO Avoid using reverse dependencies
  private readonly wrapper: HTMLElement;

  private content: any; // one of the overlay; create base class for them
  private item: ItemData | SphereItem;
  private productOverlayType: PRODUCT_PAGE_ROUTES;
  private productOverlayPrevState: string;
  private startY: number = 0;

  private startEvent: Event;

  private readonly showCookiesOverlayBound: Function;
  private readonly showFullCookiesOverlayBound: Function;
  private readonly hideCookieTableBound: Function;
  private readonly handleStartEventBound: Function;
  private readonly handleWheelEventBound: Function;
  private readonly handleEndEventBound: Function;

  constructor(
    private sphereApp: SphereApp,
    private planogram: Planogram,
    private currencyService: CurrencyService,
    private shoppingCartService: ShoppingCartService
  ) {
    this.wrapper = document.getElementById('overlay');

    this.showCookiesOverlayBound = this.showCookiesOverlay.bind(this);
    this.showFullCookiesOverlayBound = this.showCookiesOverlay.bind(this, {
      isFullTable: true,
      cleanItem: true
    });
    this.hideCookieTableBound = this.hideCookieOverlay.bind(this);
    this.handleStartEventBound = this._handleStartEvent.bind(this);
    this.handleWheelEventBound = this._handleWheelEvent.bind(this);
    this.handleEndEventBound = this._handleEndEvent.bind(this);

    overlayEventsHandler.listen('showCookieTable', this.showCookiesOverlayBound);
    overlayEventsHandler.listen('showFullCookieTable', data => this.showFullCookiesOverlayBound(data));
    overlayEventsHandler.listen('hideCookieTable', this.hideCookieTableBound);

    ['mousedown', 'touchstart'].forEach(trigger => {
      this.wrapper.addEventListener(trigger, this.handleStartEventBound as any);
    });
    this.wrapper.addEventListener('wheel', this.handleWheelEventBound as any);
    ['mouseup', 'touchend'].forEach(trigger => {
      this.wrapper.addEventListener(trigger, this.handleEndEventBound as any);
    });

    this.showVideoShare = this.showVideoShare.bind(this);
    sphereEventHandler.listen(EVENTS.VIDEO.SHARE, this.showVideoShare);
  }

  dispose() {
    overlayEventsHandler.off('showCookieTable', this.showCookiesOverlayBound);
    overlayEventsHandler.off('showFullCookieTable', this.showFullCookiesOverlayBound);
    overlayEventsHandler.off('hideCookieTable', this.hideCookieTableBound);

    ['mousedown', 'touchstart'].forEach(trigger => {
      this.wrapper.removeEventListener(trigger, this.handleStartEventBound as any);
    });
    this.wrapper.removeEventListener('wheel', this.handleWheelEventBound as any);
    ['mouseup', 'touchend'].forEach(trigger => {
      this.wrapper.removeEventListener(trigger, this.handleEndEventBound as any);
    });

    sphereEventHandler.off(EVENTS.VIDEO.SHARE, this.showVideoShare);
    this.content?.dispose?.();
  }

  hide(withNavigation = false) {
    if (this.content && this.content.dispose) {
      this.content.dispose();
    }
    searchEventHandler.emit(SEARCH_EVENTS.CLOSE_SEARCH);
    this.wrapper.classList.add('is-hidden');
    this.wrapper.innerHTML = '';
    this.content = undefined;
    this.sphereApp.unBlurGlCanvas();
    this.sphereApp.isOverlayActive = false;

    updateUIElementsVisibility(true);
    if (withNavigation) {
      Router.navigateToPlanogram(this.planogram.name);
    }
  }

  resize() {
    if (this.content.resize) {
      this.content.resize();
    }
  }

  isShowing(): boolean {
    return !!this.content;
  }

  showContentOverlay(item: SphereItem) {
    const blurGlCanvas = !(item.action.data as ContentOverlayActionData)?.transparent;
    if (item.action?.type !== ACTION_TYPE.CONTENT_OVERLAY) {
      throw new Error('Item must have a content overlay action');
    }
    this._showContent(
      new ContentOverlay(
        this.wrapper,
        (item.action.data as ContentOverlayActionData).url,
        this.closeOverlay.bind(this),
        (item.action.data as ContentOverlayActionData).transparent
      ),
      blurGlCanvas
    );
  }

  private lastItemWithOpenedOverlay: SphereItem;
  // TODO Simplify this logic in future
  showItem(item, overlayType = PRODUCT_PAGE_ROUTES.GALLERY) {
    if (overlayType === PRODUCT_PAGE_ROUTES.SHOW) {
      return;
    }
    searchEventHandler.emit(SEARCH_EVENTS.CLOSE_SEARCH);
    if (this.isTheSameProductOpened(item) && overlayType !== PRODUCT_PAGE_ROUTES.CHECKOUT) {
      if (this.productOverlayType !== overlayType) {
        this.content.toggleView();
        this.productOverlayType = overlayType;
        this.productOverlayPrevState = overlayType;
      }
      return;
    }
    if (overlayType === PRODUCT_PAGE_ROUTES.CHECKOUT) {
      if (!this.isCheckoutPageOpened()) {
        this.showCheckoutOverlay(item, this.productOverlayPrevState);
      }
      return;
    }
    this.item = item;
    this.productOverlayType = overlayType;
    this.productOverlayPrevState = overlayType;

    if (
      item?.action &&
      item.action.type !== ACTION_TYPE.PRODUCT_RELEASE_COUNTDOWN &&
      item.action.type !== ACTION_TYPE.PRODUCT &&
      item.action.type !== ACTION_TYPE.PRODUCT_OVERLAY
    ) {
      if (item.action?.type?.startsWith('sphere-')) {
        return this.handleSphereNavigationAction(item);
      }
      return this.showCustomOverlay(item);
    }

    if (!item) {
      return;
    }

    this.showProductInfo(item, overlayType);
    const productName = UrlUtils.slugify(item.name || '');
    Metrics.storeTheEvent(
      item.planogram.name,
      'open',
      `${MATOMO_EVENT_NAMES.WEBGL_OPEN_PRODUCT_OVERLAY}-${item.identifier}-${productName}`
    );
  }

  private isTheSameProductOpened(item): boolean {
    return item === this.item && this.content instanceof ProductInfoBase;
  }

  private isCheckoutPageOpened() {
    return !this.item && this.content instanceof CheckoutOverlay;
  }

  handleSphereNavigationAction(item) {
    if (!item || !item.action) {
      return;
    }
    const sphereName = item.action.type.replace('sphere-', '');

    if (this.planogram.name === sphereName) {
      Metrics.storeTheEvent(item.planogram.name, 'click', MATOMO_EVENT_NAMES.WEBGL_CLICK_CENTRAL_CARD);
    } else {
      Metrics.storeTheEvent(this.planogram.name, 'click', `${MATOMO_EVENT_NAMES.WEBGL_CLICK_SPHERE}-${sphereName}`);
    }
    Router.navigateToPlanogram(sphereName);
  }

  private showCustomOverlay(item: SphereItem) {
    const action = item.action;
    const itemName = WebUtils.getItemName(item);
    switch (action.type) {
      case ACTION_TYPE.ABOUT_US:
        Metrics.storeTheEvent(this.planogram.name, 'open', MATOMO_EVENT_NAMES.WEBGL_CLICK_ABOUT_US);
        this.showAboutUsOverlay();
        break;
      case ACTION_TYPE.CONTACT_US:
        Metrics.storeTheEvent(this.planogram.name, 'open', MATOMO_EVENT_NAMES.WEBGL_CLICK_CONTACT_US);
        this.showContactUsOverlay();
        break;
      case ACTION_TYPE.COPYRIGHT:
        this.showCopyrightOverlay();
        Metrics.storeTheEvent(this.planogram.name, 'open', MATOMO_EVENT_NAMES.WEBGL_OPEN_COPYRIGHT_OVERLAY);
        break;
      case ACTION_TYPE.PRIVACY_POLICY:
        this.showPrivacyOverlay();
        Metrics.storeTheEvent(this.planogram.name, 'open', MATOMO_EVENT_NAMES.WEBGL_OPEN_PRIVACY_OVERLAY);
        break;
      case ACTION_TYPE.COOKIES_POLICY:
        this.showCookiesPolicyOverlay();
        Metrics.storeTheEvent(item.planogram.name, 'open', MATOMO_EVENT_NAMES.WEBGL_OPEN_COOKIES_POLICY_OVERLAY);
        Router.navigateToInfoOverlay(this.planogram.name, CUSTOM_OVERLAYS.COOKIES_POLICY);
        break;
      case ACTION_TYPE.IFRAME:
        this.showIframeOverlay((item.action.data as LinkActionData).url);
        Metrics.storeTheEvent(this.planogram.name, 'click', `${MATOMO_EVENT_NAMES.WEBGL_CLICK_IFRAME}${itemName}`);
        break;
      case ACTION_TYPE.VIDEO_OVERLAY:
        this.showVideoOverlay((item.action.data as LinkActionData).url);
        Metrics.storeTheEvent(this.planogram.name, 'open', `${MATOMO_EVENT_NAMES.WEBGL_OPEN_VIDEO_OVERLAY}${itemName}`);
        break;
      case ACTION_TYPE.CONTENT_OVERLAY:
        this.showContentOverlay(item);
        Metrics.storeTheEvent(
          this.planogram.name,
          'open',
          `${MATOMO_EVENT_NAMES.WEBGL_CLICK_CONTENT_OVERLAY}-${(action.data as ContentOverlayActionData).iframeLink}`
        );
        break;
      case ACTION_TYPE.SOCIAL_CONTENT_OVERLAY:
        const source = (action.data as SocialContentActionData).source.toLowerCase() ?? '';
        this.showSocialContentOverlay(item);
        Metrics.storeTheEvent(
          this.planogram.name,
          'open',
          `${MATOMO_EVENT_NAMES.WEBGL_CLICK_SOCIAL_CONTENT_OVERLAY}-${source}`
        );
        break;
    }
  }

  showImage(item: SphereItem): void {
    this.item = item;
    this._showContent(new ImageView(this.wrapper, item, this.closeOverlay.bind(this)));
    Metrics.storeTheEvent(
      this.planogram.name,
      'click',
      `${MATOMO_EVENT_NAMES.WEBGL_OPEN_SINGLE_IMAGE_OVERLAY}${WebUtils.getItemName(item)}`
    );
  }

  showProductInfo(item, overlayType: string) {
    const countdownFunction = this.showProductReleaseCountdownOverlay.bind(this, item, overlayType);
    const checkoutFunction = this.showCheckoutOverlay.bind(this, item);
    let productOverlay;
    const productOverlayOptions = {
      initialOverlayToDisplay: overlayType,
      showCountdownOverlay: countdownFunction,
      showCheckoutOverlay: checkoutFunction
    };

    switch (true) {
      case AppUtils.isHarryPotterClient(item.planogram, item.planogram.clientName): {
        productOverlay = new ShopifyHarryPotterProductInfo(
          this.wrapper,
          item,
          this.sphereApp,
          productOverlayOptions,
          new ShopifyProductInfoService(this.currencyService),
          this.shoppingCartService,
          this.currencyService,
          this.closeOverlay.bind(this)
        );
        break;
      }

      case AppUtils.isShopifyEcommercePlatform(item.planogram): {
        productOverlay = new ShopifyProductInfo(
          this.wrapper,
          item,
          this.sphereApp,
          productOverlayOptions,
          new ShopifyProductInfoService(this.currencyService),
          this.shoppingCartService,
          this.currencyService,
          this.closeOverlay.bind(this)
        );
        break;
      }

      case AppUtils.isWBClient(item.planogram.clientName): {
        productOverlay = new ProductInfoPins(
          this.wrapper,
          item,
          productOverlayOptions,
          new ProductInfoService(),
          this.closeOverlay.bind(this)
        );
        break;
      }

      case AppUtils.isSelfridgesClient(item.planogram.clientName): {
        productOverlay = new ProductInfoSelfridges(
          this.wrapper,
          item,
          productOverlayOptions,
          new ProductInfoService(),
          this.closeOverlay.bind(this)
        );
        break;
      }

      case AppUtils.isNinjagoClient(item.planogram.clientName): {
        productOverlay = new ProductInfoNinjago(
          this.wrapper,
          item,
          productOverlayOptions,
          new ProductInfoService(),
          this.closeOverlay.bind(this)
        );
        break;
      }

      case AppUtils.isSothebysClient(item.planogram.clientName): {
        productOverlay = new ProductInfoSothebys(
          this.wrapper,
          item,
          productOverlayOptions,
          new ProductInfoService(),
          this.closeOverlay.bind(this)
        );
        break;
      }

      case AppUtils.isLegoClient(item.planogram.clientName): {
        productOverlay = new ProductInfoLego(
          this.wrapper,
          item,
          productOverlayOptions,
          new ProductInfoService(),
          this.closeOverlay.bind(this)
        );
        break;
      }

      case AppUtils.isAjTraceyClient(item.planogram.clientName): {
        productOverlay = new ProductInfoAjTracey(
          this.wrapper,
          item,
          productOverlayOptions,
          new ProductInfoService(),
          this.closeOverlay.bind(this)
        );
        break;
      }

      case AppUtils.isKhaliliClient(item.planogram.clientName): {
        productOverlay = new ProductInfoKhalili(
          this.wrapper,
          item,
          productOverlayOptions,
          new ProductInfoService(),
          this.closeOverlay.bind(this)
        );
        break;
      }

      case AppUtils.isUALClient(item.planogram.clientName): {
        productOverlay = new ProductInfoUal(
          this.wrapper,
          item,
          productOverlayOptions,
          new ProductInfoService(),
          this.closeOverlay.bind(this)
        );
        break;
      }

      default: {
        productOverlay = new ProductInfoDefault(
          this.wrapper,
          item,
          productOverlayOptions,
          new ProductInfoService(),
          this.closeOverlay.bind(this)
        );
      }
    }

    this._showContent(productOverlay);
    setTimeout(() => {
      this.sphereApp.isOverlayActive = true;
    }, 100);
  }

  showAboutUsOverlay() {
    this._showContent(
      new InfoOverlay(
        this.wrapper,
        this.planogram.id,
        this.planogram.versionId,
        CUSTOM_OVERLAYS.ABOUT_US,
        this.closeOverlay.bind(this)
      )
    );
  }

  showVideoOverlay(link: string) {
    this._showContent(new VideoOverlay(this.wrapper, link, this.closeOverlay.bind(this)));
  }

  showContactUsOverlay() {
    this._showContent(
      new InfoOverlay(
        this.wrapper,
        this.planogram.id,
        this.planogram.versionId,
        CUSTOM_OVERLAYS.CONTACT_US,
        this.closeOverlay.bind(this)
      )
    );
  }

  showCookiesOverlay(options) {
    if (options?.cleanItem) {
      this.item = null;
      this.productOverlayType = null;
    }
    const data = {
      ...options,
      ...arguments[1]
    };
    this._showContent(new CookiesTable(this.wrapper, this, data, this.closeOverlay.bind(this)));
  }

  hideCookieOverlay() {
    const item = this.item as ItemData;
    if (item && this.planogram.name === this.planogram.name) {
      this.showItem(this.item, this.productOverlayType);
    } else {
      this.closeOverlay(false);
    }
  }

  showPrivacyOverlay() {
    this._showContent(
      new Privacy(
        this.wrapper,
        this.planogram.id,
        this.planogram.versionId,
        CUSTOM_OVERLAYS.PRIVACY_POLICY,
        this.closeOverlay.bind(this)
      )
    );
  }

  showCookiesPolicyOverlay() {
    this._showContent(
      new InfoOverlay(
        this.wrapper,
        this.planogram.id,
        this.planogram.versionId,
        CUSTOM_OVERLAYS.COOKIES_POLICY,
        this.closeOverlay.bind(this)
      )
    );
  }

  showCopyrightOverlay() {
    this._showContent(
      new InfoOverlay(
        this.wrapper,
        this.planogram.id,
        this.planogram.versionId,
        CUSTOM_OVERLAYS.COPYRIGHT,
        this.closeOverlay.bind(this)
      )
    );
  }

  showIframeOverlay(url: string) {
    this._showContent(new IframeOverlay(this.wrapper, url, this.closeOverlay.bind(this)));
  }

  showProductReleaseCountdownOverlay(
    item: SphereItem,
    productInfoOverlayType = 'details',
    productReleaseData: ProductReleaseCountdownData
  ) {
    const showProductInfoOverlayCallback = this.showProductInfo.bind(this, item, productInfoOverlayType);
    this._showContent(
      new ProductReleaseCountdownOverlay(
        this.wrapper,
        {productReleaseData, showProductInfoOverlayCallback},
        this.closeOverlay.bind(this)
      )
    );
  }

  showCheckoutOverlay(item: SphereItem, productInfoOverlayType = 'gallery', product: Product = null) {
    this._showContent(
      new CheckoutOverlay(
        this.wrapper,
        {product, productInfoOverlayType, item},
        new ProductInfoService(),
        this.closeOverlay.bind(this)
      )
    );
  }

  showSocialContentOverlay(item: SphereItem) {
    const itemData = item.action.data as SocialContentActionData;
    const url = AppUtils.generateSocialContentLink(itemData);
    const {clientSocialMedias} = this.planogram;
    const socialProvider = clientSocialMedias?.find(media => media?.social_media_name === itemData.source);
    const blurCanvas = socialProvider?.container_transparent ?? false;
    const showLoader = socialProvider?.show_loader ?? false;

    if (itemData.source === SOCIAL_MEDIA_TYPE.TWITTER) {
      this._showContent(
        new SocialContentOverlay(this.wrapper, itemData.contentValue, showLoader, this.closeOverlay.bind(this)),
        !blurCanvas
      );
      return;
    }

    this._showContent(
      new IframeOverlay(
        this.wrapper,
        url,
        this.closeOverlay.bind(this),
        itemData.source,
        itemData.contentType,
        showLoader
      ),
      !blurCanvas
    );
  }

  _showContent(content, blurCanvas = true) {
    this.content = content;

    if (blurCanvas) {
      this.sphereApp.blurGlCanvas();
    }

    updateUIElementsVisibility(false);
    this.wrapper.classList.remove('is-hidden');
    sphereEventHandler.emit(EVENTS.OVERLAY.SHOW_CONTENT, {content});
  }

  _handleStartEvent(event: Event) {
    if (!(event.target as Element).closest('.with-propagation')) {
      event.stopPropagation();
    }
    this.startEvent = event;
    if (event.type === 'touchstart') {
      const e = event as TouchEvent;
      this.startY = e.touches.length && e.touches[0].pageY;
    }
  }

  _handleWheelEvent(event) {
    event.stopPropagation();
  }

  _handleMoveEvent(event) {
    const selectors = [
      '.text-wrapper',
      '.text-content',
      '.app-product-description-text',
      '.app-product-fields',
      '.app-product-description-content',
      '.cookies-wrapper',
      '.app-product-scrollable-container',
      '.allow-scroll'
    ];
    const element = (event.target as Element).closest(selectors.join(', '));
    event.stopPropagation();
    if (!element) {
      event.preventDefault();
    }
    if (event.type === 'touchmove') {
      const endY = event.touches.length && event.touches[0].pageY;
      if (element && WebUtils.isScrolledToTheEnd(element as HTMLElement, this.startY, endY) && event.cancelable) {
        event.preventDefault();
      }
    }
  }

  _handleEndEvent(event) {
    if (!this.isShowing()) {
      return;
    }
    const isClick = this.startEvent && InputEventUtils.isClick(this.startEvent, event);
    if (isClick && (event.target === this.wrapper || this.content.handleClick(event))) {
      event.preventDefault();
      this.closeOverlay();
    }
    this.startEvent = undefined;
  }

  closeOverlay(withNavigation = true): void {
    // TODO Add removing of listeners after close to every overlay
    this.hide(withNavigation);
    this.productOverlayType = null;
    this.item = null;
    sphereEventHandler.emit(EVENTS.OVERLAY.CLOSE_CONTENT);
  }

  handleLeftKey() {
    if (this.content && this.content.handleLeftKey) {
      this.content.handleLeftKey();
    }
  }

  handleRightKey() {
    if (this.content && this.content.handleRightKey) {
      this.content.handleRightKey();
    }
  }

  showVideoShare(video: VideoComponent) {
    const url = Router.generateShareableLink(
      Router.generateItemUrl(video.id as string, SPHERE_ITEM_TYPES.VIDEO, video.planogram.name, true)
    );
    this._showContent(new ShareOverlay(this.wrapper, this.planogram, url, this.closeOverlay.bind(this)));
  }
}
