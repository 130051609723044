import {isMobile} from '../../utils/platform';
import {InputEventUtils} from '../../utils/input_event_utils';
import {ProductInfoBase} from './product-info-base_overlay';
import {productInfoNinjagoView} from './product-info-ninjago.view';

const sanitizeHtml = require('sanitize-html');

export class ProductInfoNinjago extends ProductInfoBase {
  private productTitle: string;

  protected async init(): Promise<void> {
    await super.init();
    this.handleOutOfStockProduct();
  }

  protected initOverlay() {
    super.initOverlay();
    this.initInfoTextControls();
  }

  handleClick(e) {
    if (this.isViewGallery()) {
      const tokens = ['app-product-overlay', 'app-product-content-wrapper', 'app-product-carousel'];
      if (e.target.closest('#app-image-carousel')) {
        tokens.push('splide__slide');
      }
      return tokens.some(token => e.target.classList.contains(token));
    } else {
      return e.target === this.mainContainer;
    }
  }

  protected setItemContent() {
    const description = this.product.description ? sanitizeHtml(this.product.description) : '';
    const sanitizedProduct = {...this.product, description};
    this.productTitle = this.product.name;

    this.container.innerHTML = productInfoNinjagoView(sanitizedProduct, this.item.planogram.name);
  }

  private initInfoTextControls() {
    const infoButton = document.querySelector('.info-icon');
    const productOverlay = document.querySelector('.app-product-overlay');
    const toggleClassName = 'is-info-text-active';
    const parentContainer = document.querySelector('.app-product-price');

    const toggleInfoText = (e: Event): void => {
      const target = e.target as HTMLElement;
      e.stopPropagation();
      if (!parentContainer) {
        console.error('element not found - ".app-product-price"');
      }

      if (target.classList.contains('info-icon')) {
        parentContainer.classList.toggle(toggleClassName);
      } else {
        parentContainer.classList.remove(toggleClassName);
      }
    };

    if (isMobile) {
      InputEventUtils.addSelectEvents(infoButton, toggleInfoText);
      InputEventUtils.addSelectEvents(productOverlay, toggleInfoText);
    } else {
      InputEventUtils.addHoverEvent(infoButton, toggleInfoText);
    }
  }

  private handleOutOfStockProduct() {
    if (this.product.price_in_user_currency_str) {
      return;
    }
    // Hide custom button if price is empty
    const customButton = document.getElementById('custom-button');
    const galleryCheckoutButtonContainer = document.getElementById('checkout-gallery-button');
    const galleryInfoButtonContainer = document.getElementById('info-gallery-button');
    const checkoutButton = document.getElementById('checkout-button');

    this.mainContainer.classList.remove('checkout-flow');
    this.mainContainer.classList.add('no-buttons');
    customButton?.classList.add('is-hidden');
    galleryInfoButtonContainer?.classList.add('no-checkout');
    galleryCheckoutButtonContainer?.classList.add('is-hidden');
    checkoutButton?.classList.add('is-hidden');
  }
}
