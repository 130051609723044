import {ApiService} from '../api';
import {URLS, UrlUtils} from '../urls';
import {AvailableLanguage} from '../../interfaces/l10n.interface';
import {L10nUtils} from '../../utils/l10n_utils';

const storedAvailableLanguages: Map<string, AvailableLanguage[]> = new Map<string, AvailableLanguage[]>();
export class L10nService extends ApiService {
  getAvailableLanguages(): Promise<AvailableLanguage[]> {
    const availableLanguages = L10nService.getAvailableLanguages();
    const planogramId = L10nUtils.getPlanogramId();
    const url = UrlUtils.insertValueToUrl(URLS.L10N_LANGUAGES, planogramId);

    if (availableLanguages) {
      return Promise.resolve(availableLanguages);
    }
    return this.get(url)
      .then(resp => resp.json())
      .then(json => {
        L10nService.setAvailableLanguages(json);
        return json;
      });
  }

  getL10nDictionary(languageCode: string): Promise<any> {
    const url = UrlUtils.insertValueToUrl(URLS.L10N_DICTIONARY, languageCode);
    return this.getWithoutToken(url).then(resp => resp.json());
  }

  clearStoredAvailableLanguages() {
    storedAvailableLanguages.clear();
  }

  private static getAvailableLanguages() {
    return storedAvailableLanguages.get('languages');
  }

  private static setAvailableLanguages(languages) {
    storedAvailableLanguages.set('languages', languages);
  }
}
