import {Product} from '../../interfaces/product.interface';
import {URLS} from '../../api/urls';
import {L10nUtils} from '../../utils/l10n_utils';

export const ProductInfoAjTraceyView = (product: Product, planogramName: string) => {
  const checkMarkSrc = URLS.OVERLAY_CHECK_MARK;
  const shareLinkSrc = URLS.OVERLAY_SHARE_LINK_ICON;
  const shareDeviceSrc = URLS.OVERLAY_SHARE_DEVICE_ICON;

  const twitterShareLinkSrc = URLS.OVERLAY_TWITTER_ICON;
  const whatsappShareLinkSrc = URLS.OVERLAY_WHATSAPP_ICON;
  const telegramShareLinkSrc = URLS.OVERLAY_TELEGRAM_ICON;
  const viberShareLinkSrc = URLS.OVERLAY_VIBER_ICON;
  const brandIcon = URLS.OVERLAY_AJTRACEY_BRAN_ICON;

  return `
    <div id="product-details-container" class="app-ajtracey-template is-view-gallery">
        <div class="app-product-overlay ${planogramName}-overlay">
            <div class="app-gallery-gradient"></div>
            <div class="app-product-overlay-controls">
              <div class="overlay-button overlay-button-back overlay-back" role="button" tabindex="0">
                <span></span>
              </div>
              <div class="overlay-button overlay-button-close" role="button" tabindex="0">
                <span></span>
              </div>
            </div>
            <div class="app-product-content-wrapper">
              <div class="app-product-details-header">
                <div class="app-product-code">
                  <div id="share-mobile" class="is-hidden">
                    <span class="share-mobile-text">${L10nUtils.l10n('product-details-overlay.share-button.text')}</span>
                    <img src="${shareDeviceSrc}">
                  </div>
                </div>
                <div class="app-product-title">${product.name}</div>
                <div class="app-product-description-text-wrapper">
                  <div class="app-product-description-text allow-scroll">${product.description}</div>
                  <div class="text-fade"></div>
                </div>
                <div class="app-share-links is-hidden">
                  <img class="share-button-whatsapp share-button is-hidden" src="${whatsappShareLinkSrc}" role="button" tabindex="0">
                  <img class="share-button-telegram share-button is-hidden" src="${telegramShareLinkSrc}" role="button" tabindex="0">
                  <img class="share-button-viber share-button is-hidden" src="${viberShareLinkSrc}" role="button" tabindex="0">
                  <img class="share-button-twitter share-button is-hidden" src="${twitterShareLinkSrc}" role="button" tabindex="0">
                  <div class="share-link-holder">
                    <img class="share-button-copy_link share-button is-hidden" src="${shareLinkSrc}" role="button" tabindex="0">
                    <div class="share-success-message">
                      <img class="check-mark" src="${checkMarkSrc}">
                      <span>${L10nUtils.l10n('product-details-overlay.share.notification')}</span>
                    </div>
                  </div>
                </div>
                <div class="app-product-separator"></div>
              </div>
              <div class="app-product-carousel">
                <div id="app-image-carousel" class="splide">
                  <div class="splide__arrows">
                    <button class="splide__arrow splide__arrow--prev">
                      <span class="overlay-arrow overlay-arrow-prev"></span>
                    </button>
                    <button class="splide__arrow splide__arrow--next">
                      <span class="overlay-arrow overlay-arrow-next"></span>
                    </button>
                  </div>
                  <div class="splide__track">
                    <ul class="splide__list">
                    </ul>
                  </div>
                </div>
                <div class="app-thumbnail-carousel-container">
                  <div class="app-product-gallery-buttons-container">
                    <div id="info-gallery-button" class="no-checkout" role="button" tabindex="0">
                      <div>
                        <img alt="Info icon">
                      </div>
                    </div>
                    <div id="checkout-gallery-button" class="is-completely-hidden" role="button" tabindex="0">
                      <div>
                        <img alt="Checkout icon">
                      </div>
                    </div>
                  </div>
                  <div id="app-thumbnail-carousel" class="splide">
                    <div id="app-thumbnail-splide-arrows" class="splide__arrows">
                      <button class="splide__arrow splide__arrow--prev">
                        <span class="overlay-arrow overlay-arrow-prev"></span>
                      </button>
                      <button class="splide__arrow splide__arrow--next">
                        <span class="overlay-arrow overlay-arrow-next"></span>
                      </button>
                    </div>
                    <div class="splide__track">
                      <ul class="splide__list">
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="app-product-details-container">
                <div class="app-product-price is-hidden">
                  <span class="product-price-title">Price</span>
                  <span class="product-price-value"></span>
                </div>
                <div class="app-buy-buttons-wrapper"></div>
              </div>
              <img src="${brandIcon}" class="app-product-brand-icon" alt="">
            </div>
            <div id="share-mobile-popup" class="is-hidden">
              <div class="app-popup ${planogramName}-popup">
                <div class="share-button-imgs-wrapper">
                  <img class="share-button-whatsapp share-button is-hidden" src="${whatsappShareLinkSrc}" role="button" tabindex="0">
                  <img class="share-button-telegram share-button is-hidden" src="${telegramShareLinkSrc}" role="button" tabindex="0">
                  <img class="share-button-viber share-button is-hidden" src="${viberShareLinkSrc}" role="button" tabindex="0">
                  <img class="share-button-twitter share-button is-hidden" src="${twitterShareLinkSrc}" role="button" tabindex="0">
                  <img class="share-button-copy_link share-button is-hidden" src="${shareLinkSrc}" role="button" tabindex="0">
                </div>
              </div>
              <div class="app-popup-backdrop"></div>
            </div>
          </div>
        </div>
    </div>
  `;
};
