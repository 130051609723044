import {ShopifyProductInfo} from '../shopify-product_info';
import {SphereItem} from '../../../sphere_item';
import {SphereApp} from '../../../sphere_app';
import {ShopifyProductInfoService} from '../../../api/services/shopify-product_info.service';
import {ShoppingCartService} from '../../../api/services/shopping-cart.service';
import {CurrencyService} from '../../../api/services/currency.service';
import {VariantsComponent} from '../components/variants/variants_component';
import {QuantityPicker} from '../components/quantity-picker/quantity-picker';
import {ShopifyButtons} from '../components/buttons/shopify-buttons_component';
import {ProductFields} from '../components/product-fields/product-fields_component';
import {ShoppingCartWidgetComponent} from '../../../shopping-cart-widget/shopping-cart-widget.component';
import {shopifyHarryPotterOverlayView} from './shopify-harry-potter-product_info.view';
import {CurrencyUtils} from '../../../utils/currency_utils';
import {InputFieldsComponent} from './input-fields/input-fields-component';
import {OutOfStockComponent} from './components/out-of-stock/out-of-stock-component';

export class ShopifyHarryPotterProductInfo extends ShopifyProductInfo {
  private outOfStock: OutOfStockComponent;

  constructor(
    container: HTMLElement,
    item: SphereItem,
    protected sphereApp: SphereApp,
    options: any,
    productInfoService: ShopifyProductInfoService,
    protected shoppingCartService: ShoppingCartService,
    protected currencyService: CurrencyService,
    closeCallback: Function
  ) {
    super(container, item, sphereApp, options, productInfoService, shoppingCartService, currencyService, closeCallback);
  }

  get isProductAvailable() {
    return this.variant?.inventory_quantity > 0;
  }

  protected async init(): Promise<void> {
    await super.init();

    if (this.product.input_fields.length && this.overlaySettings.enable_buttons) {
      this.inputFields = new InputFieldsComponent(this.container, this.product, this.shopifyService);
    }
  }

  protected setItemContent() {
    this.container.innerHTML = shopifyHarryPotterOverlayView(this.product, this.overlaySettings.enable_buttons);
    this.mainContainer = document.getElementById('shopify-product-details-container');
    if (this.overlaySettings.enable_buttons) {
      this.variantComponents = this.product.options.map(option => {
        return new VariantsComponent(option, this.product, this.mainContainer, this.shopifyService);
      });
      this.quantityPicker = new QuantityPicker(
        this.product,
        this.mainContainer,
        this.shopifyService,
        this.shoppingCartService
      );
      this.outOfStock = new OutOfStockComponent(
        this.sphereApp,
        this.mainContainer,
        this.product,
        this.shopifyService,
        this.shoppingCartService
      );
      this.buttons = new ShopifyButtons(
        this,
        this.product,
        this.overlaySettings,
        this.mainContainer,
        this.quantityPicker,
        this.shopifyService,
        this.shoppingCartService
      );
      this.shoppingCartWidget = new ShoppingCartWidgetComponent(
        this.sphereApp,
        this.shoppingCartService,
        this.currencyService,
        this.quantityPicker,
        'app-shopify-cart-container',
        this.product
      );
    }
    this.productFields = new ProductFields(this.product, this.mainContainer, this.overlaySettings);
    this.setOverlayFonts();
    this.syncEcommerceOverlayColor();
  }

  protected updateProductPrice(selectedVariant) {
    if (!this.mainContainer) {
      return;
    }
    const priceWrapper = this.mainContainer.querySelector('.app-shopify-product-price');
    const priceContainer = priceWrapper.querySelector('.app-shopify-product-price-value');
    const discountPriceContainer = priceWrapper.querySelector('.app-shopify-product-discount-price-value');
    priceWrapper.classList.remove('has-discount-price', 'is-unavailable', 'is-unavailable-without-margin');

    if (!selectedVariant && !selectedVariant.isAutoSelected) {
      priceWrapper.classList.add('is-unavailable-without-margin');
      (priceContainer as HTMLElement).innerText = '';
      (discountPriceContainer as HTMLElement).innerText = '';
      return;
    } else if (selectedVariant.inventory_quantity <= 0 && !selectedVariant.isAutoSelected) {
      priceWrapper?.classList.add('is-unavailable');
    }

    const {selectedCurrencyCode, currenciesList} = this.currencyService;
    const getPriceValue = price =>
      CurrencyUtils.getPriceForHarryPotterWithSymbolOrCode(price, selectedCurrencyCode, currenciesList);

    if (selectedVariant?.compare_at_price && parseFloat(selectedVariant?.compare_at_price)) {
      // due to weird behaviour of shopify api discount price logic
      (priceContainer as HTMLElement).innerHTML = getPriceValue(selectedVariant.compare_at_price);
      (discountPriceContainer as HTMLElement).innerHTML = getPriceValue(selectedVariant.price);
      priceWrapper.classList.add('has-discount-price');
    } else {
      (priceContainer as HTMLElement).innerHTML = getPriceValue(selectedVariant.price);
      (discountPriceContainer as HTMLElement).innerHTML = '';
    }
  }

  dispose() {
    super.dispose();
    this.outOfStock?.dispose();
  }
}
