import {InputEventUtils} from '../utils/input_event_utils';
import {VideoUtils} from '../utils/video_utils';

export class VideoOverlay {
  container: HTMLElement;

  constructor(container: HTMLElement, private link: string, private closeCallback: Function) {
    const platform = VideoUtils.findVideoPlatform(link);
    const videoURL = VideoUtils.generateURL(link, platform);

    if (!videoURL) {
      console.error('Generating process of youtube url was failed');
      setTimeout(() => {
        this.closeOverlay();
      }, 0);
      return;
    }

    this.container = container;
    this.container.innerHTML = `
      <div class="close-backdrop"></div>
      <div class="video-overlay overlay-container is-flex is-vertical is-vertically-aligned-center is-horizontally-aligned-center">
        <div class="iframe-holder">
          <iframe width="100%" src="${videoURL}" frameborder="0" allowfullscreen></iframe>
        </div>
        <div class="overlay-button overlay-button-close will-close" role="button" tabindex="0">
          <span></span>
        </div>
      </div>
    `;

    const closeButton = document.querySelector('.video-overlay .overlay-button-close');

    if (closeButton) {
      InputEventUtils.addSelectEvents(closeButton, this.closeOverlay.bind(this));
    } else {
      console.error('element not found - ".overlay-button-close"');
    }
  }

  private closeOverlay(e?: Event): void {
    if (e) {
      e.stopPropagation();
    }
    if (this.closeCallback) {
      this.closeCallback();
    } else {
      console.error('"Close" function is not set up');
    }
  }

  handleClick(e) {
    return ['close-backdrop', 'video-overlay'].some(token => e.target.classList.contains(token));
  }
}
