import {loader} from '../components/loader';

export const shoppingCartButtonView = () => {
  return `
    <div class="app-shopping-cart-button-wrapper fixed-control-button">
        <span id="badge-counter"></span>
        ${loader({})}
    </div>
  `;
};
