import {L10nUtils} from '../../../../utils/l10n_utils';

export const QuantityPickerView = (limit: number | string) => {
  return `
    <div class="shopify-product-details-item-quantity-controls">
      <div class="shopify-product-details-quantity-label">${L10nUtils.l10n('shopify-product-overlay.quantity-picker.title')}</div>
      <div class="shopify-product-details-item-quantity-wrapper input-field-border">
        <div class="left-border">
          <div class="square"></div>
        </div>
        <div class="right-border">
          <div class="square"></div>
        </div>
        <div class="shopify-product-details-item-quantity-button app-shopping-cart-item-decrease disabled">
          <span></span>
        </div>
        <div class="shopify-product-details-item-quantity-value">1</div>
        <div class="shopify-product-details-item-quantity-button app-shopping-cart-item-increase">
          <span></span>
        </div>
      </div>
      ${limit ? `<div class="shopify-product-details-item-quantity-limit">Limit: ${limit}</div>` : ''}
    </div>
  `;
}
