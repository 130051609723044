import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import {SearchSettings} from '../interfaces/search-setting.interface';
import {SearchService} from '../api/services/search.service';
import {InputEventUtils} from '../utils/input_event_utils';
import {SearchResultsData} from '../interfaces/search-resutls.interface';
import {searchEventHandler} from '../custom_event_utils';
import {SEARCH_EVENT_NAMES as SEARCH_EVENTS} from '../event-names';
import {Metrics} from '../metrics';
import {MATOMO_EVENT_NAMES} from '../metric-events';

export class SearchFilters {
  private readonly searchFiltersContainer: HTMLElement;
  private storedClickedFilterElement: HTMLElement | null;
  private searchSettings: SearchSettings;
  private isRequestPending: boolean;

  constructor(private searchService: SearchService) {
    this.searchFiltersContainer = document.getElementById('search-filters-container');
    this.storedClickedFilterElement = null;
    this.isRequestPending = false;

    if (!this.searchFiltersContainer) {
      console.error('Element with id - "#search-filters-container" not found');
    }

    pipe(
      this.searchService.searchFilterSettingsSubject,
      subscribe({
        next: (results: SearchResultsData) => {
          searchEventHandler.emit(SEARCH_EVENTS.UPDATE_SEARCH, {results});
          this.renderFilters(results);
        }
      })
    );

    searchEventHandler.listen(SEARCH_EVENTS.REQUEST_STATUS, (data) => {
      this.isRequestPending = data.state;
    });

    InputEventUtils.addSelectEvents(
      this.searchFiltersContainer,
      this.handleClicks.bind(this)
    );
  }

  updateSearchSettings(settings: SearchSettings) {
    this.searchSettings = settings;
  }

  private renderFilters(searchResults) {
    const filterButtons = searchResults?.aggregations.planogram_version_ids;

    if (!this.searchFiltersContainer) {
      console.error('Element with id - "search-filters-container" not found');
      return;
    }

    this.clearFilters();
    const buttons = document.createDocumentFragment();
    this.searchFiltersContainer.classList.remove('is-filtering-active');
    filterButtons?.forEach(button => {
      const buttonWrapper = document.createElement('div');
      const badgeCount = document.createElement('span');
      const buttonInfo = this.searchSettings.other_planogram_versions.find(planogram => planogram.id === button.id);
      const buttonIcon = document.createElement('img');
      buttonWrapper.classList.add('search-filter-button');
      badgeCount.classList.add('search-filter-badge-counter');
      badgeCount.innerText = button.count > 99 ? '99+' : button.count;
      buttonIcon.setAttribute('src', buttonInfo?.search_setting.control_button?.url);
      buttonWrapper.setAttribute('data-planogram_id-attr', button.id);
      buttonWrapper.setAttribute('data-planogram_name', buttonInfo?.planogram_name);
      buttonWrapper.setAttribute('aria-label', `${badgeCount.innerText} elements in ${buttonInfo?.planogram_name}` );
      buttonWrapper.setAttribute('role', `button` );
      badgeCount.setAttribute('aria-hidden', 'true');
      buttonWrapper.appendChild(badgeCount);
      buttonWrapper.appendChild(buttonIcon);
      buttons.appendChild(buttonWrapper);
    });
    this.searchFiltersContainer.appendChild(buttons);
  }

  clearFilters() {
    this.searchFiltersContainer.innerHTML = '';
  }

  private handleClicks(e) {
    const clickedFilter = e.target.closest('.search-filter-button');

    if (!clickedFilter || this.isRequestPending) {
      return;
    }

    const filterId = clickedFilter.getAttribute('data-planogram_id-attr');
    const filterPlanogramName = clickedFilter.getAttribute('data-planogram_name');
    const searchQuery = (document.getElementById('search-input') as HTMLInputElement).value;

    Metrics.storeTheEvent(
      this.searchService.planogramName,
      'click',
      MATOMO_EVENT_NAMES.CLICK_SEARCH_RESULT_ICON(filterPlanogramName)
    );

    if (this.storedClickedFilterElement !== clickedFilter) {
      this.searchFiltersContainer.classList.add('is-filtering-active');
      this.storedClickedFilterElement?.classList.remove('active-filter');
      clickedFilter.classList.add('active-filter');
      this.storedClickedFilterElement = clickedFilter;
      this.searchService.filterResults(searchQuery, filterId);
    } else {
      this.searchFiltersContainer.classList.remove('is-filtering-active');
      clickedFilter.classList.remove('active-filter');
      this.storedClickedFilterElement = null;
      this.searchService.filterResults(searchQuery);
    }
  }
}
