import makeBehaviorSubject from 'callbag-behavior-subject';
import {ApiService} from '../api';
import {ShopifyProduct} from '../../interfaces/shopify-product.interface';

export class ShopifyService extends ApiService {
  productVariantsSubject: makeBehaviorSubject<object>;
  selectedProductSubject: makeBehaviorSubject<object>;
  selectedVariantValueSubject: makeBehaviorSubject<string>;
  private product: ShopifyProduct = null;
  private readonly selectedProductVariant: any;

  constructor() {
    super();
    this.productVariantsSubject = makeBehaviorSubject(this.selectedProductVariant);
    this.selectedProductSubject = makeBehaviorSubject(null);
    this.selectedVariantValueSubject = makeBehaviorSubject('');
    this.selectedProductVariant = {option1: null, option2: null, option3: null};
  }

  updateProductVariant(variantName, variantValue) {
    this.selectedProductVariant[variantName] = variantValue;
    this.selectedVariantValueSubject(1, variantValue);
    this.productVariantsSubject(1, this.selectedProductVariant);
    this.getSelectedVariant();
  }

  updateProductData(product) {
    this.product = product;
    this.getSelectedVariant();
  }

  private getSelectedVariant() {
    if (!this.product) {
      return;
    }
    const selectedVariants = [
      this.selectedProductVariant.option1,
      this.selectedProductVariant.option2,
      this.selectedProductVariant.option3
    ].join(',');
    const imagesIds = this.product.product_images.map(image => image.shopify_id).join(',');
    let selectedVariant = this.product.variants.filter(variant => {
      const productVariants = [variant.option1, variant.option2, variant.option3].join(',');
      return productVariants === selectedVariants;
    });

    if (!selectedVariant.length && selectedVariants[0]) {
      selectedVariant = this.product.variants
        .filter(variant => variant.option1 === selectedVariants.split(',')[0])
        .filter(variant => imagesIds.includes(`${variant.image_id}`));
    }

    if (!selectedVariant.length) {
      selectedVariant = [{...this.product.variants[0], isAutoSelected: true}];
    }

    this.selectedProductSubject(1, selectedVariant[0]);
  }
}
