import {InputEventUtils} from '../utils/input_event_utils';
import {SOCIAL_MEDIA_TYPE} from '../interfaces/planogram.interface';
import {UrlUtils} from '../api/urls';
import {OverlayScrollbar} from '../components/overlay_scrollbar';
import {AppUtils} from '../utils/app_utils';

export class IframeOverlay {
  container: HTMLElement;
  private overlayScrollbar: OverlayScrollbar;

  constructor(container: HTMLElement,
              private url: string,
              private closeCallback: Function,
              private source?: SOCIAL_MEDIA_TYPE,
              private contentType?: string,
              private showLoader?: boolean) {
    this.container = container;
    const additionalClasses = [];
    if (this.source) {
      additionalClasses.push('social-media-overlay');
      additionalClasses.push(UrlUtils.slugify(this.source));
    }
    if (this.contentType) {
      additionalClasses.push(UrlUtils.slugify(this.contentType));
    }
    this.container.innerHTML = `
      <div class="iframe-overlay overlay-container is-flex is-vertical is-vertically-aligned-center is-horizontally-aligned-center ${additionalClasses.join(' ')}">
        <div class="overlay-button overlay-button-close will-close">
          <span></span>
        </div>
        <div class="iframe-holder">
          <iframe width="100%" height="100%" src="${this.url}" frameborder="0"></iframe>
        </div>
      </div>
    `;

    const searchParams = new URL(url).searchParams;
    const width = parseFloat(searchParams.get('width'));
    const height = parseFloat(searchParams.get('height'));
    const closeButton = document.querySelector('.iframe-overlay .overlay-button-close');
    const root = document.documentElement;
    const iframeOverlay = this.container.querySelector('.iframe-overlay');
    const iframe = this.container.querySelector('iframe');
    this.overlayScrollbar = new OverlayScrollbar('.iframe-holder', {sizeAutoCapable: false});
    if (this.showLoader) {
      iframeOverlay.appendChild(AppUtils.showLoader());
    }
    iframe.onload = () => AppUtils.removeLoader();

    if (closeButton) {
      InputEventUtils.addSelectEvents(closeButton, this.closeOverlay.bind(this));
    } else {
      console.error('element not found - ".overlay-button-close"');
    }

    if (width && height) {
      const ratio = width / height;
      const ratioClass = ratio > 1 ? 'horizontal' : 'vertical';

      root.style.setProperty('--social-media-overlay-width', `${width}`);
      root.style.setProperty('--social-media-overlay-height', `${height}`);
      root.style.setProperty('--social-media-overlay-max-width', `${width}px`);
      this.container.classList.remove('horizontal', 'vertical');
      this.container.classList.add(ratioClass);
    } else {
      root.style.removeProperty('--social-media-overlay-width');
      root.style.removeProperty('--social-media-overlay-height');
      root.style.removeProperty('--social-media-overlay-max-width');
    }
  }

  private closeOverlay(e?: Event): void {
    if (e) {
      e.stopPropagation();
    }
    if (this.closeCallback) {
      this.closeCallback();
    } else {
      console.error('"Close" function is not set up');
    }
  }

  handleClick(e) {
    return e.target.classList.contains('iframe-overlay');
  }
}
