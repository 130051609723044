import {Product} from '../../interfaces/product.interface';
import {L10nUtils} from '../../utils/l10n_utils';

export const productInfoSelfridgesView = (product: Product, planogramName: string) => {

  return `
    <div id="product-details-container" class="app-selfridges-template is-view-gallery">
        <div class="app-product-overlay ${planogramName}-overlay">
            <div class="app-gallery-gradient"></div>
            <div class="app-product-overlay-controls">
              <div class="overlay-button overlay-button-back overlay-back" role="button" tabindex="0">
                <span></span>
              </div>
              <div class="overlay-button overlay-button-close" role="button" tabindex="0">
                <span></span>
              </div>
            </div>
            <div class="app-product-content-wrapper">
              <div class="app-product-details-header">
                <div class="app-product-brand-name">${product.brand}</div>
                <div class="app-product-title ${product.brand ? '' : 'no-brand'}">${product.name}</div>
              </div>
              <div class="app-product-carousel">
                <div id="app-image-carousel" class="splide">
                  <div class="splide__arrows">
                    <button class="splide__arrow splide__arrow--prev">
                      <span class="overlay-arrow overlay-arrow-prev"></span>
                    </button>
                    <button class="splide__arrow splide__arrow--next">
                      <span class="overlay-arrow overlay-arrow-next"></span>
                    </button>
                  </div>
                  <div class="splide__track">
                    <ul class="splide__list">
                    </ul>
                  </div>
                </div>
                <div class="app-thumbnail-carousel-container">
                   <div class="app-product-gallery-buttons-container">
                    <div id="info-gallery-button" role="button" tabindex="0">
                      <div>
                        <img alt="${L10nUtils.l10n('product-details-overlay.selfridges.info-gallery-button.alt-text')}">
                      </div>
                    </div>
                    <div id="checkout-gallery-button" role="button" tabindex="0" class="is-completely-hidden">
                      <div>
                        <img alt="${L10nUtils.l10n('product-details-overlay.selfridges.checkout-gallery-button.alt-text')}">
                      </div>
                    </div>
                  </div>
                  <div id="app-thumbnail-carousel" class="splide">
                    <div id="app-thumbnail-splide-arrows" class="splide__arrows">
                      <button class="splide__arrow splide__arrow--prev">
                        <span class="overlay-arrow overlay-arrow-prev"></span>
                      </button>
                      <button class="splide__arrow splide__arrow--next">
                        <span class="overlay-arrow overlay-arrow-next"></span>
                      </button>
                    </div>
                    <div class="splide__track">
                      <ul class="splide__list">
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="app-product-details-container">
                <div class="app-product-price is-hidden ${product.discounted_price_in_user_currency_str ? 'with-discount-price' : ''}">
                  <span class="product-discount-price-value">${product.discounted_price_in_user_currency_str}</span>
                  <span class="product-price-value"></span>
                </div>
                <div class="app-product-description-container ${!product.description ? 'is-hidden' : ''}"">
                  <div class="app-product-description-content allow-scroll">${product.description}</div>
                  <div class="text-fade"></div>
                </div>
                <div class="app-buy-buttons-wrapper"></div>
              </div>
            </div>
          </div>
        </div>
    </div>
  `;
};
