import {ItemData, LODSMetaData, VirtualTextureData} from '../interfaces/planogram.interface';
import {
  Box2,
  ClampToEdgeWrapping,
  DataTexture,
  LinearFilter,
  RGBAFormat,
  RGBFormat,
  RepeatWrapping,
  Texture,
  TextureLoader,
  UVMapping,
  UnsignedByteType,
  Vector2,
  Vector3,
  WebGLRenderer
} from 'three';
import {Page, PhysicalPage} from '../interfaces/vt_pipeline.interface';
import {ArrayUtils} from '../utils/array_utils';
import {ItemVirtualPagesManager, VirtualSlot} from './item_virtual_page';
import {sphereEventHandler} from '../custom_event_utils';
import {SPHERE_EVENT_NAMES} from '../event-names';
import loadingProgress, {LOADING_STAGES} from '../api/services/loading_progress.service';
import {Modulo} from '../utils/moduloUtils';
import {Planogram} from '../planogram';

// TODO: Increase this to textureSize after resolving memory leaks
const VIRTUAL_TEXTURE_SIZE = 512;

// Pack texture coordinates, texture size, and texture number into 4 * Bytes.
// Coordinates are in the range of 0-4095 but due to space limitations, this is divided by 64
// (smallest possible texture size). X is stored in the red channel and y in the blue channel.
// Alpha channel is used for storing virtual page size, and physical page size and texture number are both stored
// in the blue channel, since we have 5 different values and only 4 channels available. The First 4 bits are
// used for texture number and other 4 for physical page size. Physical page size is provided as a power of two
// so that we can store in 4 bits.
function formatVirtualTextureBytes(
  x: number,
  y: number,
  virtualPageSize: number,
  physicalTextureSize: number,
  textureNumber: number
) {
  const physicalTextureBase = Math.round(Math.log2(physicalTextureSize));

  if (textureNumber > 15 || physicalTextureBase > 15) {
    console.error(
      `Texture number and physical texture base each have only 4 bits of memory available and thus cannot be larger then 15`
    );
  }

  const r = Math.round(x / 64);
  const g = Math.round(y / 64);
  const b1 = textureNumber % 16 << 4;
  const b2 = physicalTextureBase % 16;
  const a = virtualPageSize;

  const output = [r, g, b1 + b2, a];

  return output;
}

export class DynamicElementsPageCache {
  private loader: TextureLoader;
  private readonly physicalTextures: Array<DataTexture>;
  private readonly virtualTableTexture: DataTexture;
  private readonly physicalPages: Array<PhysicalPage>;
  private emptyPhysicalPage: PhysicalPage;
  private disposed = false;
  private itemVirtualPagesManager: ItemVirtualPagesManager;
  private pageSize: number;
  private viewport?: Box2;
  private modulo: Modulo = new Modulo(Planogram.pages());

  constructor(
    private vtParams: VirtualTextureData,
    private renderer: WebGLRenderer,
    private readonly textureSize: number,
    numberOfTextures: number,
    private readonly itemsData: ItemData<LODSMetaData>[]
  ) {
    this.pageSize = this.vtParams.pageSize;

    this.itemVirtualPagesManager = new ItemVirtualPagesManager(VIRTUAL_TEXTURE_SIZE);

    this.loader = new TextureLoader();
    this.physicalPages = this.setupPhysicalPages(textureSize, this.pageSize, numberOfTextures);
    this.physicalTextures = this.setupPhysicalTextures(textureSize, numberOfTextures);

    this.virtualTableTexture = this.setupVirtualTableTexture(VIRTUAL_TEXTURE_SIZE);
    this.populateVirtualTextures(this.itemsData);
  }

  setViewport(viewport: Box2) {
    this.viewport = viewport;
  }

  async initialize(currentLod: number, permanentLod: number) {
    await this.requestAllPagesForLOD(permanentLod, true);
    setTimeout(() => sphereEventHandler.emit(SPHERE_EVENT_NAMES.PERMANENT_TILES_LOAD));
    await this.requestAllPagesForLOD(currentLod, false);
  }

  async loadItem(page: Page, permanent: boolean) {
    if (this.disposed) {
      return false;
    }

    const {id, lod, x, y} = page;

    const virtualSlot = this.itemVirtualPagesManager.getVirtualSlot(id, x, y, lod);
    virtualSlot.permanent = permanent ?? virtualSlot.permanent;
    await this.loadVirtualSlot(virtualSlot);
  }

  getItemUniforms(item: ItemData<LODSMetaData>) {
    return this.makeUniforms(item);
  }

  dispose() {
    this.disposed = true;
    this.renderer = undefined;
    this.physicalTextures.forEach(t => {
      t.dispose();
    });
    this.virtualTableTexture.dispose();
  }

  private setupVirtualTableTexture(textureSize: number) {
    const data = new Uint8Array(textureSize * textureSize * 4);
    const emptyTexture = this.emptyPhysicalPage.physicalCoordinates.texture;
    const formattedData = formatVirtualTextureBytes(0, 0, 1, this.pageSize, emptyTexture);
    for (let i = 0; i < textureSize * textureSize; i += 4) {
      data[i] = formattedData[0];
      data[i + 1] = formattedData[1];
      data[i + 2] = formattedData[2];
      data[i + 3] = formattedData[3];
    }

    const virtualTexture = new DataTexture(
      data,
      textureSize,
      textureSize,
      RGBAFormat,
      UnsignedByteType,
      UVMapping,
      RepeatWrapping,
      RepeatWrapping,
      LinearFilter,
      LinearFilter
    );
    virtualTexture.repeat = new Vector2();
    virtualTexture.offset = new Vector2();
    virtualTexture.needsUpdate = true;
    return virtualTexture;
  }

  private setupPhysicalPages(textureSize: number, pageSize: number, numberOfTextures: number) {
    const slots: PhysicalPage[] = [];
    for (let t = 0; t < numberOfTextures; t++) {
      for (let y = 0; y < textureSize; y += pageSize) {
        for (let x = 0; x < textureSize; x += pageSize) {
          slots.push({
            physicalCoordinates: {
              x,
              y,
              texture: t
            },
            virtualSlots: [],
            permanent: false
          });
        }
      }
    }

    this.emptyPhysicalPage = {
      physicalCoordinates: {
        x: 0,
        y: 0,
        texture: 15
      },
      virtualSlots: [],
      permanent: true
    };
    return slots;
  }

  private setupPhysicalTextures(textureSize: number, numberOfTextures: number) {
    const textures: DataTexture[] = [];

    for (let t = 0; t < numberOfTextures; t++) {
      const physicalTexture = new DataTexture(
        undefined,
        textureSize,
        textureSize,
        RGBAFormat,
        UnsignedByteType,
        UVMapping,
        ClampToEdgeWrapping,
        ClampToEdgeWrapping,
        LinearFilter,
        LinearFilter
      );
      physicalTexture.repeat = new Vector2();
      physicalTexture.needsUpdate = true;
      textures.push(physicalTexture);
    }

    return textures;
  }

  private populateVirtualTextures(items: ItemData<LODSMetaData>[]) {
    items
      .map(item => {
        const bestLod = item.data.lods.reduce((acc, cur) => (acc.lod > cur.lod ? cur : acc));
        return {
          id: item.id,
          lods: item.data.lods,
          maxTextures: bestLod.textures.length
        };
      })
      .sort((a, b) => b.maxTextures - a.maxTextures)
      .forEach(item => {
        this.itemVirtualPagesManager.createItemVirtualPage(item.id.toString(), item.lods);
      });
  }

  private getRequestedItemLODTiles(lod: number) {
    return this.itemsData
      .filter(item => !!item)
      .map(item => {
        const requestedLod =
          item.data.lods?.find(it => it.lod === lod) ??
          item.data.lods?.reduce((best, it) => (Math.abs(best.lod - lod) < Math.abs(it.lod - lod) ? best : it));

        if (!requestedLod) {
          throw Error('Requested lod not found!');
        }

        return {id: item.id, lod: requestedLod};
      });
  }

  private async requestAllPagesForLOD(lod: number, permanent: boolean): Promise<void> {
    const requestedItems = this.getRequestedItemLODTiles(lod);
    if (requestedItems.length === 0) {
      if (permanent) loadingProgress.progressStage(LOADING_STAGES.PERMANENT_TILES_FOR_LOD, 1);
      else loadingProgress.progressStage(LOADING_STAGES.TILES_FOR_LOD, 1);
    }

    const totalTilesForLOD = requestedItems.reduce(
      (total, item) => total + Math.pow(Math.ceil(Math.sqrt(item.lod.textures.length)), 2),
      0
    );

    const promises = [];
    for (const item of requestedItems) {
      const size = Math.sqrt(item.lod.textures.length);
      for (let x = 0; x < size; x++) {
        for (let y = 0; y < size; y++) {
          const promise = this.loadItem(
            {
              id: item.id.toString(),
              lod: item.lod.lod,
              x,
              y
            },
            permanent
          )
            .catch(err => {
              console.error(`Sphere LOD tile loading failed: ${err}`);
            }) // load other tiles if we failed some
            .then(() => {
              if (!permanent) {
                loadingProgress.progressStage(LOADING_STAGES.TILES_FOR_LOD, totalTilesForLOD);
              } else {
                loadingProgress.progressStage(LOADING_STAGES.PERMANENT_TILES_FOR_LOD, totalTilesForLOD);
              }
            });
          promises.push(promise);
        }
      }
    }
    return await Promise.all(promises).then(() => {});
  }

  private async loadVirtualSlot(virtualSlot: VirtualSlot) {
    if (!virtualSlot.url) {
      this.loadEmptyTexture(virtualSlot);
    } else {
      await this.loadTexture(virtualSlot);
    }
  }

  private fetchImageAndLoadPage(virtualSlot: VirtualSlot) {
    const {url} = virtualSlot;
    virtualSlot.requested = true;
    return new Promise<void>((resolve, reject) => {
      this.loader.load(
        url,
        (texture: Texture) => {
          if (!this.disposed) {
            this.addTexture(texture, virtualSlot);
          } else {
            texture.dispose();
          }
          resolve();
        },
        undefined,
        e => {
          console.error('Loading failure:', url, e);
          reject();
        }
      );
    }).finally(() => {
      virtualSlot.requested = false;
    });
  }

  private addTexture(texture: Texture, virtualSlot: VirtualSlot) {
    texture.repeat = new Vector2(0, 0);
    texture.wrapS = ClampToEdgeWrapping;
    texture.wrapT = ClampToEdgeWrapping;
    texture.minFilter = LinearFilter;
    texture.magFilter = LinearFilter;
    texture.offset = new Vector2(0, 0);
    texture.needsUpdate = true;

    const physicalPage = this.pickPhysicalPageToReplace(virtualSlot);

    if (physicalPage) {
      physicalPage.permanent = physicalPage.permanent || virtualSlot.permanent;
      if (physicalPage.imageUrl !== virtualSlot.url) {
        this.clearVirtualSlotsFromPhysicalPage(physicalPage);
        physicalPage.imageUrl = virtualSlot.url;
        this.writeToPhysicalTexture(texture, physicalPage);
      }
      this.linkVirtualSlotToPhysicalPage(virtualSlot, physicalPage);
    }
    texture.dispose();
  }

  private async loadTexture(virtualSlot: VirtualSlot) {
    if (virtualSlot.hasPhysicalPage) {
      this.linkVirtualSlotToPhysicalPage(virtualSlot, virtualSlot.physicalPage);
    } else if (!virtualSlot.requested) {
      await this.fetchImageAndLoadPage(virtualSlot);
    }
  }

  private loadEmptyTexture(virtualSlot: VirtualSlot) {
    this.linkVirtualSlotToPhysicalPage(virtualSlot, this.emptyPhysicalPage);
  }

  private clearVirtualSlotsFromPhysicalPage(physicalPage: PhysicalPage) {
    while (physicalPage.virtualSlots.length > 0) {
      this.unlinkVirtualSlotFromPhysicalPage(physicalPage.virtualSlots[physicalPage.virtualSlots.length - 1]);
    }
    physicalPage.imageUrl = undefined;
  }

  private writeToPhysicalTexture(pageTexture: Texture, physicalPage: PhysicalPage) {
    const {physicalCoordinates} = physicalPage;

    this.renderer.copyTextureToTexture(
      new Vector2(physicalCoordinates.x, physicalCoordinates.y),
      pageTexture,
      this.physicalTextures[physicalCoordinates.texture]
    );
  }

  private linkVirtualSlotToPhysicalPage(virtualSlot: VirtualSlot, physicalPage: PhysicalPage) {
    if (virtualSlot.hasPhysicalPage && virtualSlot.physicalPage !== physicalPage) {
      console.error('Linking a virtual slot which is already linked to a different page');
    }

    if (physicalPage.imageUrl !== virtualSlot.url) {
      console.error('Linking to the wrong physical page');
    }

    if (!virtualSlot.hasPhysicalPage) {
      virtualSlot.physicalPage = physicalPage;
      physicalPage.virtualSlots.push(virtualSlot);
    }
    this.updatePageTableTexture(virtualSlot);
  }

  private unlinkVirtualSlotFromPhysicalPage(virtualSlot: VirtualSlot) {
    const {physicalPage} = virtualSlot;

    if (!virtualSlot.hasPhysicalPage) {
      console.error('Unlinking a virtual slot with no physical page');
    }

    if (physicalPage.virtualSlots.length === 0) {
      console.error('Unlinking from empty physical page');
    }

    ArrayUtils.removeFromArray(virtualSlot, physicalPage.virtualSlots);

    virtualSlot.physicalPage = undefined;

    this.replaceVirtualSlotWithHigherLOD(virtualSlot);
  }

  private replaceVirtualSlotWithHigherLOD(virtualSlot: VirtualSlot) {
    const slotToDowngradeTo = virtualSlot.gethigherLODVirtualSlot();
    if (slotToDowngradeTo) {
      this.updatePageTableTexture(slotToDowngradeTo, virtualSlot);
    } else {
      console.error('Missing permanent root LOD');
    }
  }

  private updatePageTableTexture(virtualSlot: VirtualSlot, slotToDowngrade?: VirtualSlot) {
    const {physicalPage, url, uv} = virtualSlot;

    if (!url) {
      return;
    }

    if (!physicalPage) {
      console.error('Trying to load the texture for a slot without a physical page');
      return;
    }

    if (physicalPage.imageUrl !== url) {
      console.error('Virtual slot connected to the wrong physicalpage', physicalPage.imageUrl, url);
      return;
    }

    if (uv.height !== uv.width) {
      console.error('UV height and width should be the same for the time being!');
    }

    const {vtCoordinates} = slotToDowngrade ?? virtualSlot;

    const texelDimension = vtCoordinates.size;
    const newPageData = new Uint8Array(texelDimension * texelDimension * 4);

    const physicalPageSize = this.pageSize * uv.width;
    const baseCoordinateX = physicalPage.physicalCoordinates.x + uv.x * this.pageSize;
    const baseCoordinateY = physicalPage.physicalCoordinates.y + (1 - uv.y) * this.pageSize - physicalPageSize;
    const virtualPageSize = virtualSlot.vtCoordinates.size;

    const formattedPixelData = formatVirtualTextureBytes(
      baseCoordinateX,
      baseCoordinateY,
      virtualPageSize,
      physicalPageSize,
      physicalPage.physicalCoordinates.texture
    );

    for (let i = 0; i < texelDimension * texelDimension; i++) {
      const p = i * 4;

      newPageData[p] = formattedPixelData[0];
      newPageData[p + 1] = formattedPixelData[1];
      newPageData[p + 2] = formattedPixelData[2];
      newPageData[p + 3] = formattedPixelData[3];
    }

    const pageTableCoords = new Vector2(vtCoordinates.x, vtCoordinates.y);

    const pixelTexture = new DataTexture(newPageData, texelDimension, texelDimension, RGBFormat);
    pixelTexture.needsUpdate = true;
    this.renderer.copyTextureToTexture(pageTableCoords, pixelTexture, this.virtualTableTexture);
    pixelTexture.dispose();
  }

  private pickPhysicalPageToReplace(virtualSlot: VirtualSlot) {
    let basePriority = 0;
    if (this.viewport && virtualSlot.center) {
      basePriority = this.virtualSlotPriority(virtualSlot, virtualSlot.lod);
    }

    const bestPhysicalPageCandidate: {
      priority: number;
      physicalPage?: PhysicalPage;
    } = {
      // don't unload tiles which are "better" than the one we are trying to load
      priority: basePriority,
      physicalPage: undefined
    };

    for (let i = 0; i < this.physicalPages.length; i++) {
      const physicalPage = this.physicalPages[i];

      if (physicalPage.imageUrl === virtualSlot.url) {
        return physicalPage;
      }

      if (physicalPage.virtualSlots?.length === 0) {
        return physicalPage;
      }

      if (physicalPage.permanent) {
        continue;
      }

      if (!!this.viewport) {
        const priority = this.physicalPagePriority(physicalPage, virtualSlot.lod);
        if (priority > bestPhysicalPageCandidate.priority) {
          bestPhysicalPageCandidate.priority = priority;
          bestPhysicalPageCandidate.physicalPage = physicalPage;
        }
      }
    }
    if (bestPhysicalPageCandidate.physicalPage === undefined) {
      if (!this.viewport) {
        console.error("Couldn't pick a page to replace, because no viewport was provided");
      }
    }

    return bestPhysicalPageCandidate.physicalPage;
  }

  private physicalPagePriority(physicalPage: PhysicalPage, lod: number): number {
    return physicalPage.virtualSlots.reduce((min, virtualSlot) => {
      return Math.min(min, this.virtualSlotPriority(virtualSlot, lod));
    }, Number.POSITIVE_INFINITY);
  }

  private virtualSlotPriority(virtualSlot: VirtualSlot, lod: number): number {
    const distance = this.distanceFromViewport(virtualSlot);
    const lodDiff = virtualSlot.lod - lod;
    const priority = lodDiff > 0 ? 5 : 1; // prefer to unload higher LOD tiles
    return (1 + distance) * (1 + priority * Math.abs(lodDiff));
  }

  private distanceFromViewport(virtualSlot: VirtualSlot): number {
    return this.modulo.normalDistanceVB(virtualSlot.center, this.viewport).length();
  }

  private makeUniforms(item: ItemData<LODSMetaData>) {
    const virtualPage = this.itemVirtualPagesManager.getItemVirtualPage(item.id.toString());
    if (!virtualPage) {
      console.error("Can't produce uniforms for an unknown item");
      return undefined;
    }
    const virtualCoordinates = virtualPage.virtualCoordinates;
    const virtualCoordinatesVector = new Vector3(virtualCoordinates.x, virtualCoordinates.y, virtualCoordinates.size);

    const paddedSize = new Vector2();
    const paddedResolution = item.data.full_size;
    if (paddedResolution) {
      paddedSize.set(...paddedResolution);
    } else {
      const bestLod = item.data.lods.reduce((best, lod) => (lod.lod < best.lod ? lod : best));
      paddedSize.set(1, 1).multiplyScalar(bestLod.gridSize * this.pageSize);
    }

    const contentSize = new Vector2();
    const fitResolution = item.data.fit_size;
    if (fitResolution) {
      contentSize.set(...fitResolution);
    } else {
      const naturalResolution = item.data?.naturalResolution;
      const originalSize = naturalResolution ? new Vector2(...naturalResolution) : paddedSize.clone();
      const aspectRatio = originalSize.x / originalSize.y;
      contentSize.set(Math.min(1, aspectRatio), 1 / Math.max(1, aspectRatio)).multiply(paddedSize);
    }
    const contentRatio = contentSize.clone().divide(paddedSize);
    const contentOffset = new Vector2(1.0, 1.0).sub(contentRatio).multiplyScalar(0.5);

    const uniforms = {
      virtualTableTexture: {value: this.virtualTableTexture},
      virtualCoordinates: {value: virtualCoordinatesVector},
      tableSize: {value: VIRTUAL_TEXTURE_SIZE},
      physicalTextureSize: {value: this.textureSize},
      opacity: {value: item.data.opacity ?? 1},
      borderSize: {value: this.vtParams.pageBorderWidth},
      offset: {value: contentOffset},
      contentRatio: {value: contentRatio}
    };

    for (let t = 0; t < this.physicalTextures.length; t += 1) {
      uniforms[`physicalTexture${t}`] = {value: this.physicalTextures[t]};
    }

    return uniforms;
  }
}
