export const isMacOS = /mac/i.test(navigator.platform);
/*
* https://stackoverflow.com/questions/57776001/how-to-detect-ipad-pro-as-ipad-using-javascript
* Workaround to detect iPad after iOS update, affected version is iOS 13 and above
*/
export const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  || (isMacOS && navigator.maxTouchPoints && navigator.maxTouchPoints > 2);

export const isAppleDevice = /iPhone|iPad|iPod/i.test(navigator.userAgent)
  || (isMacOS && navigator.maxTouchPoints && navigator.maxTouchPoints > 2);

export const isTouchSupported = 'ontouchstart' in window
  || navigator.maxTouchPoints > 0;
