import {FontSettings} from './planogram-fonts.interface';

export interface SharingButton {
    id: number;
    enabled: boolean;
    title: string;
    color: string;
    sharing_button_font: FontSettings;
    button_icons: Array<SharingButtonIcon>;
    sharing_providers: Array<SharingProviderIcon>;
}

export interface SharingButtonIcon {
    id: number;
    url: string;
    icon_state: string;
    thumbnails: any;
}

export interface SharingProviderIcon {
    id: number;
    title: string;
    enabled: boolean;
    identifier: ShareProviderTypes;
    icon_custom_url: string;
    icon_default_url: string;
}

export enum ShareProviderTypes {
    WHATSAPP = 'whatsapp',
    TELEGRAM = 'telegram',
    VIBER = 'viber',
    TWITTER = 'twitter',
    COPY_LINK = 'copy_link'
}

export enum ShareButtonIconTypes {
    DEFAULT = 'default',
    ACTIVE = 'active'
}
