import {Product} from '../../interfaces/product.interface';
import {L10nUtils} from '../../utils/l10n_utils';

export const checkoutView = (product: Product) => {
  const ASSETS_PREFIX = !!REQUEST_PREFIX ? REQUEST_PREFIX : '';
  return `
    <div id="checkout-details-container">
      <div class="app-product-overlay">
        <div class="app-product-overlay-controls">
          <div class="overlay-button overlay-button-back" role="button" tabindex="0">
            <span></span>
          </div>
          <div class="app-product-details-header">
            <div class="app-product-code">
              ${product.identifier}
            </div>
            <div class="app-product-title ">
              ${product.name}
            </div>
          </div>
          <div class="overlay-button overlay-button-close" role="button" tabindex="0">
            <span></span>
          </div>
        </div>
        <div class="app-product-content-wrapper">
          <div class="checkout-grid is-hidden">
            <div id="rakuten-store" class="item-store">
              <div class="store-logo">
                <img src="${ASSETS_PREFIX}/assets/img/icons/icon-lego.svg" alt="">
              </div>
            </div>
            <div id="ebay-store" class="item-store">
              <div class="store-frame">
                <div class="store-bar">
                  <span class="amount">45</span>
                  <div class="logo">
                    <img src="${ASSETS_PREFIX}/assets/img/icons/icon-ebay.svg" alt="">
                  </div>
                  <a href="#" class="btn-expand">
                    <img src="${ASSETS_PREFIX}/assets/img/icons/icon-expand.svg" alt="">
                  </a>
                  <span id="ebay-store-pager" class="amount is-hidden">1/2</span>
                </div>
                <a href="#" class="slider-controls prev">
                    <img src="${ASSETS_PREFIX}/assets/img/icons/icons-arrow-slim-left.svg" alt="">
                </a>
                <div class="grid-wrapper">
                  <div class="checkout-grid inner-grid"></div>
                </div>
                <a href="#" class="slider-controls next">
                    <img src="${ASSETS_PREFIX}/assets/img/icons/icons-arrow-slim-left.svg" alt="">
                </a>
              </div>
            </div>
          </div>
          <div class="checkout-loader-container">
            <img src="${ASSETS_PREFIX}/assets/img/icons/checkout-loader.svg" alt="Loader">
            <span>${L10nUtils.l10n('checkout-overlay.loader.text')}</span>
          </div>
        </div>
      </div>
    </div> 
  `;
};
