import {Product} from '../../../../interfaces/product.interface';
import {L10nUtils} from '../../../../utils/l10n_utils';

export const productFieldsComponentView = (product: Product, showDetails  = false) => {
  return `
    <div class="app-shopify-product-additional-fields-container">
      <div class="app-shopify-product-additional-field ${showDetails ? 'active' : ''}">
        <div class="app-shopify-product-additional-field-title-wrapper">
          <div class="app-shopify-product-additional-field-title">${L10nUtils.l10n('shopify-product-overlay.product-field-title')}</div>
          <span class="app-shopify-product-additional-field-icon"></span>
        </div>
        <div class="app-shopify-product-additional-field-content">${product.description}</div>
      </div>
    </div>
  `;
};
