import {URLS} from '../api/urls';
import {Planogram} from '../planogram';
import {WebUtils} from '../utils/web_utils';
import {ShareLink} from './product-info/services/share-link';

export class ShareOverlay {
  container: HTMLElement;

  constructor(container: HTMLElement, planogram: Planogram, url: string, private close: () => void) {
    this.container = container;
    container.innerHTML = '';
    const root = document.createElement('div');
    root.classList.add('share-overlay');
    this.container.appendChild(root);

    const holder = document.createElement('div');
    holder.classList.add('share-holder');
    root.appendChild(holder);

    const closeButton = document.createElement('img');
    closeButton.classList.add('share-button', 'share-close-button');
    closeButton.setAttribute('src', URLS.OVERLAY_SHARE_CLOSE_ICON);
    closeButton.addEventListener('click', this.closeOverlay.bind(this));
    holder.appendChild(closeButton);

    holder.addEventListener('click', e => e.stopPropagation());
    root.addEventListener('click', this.closeOverlay.bind(this)); // close on click  outside on mobile

    planogram.sharingButton.sharing_providers.forEach(provider => {
      const api = ShareLink.shareAPIs[provider.identifier];
      const icon = provider.icon_custom_url ?? provider.icon_default_url;
      const button = document.createElement('img');
      button.classList.add('share-button');
      button.setAttribute('src', icon);
      if (api) {
        const popupUrl = `${api}${encodeURIComponent(url)}`;
        button.addEventListener('click', () => ShareLink.buildPopupWindow(popupUrl));
      } else {
        button.addEventListener('click', () => WebUtils.copyToClipboard(url));
      }
      holder.appendChild(button);
    });
  }

  private closeOverlay(e?: Event): void {
    if (e) {
      e.stopPropagation();
    }
    this.close();
  }

  handleClick(e) {
    return e.target.classList.contains('iframe-overlay');
  }
}
