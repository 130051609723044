import {URLS} from './api/urls';

const clickedElementId = 'errors-clicked-element';

function showErrorOverlay(outerHTML, clickCallback) {
  const element = document.createElement('div');

  document.body.appendChild(element);
  element.outerHTML = outerHTML;

  if (!clickCallback) {
    return;
  }
  const clickedElement = document.getElementById(clickedElementId);
  if (!clickedElement) {
    return;
  }
  clickedElement.addEventListener('click', clickCallback);
}

export class ErrorOverlay {
  static show500Error() {
    const data = `
    <div class="errors-overlay">
      <picture>
        <source media="(max-width: 480px)" srcset="${URLS.ERROR_500_BG_IMAGE_MOBILE}">
        <source media="(max-width: 1024px)" srcset="${URLS.ERROR_500_BG_IMAGE_TABLET}">
        <img class="errors-bg-image" src="${URLS.ERROR_500_BG_IMAGE}">
      </picture>
      <div class="errors-main-content-wrapper no-select">
          <h1>THE SPHERE\nIS CURRENTLY DOWN</h1>
          <span id="${clickedElementId}">Please, try again later</span>
      </div>
    </div>`;

    showErrorOverlay(data, () => {
      location.reload();
    });
  }

  static show404Error() {
    const data = `
    <div class="errors-overlay">
      <img class="errors-bg-image" src="${URLS.ERROR_404_BG_IMAGE}"/>
      <div class="errors-main-content-wrapper no-select">
          <img class="errors-main-image" src="${URLS.ERROR_404_MAIN_IMAGE}"/>
          <h2>GOT LOST?</h2>
          <span id="${clickedElementId}">Let's take you home <img src="${URLS.ERROR_404_FORWARD_ARROW_IMAGE}"/></span>
      </div>
    </div>`;

    showErrorOverlay(data, () => {
      if (URLS.HOME_SPHERE === window.location.href) {
        window.location.reload();
        return;
      }
      window.location.href = URLS.HOME_SPHERE;
    });
  }

  static hide() {
    const element = document.querySelector('body > .errors-overlay');
    element?.remove();
  }
}
