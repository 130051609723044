import {Product} from '../../../../../interfaces/product.interface';
import {InputFieldView} from '../../components/input-field/input-field-component.view';
import {SelectFieldView} from '../../components/select-field/select-field-component.view';

export const InputFieldsLetterKeychainView = (product: Product) => {
  return `
    <div>
        <div class="input-field-wrapper input-field-wrapper__2">
            ${SelectFieldView(
              {
                label: product.input_fields[0].name,
                initialOptionLabel: '---',
                options: ['Mr', 'Mrs', 'Ms', 'Miss', 'Dr']
              })}
        </div>
        <div class="input-field-row">
          <div class="input-field-wrapper input-field-wrapper__2">
              ${InputFieldView({label: product.input_fields[1].name, placeholder: 'H', maxlength: 1})}
          </div>
          <div class="input-field-wrapper input-field-wrapper__2">
              ${InputFieldView({label: product.input_fields[2].name, placeholder: 'Potter', maxlength: 13})}
          </div>
        </div>
    </div>
  `;
};
