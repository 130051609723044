import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';
import {SMAAPass} from 'three/examples/jsm/postprocessing/SMAAPass';
import {BlurPass} from 'postprocessing';
import {Box2, Clock, Color, Mesh, Raycaster, Scene, Vector3, WebGLRenderer, sRGBEncoding} from 'three';
import {VTPipeline} from './vt/vt_pipeline';
import {BASE_FOV, Camera} from './camera';
import {SphereItems} from './sphere_items';
import {Planogram} from './planogram';
import {BrowserUtils} from './utils/browser_utils';
import {SphereShape} from './maths/sphereShape';
import {normalizeMouse} from './utils/math_utils';

const BLUR_RESOLUTION_WIDTH = 960;

export default class CanvasRenderer {
  // TODO: make private?
  readonly camera: Camera;

  private scene: Scene;
  private renderer: WebGLRenderer;
  private readonly canvas: HTMLCanvasElement;
  private composer: EffectComposer;
  private passes: {renderPass: RenderPass; blurPass: BlurPass; smaaPass: SMAAPass} = {
    renderPass: undefined,
    blurPass: undefined,
    smaaPass: undefined
  };
  private vtPipeline: VTPipeline;
  private disposed = false;
  private clock = new Clock();

  // TODO: refactor to separate planogram and rendering logic more
  initiateSphere(planogram: Planogram) {
    return new SphereItems(planogram, this.scene, this.vtPipeline);
  }

  constructor(canvas: HTMLCanvasElement, planogram: Planogram, sphereShape: SphereShape) {
    {
      this.scene = new Scene();
      this.scene.background = new Color(...(planogram.backgroundColor ?? []));
    }

    {
      this.camera = new Camera(planogram, sphereShape);
      this.camera.updateAspect();
      this.camera.updateCamera();
    }

    this.renderer = new WebGLRenderer({
      canvas,
      antialias: false,
      alpha: true,
      stencil: false,
      depth: true
    });
    {
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.autoClear = false;
    }

    {
      this.composer = new EffectComposer(this.renderer);
      this.passes.renderPass = new RenderPass(this.scene, this.camera.perspectiveCamera);
      this.passes.renderPass.clear = false;

      this.composer.addPass(this.passes.renderPass);

      this.passes.smaaPass = new SMAAPass(window.innerWidth, window.innerHeight);
      this.composer.addPass(this.passes.smaaPass);

      this.passes.blurPass = new BlurPass({
        width: BLUR_RESOLUTION_WIDTH
      });
      this.composer.addPass(this.passes.blurPass);
    }

    this.resetComporesAndEffects();

    {
      this.vtPipeline = new VTPipeline(
        planogram.virtualTexture,
        this.renderer,
        BrowserUtils.isMobileSafari() ? 5 : 7,
        4096,
        this.camera,
        planogram
      );
      this.camera.onUpdate(() => {
        if (this.disposed) return;
        const viewport = this.camera.getViewport();
        this.vtPipeline.updateViewport(viewport);
        this.viewportListeners.forEach(it => it(viewport));
      });
    }
  }

  initialize() {
    this.vtPipeline.updateViewport(this.camera.getViewport());
    return this.vtPipeline.initialize();
  }

  blur(kernelSize?: number) {
    this.passes.blurPass.enabled = kernelSize !== undefined;
    this.passes.blurPass.kernelSize = kernelSize ?? 0;
  }

  start() {
    this.camera.updateCamera();
    this.renderer.setAnimationLoop(() => this.render());
  }

  resize() {
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.composer.setSize(window.innerWidth, window.innerHeight);
    this.passes.smaaPass.setSize(window.innerWidth, window.innerHeight);

    this.renderer.setRenderTarget(null);
    this.camera.updateAspect();
    this.camera.zoomIn(BASE_FOV);
    this.camera.updateCamera();
    this.composer.render();
  }

  private viewportListeners: Array<(viewport: Box2) => void> = [];

  onViewportChange(callback: (viewport: Box2) => void) {
    this.viewportListeners.push(callback);
  }

  private resetComporesAndEffects() {
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.composer.removePass(this.passes.renderPass);
    this.passes.renderPass = new RenderPass(this.scene, this.camera.perspectiveCamera);
    this.passes.renderPass.clear = false;
    this.composer.insertPass(this.passes.renderPass, 0);

    this.passes.smaaPass.setSize(window.innerWidth, window.innerHeight);
    this.composer.reset();

    this.composer.setSize(window.innerWidth, window.innerHeight);
  }

  private render() {
    const dt = this.clock.getDelta();
    this.vtPipeline.update(dt);
    this.composer.render();
    this.renderListeners.forEach(it => it(dt));
  }

  private renderListeners: Array<(dt: number) => void> = [];

  onRender(callback: (dt: number) => void) {
    this.renderListeners.push(callback);
  }

  getInteractableObjectAtScreenCoordinate(x: number, y: number): {mesh: Mesh; point: Vector3} {
    const coords = normalizeMouse(x, y);
    const raycaster = new Raycaster();
    raycaster.setFromCamera(coords, this.camera.perspectiveCamera);
    const intersects = raycaster.intersectObjects(this.scene.children, true);

    // Filter intersection that are closer than the near clipping plane.
    // Since the camera, and thus the origin of the raycaster, is located outside of the sphere
    // it can happens that when raycasting items on the opposite side of the sphere are also intersected.
    const topIntersection = intersects
      .filter(i => i.distance > Camera.NEAR_CLIPPING)
      .sort((a, b) => {
        return b.object.renderOrder - a.object.renderOrder;
      })[0];

    // Layer 2 is reserved for elements reacting to input
    const intersection = topIntersection?.object.layers.isEnabled(2) ? topIntersection : undefined;

    if (!intersection) {
      return {mesh: undefined, point: undefined};
    }

    return {mesh: intersection.object as Mesh, point: intersection.point};
  }

  dispose() {
    this.vtPipeline?.dispose();
    this.renderer.setAnimationLoop(null);
    this.viewportListeners.splice(0, this.viewportListeners.length);
    this.renderListeners.splice(0, this.renderListeners.length);
    this.disposed = true;
  }
}
