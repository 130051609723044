import {EcommercePlatformCurrency} from '../interfaces/planogram.interface';

export class CurrencyUtils {
  static getPriceWithSymbolOrCode(price: string, code: string, currenciesList: Array<EcommercePlatformCurrency>) {
    const currencyData = currenciesList.find((item) => item.name === code);

    return currencyData.symbol ? currencyData.symbol + price : `${price} ${code}`;
  }

  static getPriceForHarryPotterWithSymbolOrCode(price: string, code: string, currenciesList: Array<EcommercePlatformCurrency>) {
    const currencyData = currenciesList.find((item) => item.name === code);
    const formattedPrice = price ? price.split('.') : '0.00'.split('.');

    return currencyData.symbol ?
      `${currencyData.symbol}${formattedPrice[0]}<sup>.${formattedPrice[1]}</sup>` :
      `${formattedPrice[0]}<sup>.${formattedPrice[1]}</sup> ${code}`;
  }
}
