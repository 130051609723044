import {button} from '../components/button';
import {input} from '../components/input';
import {L10nUtils} from '../../utils/l10n_utils';

export const signInView = () => {
  return `
    <div class="account-view" id="account-sign-in">
      <div class="account-overlay-title">
        <span>${L10nUtils.l10n('account.panel.sign-in.title')}</span>
      </div>
      <form id="sign-in-form" class="account-overlay-form">
        <div class="account-overlay-fields-with-error">
          <div class="account-overlay-fields">
            ${input({
              placeholder: L10nUtils.l10n('account.panel.input.email.placeholder'),
              name: 'email',
              type: 'email',
              required: true
            })}
            ${input({
              placeholder: L10nUtils.l10n('account.panel.input.password.placeholder'),
              name: 'password',
              type: 'password',
              required: true
            })}
          </div>
          <div class="account-overlay-error-container">
            <div class="account-overlay-error is-hidden"></div>
          </div>
        </div>
        ${button({
          text: L10nUtils.l10n('account.panel.sign-in.submit-button.text'),
          type: 'submit',
          className: 'sign-in-button'
        })}
      </form>
      <div class="link-to-reset-pass">
        <span>${L10nUtils.l10n('account.panel.sign-in.link-to-reset-pass.text')}</span>
      </div>
      ${button({
        text: L10nUtils.l10n('account.panel.sign-up.submit-button.text'),
        className: 'link-to-sign-up',
        secondary: true
      })}
    </div>
  `;
};
