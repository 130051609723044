import './styles/main.scss';
import './global-typings.ts';
import {RESTRICTED_SUBDOMAIN_NAMES} from './app/shared/constants';
import {sphereAppInitialise} from './app/initialise';

window.addEventListener('load', () => {
  const planogramName = window.location.pathname.split('/').slice(1)[0];

  sphereAppInitialise(planogramName);
});
