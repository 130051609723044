import {SphereItem} from '../sphere_item';
import {UrlUtils} from '../api/urls';
import {ImageMetaData} from '../interfaces/planogram.interface';

export class ImageView {
  constructor(private container: HTMLElement, private item: SphereItem, private closeCallback: Function) {
    const data = item.data as ImageMetaData;
    const thumbnailUrl = (data.picture || data)?.thumbnails?.VARIANT_LARGE_WEBP;

    const imageUrl = UrlUtils.generateThumbnailURL(data.picture?.url || data.url || '', thumbnailUrl);
    this.container.innerHTML = `
    <div class="app-image-view-container" aria-live="polite" >
      <div class="overlay-button overlay-button-close" role="button" tabindex="0">
        <span></span>
      </div>
      <img role="img" aria-label="${data.description || ''}" src="${imageUrl}"/>
    </div>
    <div class="app-overlay-backdrop"></div>`;
  }

  private closeOverlay(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    if (this.closeCallback) {
      this.closeCallback();
    } else {
      console.error('"Close" function is not set up');
    }
  }

  handleClick(e) {
    return e.target.tagName !== 'IMG';
  }
}
