import {Options} from '@splidejs/splide';

import {productInfoSelfridgesView} from './product-info-selfridges.view';
import {ProductSlides} from '../../product_slides';
import {ProductInfoBase} from './product-info-base_overlay';

const sanitizeHtml = require('sanitize-html');

export class ProductInfoSelfridges extends ProductInfoBase {
  protected initDescriptionControls() {
    // No description
  }

  protected setItemContent() {
    const description = sanitizeHtml(this.product.description);
    const sanitizedProduct = {...this.product, description};

    this.container.innerHTML = productInfoSelfridgesView(sanitizedProduct, this.item.planogram.name);
  }

  protected setThumbsSwitchLength() {
    if (this.gallerySplide && this.thumbnailSplide) {
      const thumbsLength = this.thumbnailSplide.length - 1;
      const maxLength = window.innerWidth > 1366 ? 4 : 3;
      const perPage = Math.min(thumbsLength, maxLength);

      this.thumbnailSplide.options = {perPage, focus: perPage - 1};
      this.gallerySplide.emit('resize', {});
      this.thumbnailSplide.emit('resize', {});
    }
  }

  protected getThumbsCarouselConfig(productSlides: ProductSlides): Options {
    return {
      fixedWidth: 137,
      height: 137,
      gap: 18,
      speed: 150,
      rewind: true,
      pagination: false,
      isNavigation: true,
      lazyLoad: 'sequential',
      start: productSlides.imageIndex,
      perMove: 1,
      perPage: Math.min(productSlides.length - 1, 4),
      focus: 3,
      keyboard: false,
      breakpoints: {
        2560: {
          fixedWidth: 92,
          height: 92,
          gap: 18
        },
        1921: {
          fixedWidth: 70,
          height: 70,
          gap: 16
        },
        1366: {
          fixedWidth: 60,
          height: 60,
          gap: 20,
          perPage: Math.min(productSlides.length - 1, 3),
          focus: 2
        },
        1024: {
          fixedWidth: 52,
          height: 52,
          gap: 16
        }
      }
    };
  }
}
