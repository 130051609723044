import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';

import {ShopifyProduct, ShopifyVariant} from '../../../../../interfaces/shopify-product.interface';
import {ShopifyService} from '../../../../../api/services/shopify.service';
import {OutOfStockComponentView} from './out-of-stock-component.view';
import {SphereApp} from '../../../../../sphere_app';
import {BrowserUtils} from '../../../../../utils/browser_utils';
import {CDN_RESOURCES, UrlUtils} from '../../../../../api/urls';
import {ShoppingCartService} from '../../../../../api/services/shopping-cart.service';
import {OutOfStockService} from '../../../../../api/services/out-of-stock.service';
import {Account} from '../../../../../account/account';
import {AppUtils} from '../../../../../utils/app_utils';
import {CLIENT_NAMES} from '../../../../../shared/constants';
import {Metrics} from '../../../../../metrics';
import {MATOMO_EVENT_NAMES} from '../../../../../metric-events';

export class OutOfStockComponent {
  private variant: ShopifyVariant;
  private inputField: HTMLInputElement;
  private notifyMeButton: HTMLElement;
  private inputFieldWrapper: HTMLElement;
  private mainContentContainer: HTMLElement;
  private responseContainer: HTMLElement;
  private agreementContainer: HTMLElement;
  private isAnonymous: boolean = true;
  private outOfStockService: OutOfStockService;
  private readonly selectedProductSubjectRef: Function;

  constructor(
    private sphereApp: SphereApp,
    private mainContainer: HTMLElement,
    private product: ShopifyProduct,
    private shopifyService: ShopifyService,
    private shoppingCartService: ShoppingCartService
  ) {
    this.outOfStockService = new OutOfStockService();

    this.selectedProductSubjectRef = pipe(
      this.shopifyService.selectedProductSubject,
      subscribe({
        next: (variant: any) => {
          if (variant) {
            this.variant = variant;
            this.renderComponent();
          }
        }
      })
    );
  }

  private renderComponent() {
    const outOfStockContainer = this.mainContainer.querySelector('.app-shopify-out-of-stock-container');
    outOfStockContainer.innerHTML = '';

    if (this.variant.inventory_quantity || this.variant.isAutoSelected) {
      return;
    }

    const outOfStock = document.createElement('div');
    outOfStockContainer.append(outOfStock);
    outOfStock.outerHTML = OutOfStockComponentView(this.product);
    this.mainContentContainer = outOfStockContainer.querySelector('.app-shopify-out-of-stock-form');
    this.responseContainer = outOfStockContainer.querySelector('.app-shopify-out-of-stock-response');
    this.agreementContainer = outOfStockContainer.querySelector('.app-shopify-out-of-stock-agreement');
    this.inputFieldWrapper = outOfStockContainer.querySelector('.input-field-wrapper');
    this.inputField = outOfStockContainer.querySelector('#out-of-stock-email');
    this.notifyMeButton = outOfStockContainer.querySelector('#notify-me-button');

    this.notifyMeButton.onclick = () => this.handleButtonClicks();
  }

  private async handleButtonClicks() {
    if (this.notifyMeButton.classList.contains('loading')) {
      return;
    }

    if (this.shoppingCartService.getMultipassToken || Account.isLogged) {
      this.isAnonymous = false;
    }

    if (this.isAnonymous && this.inputFieldWrapper.classList.contains('is-hidden')) {
      this.inputFieldWrapper.classList.remove('is-hidden');
      this.agreementContainer.classList.remove('is-hidden');
      return;
    }

    if (this.isAnonymous && !this.inputField.reportValidity()) {
      return;
    }

    const country = await this.outOfStockService.getCountry(this.product.shop_name) || '';
    const shopName = AppUtils.isHarryPotterClient(this.sphereApp.planogram, this.sphereApp.planogram.clientName)
      && this.sphereApp.planogram.clientName === CLIENT_NAMES.HARRY_POTTER_US ?
      'HP_SHOP_US' : 'HP_SHOP_UK';
    const data = {
      email: this.isAnonymous ? this.inputField.value : this.shoppingCartService.email,
      country,
      shopName,
      productId: this.product.identifier,
      variantId: this.variant.id,
      productName: this.product.name,
      productImageUrl: this.getImageUrl(),
      productUrl: window.location.href,
      variantName: [this.variant.option1, this.variant.option2, this.variant.option3].filter(Boolean).join(' ')
    };

    Metrics.storeTheEvent(
      this.sphereApp.planogram.name,
      'click',
      MATOMO_EVENT_NAMES.CLICK_ECOMMERCE_OVERLAY_NOTIFY_ME_BUTTON(
        this.product?.identifier,
        UrlUtils.slugify(this.product?.name)
      )
    );
  }

  private getImageUrl() {
    const image = this.product.product_images.find(img => {
      return img.shopify_id === this.variant.image_id || img.id === this.variant.image_id;
    }) || this.product.product_images[0];
    const path = BrowserUtils.pickImageVariant(image.variants, false, true);

    return UrlUtils.insertValueToUrl(CDN_RESOURCES.PRODUCT_IMAGE, path);
  }

  dispose() {
    this.selectedProductSubjectRef();
  }
}
