export class CustomEventsHandler {
  private events: object = {};

  listen(eventNames: string, fn: Function): void {
    eventNames.split(' ').forEach(eventName => {
      if (!this.events[eventName]) {
        this.events[eventName] = [];
      }
      this.events[eventName].push(fn);
    });
  }

  emit(eventName: string, options?: object): void {
    const event = this.events[eventName];
    if (event) {
      event.forEach(fn => {
        fn.call(null, options);
      });
    }
  }

  off(eventNames: string, fn: Function): void {
    eventNames.split(' ').forEach(eventName => {
      const event = this.events[eventName];
      if (event) {
        const index = event.indexOf(fn);
        if (index >= 0) {
          event.splice(index, 1);
        }
      }
    });
  }
}

const overlayEventsHandler = new CustomEventsHandler();
const sphereEventHandler = new CustomEventsHandler();
const debugEventHandler = new CustomEventsHandler();
const searchEventHandler = new CustomEventsHandler();
const shopifyOverlayHandler = new CustomEventsHandler();

export {overlayEventsHandler, sphereEventHandler, debugEventHandler, searchEventHandler, shopifyOverlayHandler};
