import {ItemData, ShapesMetaData} from '../interfaces/planogram.interface';
import {SphereItem} from '../sphere_item';
import {Planogram} from '../planogram';
import {SphereGeometry} from '../geometries/sphere_geometry';
import RectangleBorderGeometry from '../geometries/rectangle_border_geometry';

import rectangleBorderVertexShader from '../../shaders/rectangle_border_vertex_shader.glsl';
import rectangleBorderFragmentShader from '../../shaders/rectangle_border_fragment_shader.glsl';
import rectangleFillFragmentShader from '../../shaders/rectangle_fill_fragment_shader.glsl';
import standardVertexShader from '../../shaders/standard_vertex_shader.glsl';
import {DoubleSide, Group, Mesh, ShaderMaterial, Vector2} from 'three';

export class RectangleComponent extends SphereItem {
  borderGeometry: RectangleBorderGeometry;
  borderMaterial: ShaderMaterial;
  fillAzimuthStartRadians: number;

  constructor(itemData: ItemData, planogram: Planogram) {
    super(itemData, planogram);
    this.createMaterials();
  }

  createMesh() {
    const {thickness, cornerRadius, soft} = this.data as ShapesMetaData;

    this.geometry = this.generateFillGeometry();
    const fill = new Mesh(this.geometry, this.material);
    fill.renderOrder = this.renderOrder;
    fill.userData = {
      component: this
    };

    this.object3D = new Group();
    this.object3D.renderOrder = this.renderOrder;
    this.object3D.add(fill);

    if (this.hasInput()) {
      fill.layers.enable(2);
      this.object3D.layers.enable(2);
    }

    if (thickness > 0) {
      this.borderGeometry = new RectangleBorderGeometry(
        this.planogram,
        [this.width, this.height, 1],
        this.x,
        this.y,
        thickness,
        cornerRadius,
        soft
      );
      const border = new Mesh(this.borderGeometry, this.borderMaterial);
      border.renderOrder = this.renderOrder + 0.5;
      border.userData = {
        component: this
      };

      if (this.hasInput()) {
        border.layers.enable(2);
      }
      this.object3D.add(border);
    }

    return Promise.resolve();
  }

  createMaterials() {
    const {borderColor, fillColor, thickness, cornerRadius, dashed, soft, indentation} = this.data as ShapesMetaData;

    this.borderMaterial = new ShaderMaterial({
      vertexShader: rectangleBorderVertexShader,
      fragmentShader: rectangleBorderFragmentShader,
      depthTest: false,
      uniforms: {
        scale: {value: [this.width, this.height, 1]},
        borderColor: {value: borderColor},
        thickness: {value: thickness},
        cornerRadius: {value: cornerRadius},
        soft: {value: soft},
        dashed: {value: dashed}
      },
      transparent: true,
      side: DoubleSide
    });

    this.material = new ShaderMaterial({
      vertexShader: standardVertexShader,
      fragmentShader: rectangleFillFragmentShader,
      depthTest: false,
      uniforms: {
        fillColor: {value: fillColor},
        thickness: {value: thickness},
        indentation: {value: indentation},
        cornerRadius: {value: cornerRadius},
        width: {value: Math.abs(this.width) - 2 * indentation},
        height: {value: Math.abs(this.height) - 2 * indentation}
      },
      transparent: true,
      side: DoubleSide
    });
  }

  generateFillGeometry(): SphereGeometry {
    const {indentation} = this.data as ShapesMetaData;

    const reflectedWidth = this.width > 0 ? 0 : this.width;
    const reflectedHeight = this.height > 0 ? 0 : this.height;

    this.fillAzimuthStartRadians = SphereGeometry.calcAzimuthStartRadians(
      this.x,
      Math.abs(this.width) - indentation + reflectedWidth,
      this.planogram.width
    );
    const azimuthLength = SphereGeometry.calcAzimuthLengthRadians(
      Math.abs(this.width) - 2 * indentation,
      this.planogram.width
    );
    const cols = Math.ceil(azimuthLength / ((2 * Math.PI) / Planogram.COLUMN_COUNT));
    const rows = Math.ceil((Math.abs(this.height) - 2 * indentation) / (this.planogram.height / Planogram.ROW_COUNT));

    return new SphereGeometry(
      Planogram.ALPHA,
      this.planogram.largeRadius,
      this.planogram.fixedRadius,
      cols,
      rows,
      this.fillAzimuthStartRadians,
      azimuthLength,
      this.planogram.height,
      this.y + indentation + reflectedHeight,
      Math.abs(this.height) - 2 * indentation,
      true,
      new Vector2(0, 0),
      new Vector2(1, 1)
    );
  }
}
