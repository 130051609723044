import {MatomoEcommerceInterface} from './interfaces/matomo.ecommerce.interface';
import {CookiesManagement} from './cookies_management';

type MatomoEvent = [string, ...(string | number)[]];
type GAEvent = {
  event: string;
  pagePath?: string;
  pageTitle?: string;
  eventName?: string;
  eventCategory?: string;
  eventAction?: string;
};

declare global {
  interface Window {
    _paq?: Array<MatomoEvent>;
    dataLayer?: Array<GAEvent>;
    // this function is added on BE side as a part of gtm snippet
    gtag: Function;
  }
}

export class Metrics {
  private static matomoEvent(name: string, data: Array<string | number>) {
    if (!window._paq) {
      return;
    }
    window._paq.push([name, ...data]);
  }

  private static googleEvent(event: GAEvent) {
    if (!window.dataLayer) {
      return;
    }
    window.dataLayer.push(event);
  }

  static updateTitle() {
    Metrics.matomoEvent('setDocumentTitle', [document.title]);
  }

  static onUrlChange(): void {
    const url = window.location.pathname;
    Metrics.matomoEvent('setCustomUrl', [url]);
    Metrics.matomoEvent('trackPageView', []);
    Metrics.googleEvent({ event: 'PageView' });
  }

  static setGoogleConsent(value: boolean, cookiesTogglesTracking = false) {
    if (!window.dataLayer) {
      return;
    }
    const consentMode = {
      'ad_storage': value ? 'granted' : 'denied',
      'analytics_storage': value ? 'granted' : 'denied',
      'personalization_storage': value && !cookiesTogglesTracking ? 'granted' : 'denied',
      'functionality_storage': value && !cookiesTogglesTracking ? 'granted' : 'denied',
      'security_storage': value && !cookiesTogglesTracking ? 'granted' : 'denied'
    };
    window.gtag('consent', 'update', consentMode);
  }

  static storeTheEvent(eventCategory: string, eventAction: string, eventName: string): void {
    // TODO move this logic to HeatmapService after merging updates from DP release
    CookiesManagement.updateLastActionTime();
    Metrics.matomoEvent('trackEvent', [eventCategory, eventAction, eventName]);
    Metrics.googleEvent({
      event: 'sphere_event',
      eventName,
      eventCategory,
      eventAction
    });
  }

  static storeEcommerceEvent(params: MatomoEcommerceInterface): void {
      // An addEcommerceItem push should be generated for each cart item
    // productSKU (Required) – String – A unique product identifier.
    // productName (Recommended) – String – The name of the product.
    // categoryName (Optional) – String/Array – This is either the category name passed as a string
    // or up to five unique categories as an array e.g. ["Books", "New Releases", "Technology"]
    // price (Optional) – Integer/Float – The cost of the item.
    // quantity (Optional) – Integer – How many of this item are in the cart. Defaults to 1.
    // window._paq.push(['addEcommerceItem', params.sku, params.productName, '', params.productPrice]);
    // Pass the Cart's Total Value as a numeric parameter
    // window._paq.push(['trackEcommerceCartUpdate', params.productPrice]);
    Metrics.matomoEvent('setEcommerceView', [params.sku, params.productName, params.category, params.productPrice]);
    Metrics.matomoEvent('trackPageView', []);
  }

  static allowUseCookies(): void {
    Metrics.matomoEvent('setCookieConsentGiven', []);
    Metrics.matomoEvent('setConsentGiven', []);
  }

  static declineCookies(): void {
    Metrics.matomoEvent('forgetCookieConsentGiven', []);
    Metrics.matomoEvent('forgetConsentGiven', []);
  }
}
