import Cookies from 'js-cookie';
import {isTouchSupported} from './utils/platform';
import {sphereEventHandler} from './custom_event_utils';
import {SPHERE_EVENT_NAMES as EVENTS} from './event-names';
import {COOKIES_ATTRIBUTES} from './shared/constants';
import {entranceGuideView} from './entrance-guide.view';
import {WindowOrientationSupervisor} from './window-orientation/window-orientation.supervisor';
import {WebUtils} from './utils/web_utils';
import {AppUtils} from './utils/app_utils';
import {FontLoader} from './font_loader';
import {ANIMATION_TYPE, EntranceAnimationSettings} from './interfaces/planogram.interface';
import {Metrics} from './metrics';
import {AppState} from './shared/app.state';
import {MATOMO_EVENT_NAMES} from './metric-events';
import {CookiesManagement} from './cookies_management';

export class EntranceGuide {
  static clientName: string;
  static planogramName: string;
  static settings: EntranceAnimationSettings;
  private static wrapper: HTMLElement;
  private static zoomIndicator: HTMLElement;
  private static dragIndicator: HTMLElement;
  private static clickIndicator: HTMLElement;
  private static inactivityTimeout: number;
  private static clickIndicatorCloseTimeout: number;
  private static clickInactivityTimeout: number;

  static get MS_BEFORE_SHOW() {
    return 1000;
  }

  static get MAIN_INACTIVITY_MS() {
    return 10000;
  }

  static get INDICATOR_DISPLAY_MS() {
    return 1500;
  }

  static init(skipWindowOrientationPopup = false) {
    if (!WindowOrientationSupervisor.isOverlayShown() &&
      !skipWindowOrientationPopup &&
      !AppUtils.isSassenbachClient(this.clientName, this.planogramName) &&
      !EntranceGuide.settings.hide_guidance
    ) {
      WindowOrientationSupervisor.trackWindowOrientation();
      return;
    }
    if (Cookies.get('entrance_animation') && !EntranceGuide.settings.repeat_animation) {
      return;
    }
    this.storeAnimationSettings();
    this.initView();
    this.wrapper = document.getElementById('entrance-guide');
    this.zoomIndicator = document.querySelector('#entrance-guide > .zoom-indicator');
    this.dragIndicator = document.querySelector('#entrance-guide > .drag-indicator');
    this.clickIndicator = document.querySelector('#entrance-guide > .click-indicator');
    this.onElementHandler = this.onElementHandler.bind(this);
    this.onSphereInit = this.onSphereInit.bind(this);
    this.clear = this.clear.bind(this);
    this.show = this.show.bind(this);
    this.destroy = this.destroy.bind(this);

    this.start();
  }

  private static initView() {
    const entranceGuideElement = document.createElement('div');
    document.body.appendChild(entranceGuideElement);
    entranceGuideElement.outerHTML = entranceGuideView();
  }

  private static storeAnimationSettings() {
    const root = document.documentElement;
    const bgColorRgb = AppUtils.hex2rgb(EntranceGuide.settings.background_color);

    root.style.setProperty(
      `--entrance-animation-background-color`,
      EntranceGuide.settings.background_color
    );
    root.style.setProperty(`--entrance-animation-background-color-rgb`, bgColorRgb);
    EntranceGuide.settings.entrance_animation_fonts.forEach(animation => {
      FontLoader.mountCustomFonts([
        { ...animation.desktop, assignment: `entrance-animation-${animation.action_type}-desktop` },
        { ...animation.mobile, assignment: `entrance-animation-${animation.action_type}-mobile` }
      ]);
    });
  }

  private static start() {
    if (Cookies.get('entrance_animation') && !EntranceGuide.settings.repeat_animation) {
      return;
    }
    if (this.wrapper && this.zoomIndicator && this.dragIndicator) {
      this.wrapper.classList.add(isTouchSupported ? 'with-touch' : 'with-scroll');
      this.inactivityTimeout = window.setTimeout(this.show, this.MS_BEFORE_SHOW);

      sphereEventHandler.listen(EVENTS.SPHERE.INIT, this.onSphereInit);
      sphereEventHandler.listen([EVENTS.OVERLAY.SHOW_CONTENT, EVENTS.SPHERE.RESET].join(' '), this.destroy);
    } else {
      console.error('EntranceGuide elements not found');
    }
  }

  private static destroy() {
    this.clear();
    sphereEventHandler.off([EVENTS.OVERLAY.SHOW_CONTENT, EVENTS.SPHERE.RESET].join(' '), this.destroy);
    this.wrapper.classList.add('is-hidden');
    this.zoomIndicator?.classList.add('is-hidden');
    this.dragIndicator?.classList.add('is-hidden');
    this.clickIndicator?.classList.add('is-hidden');
    this.wrapper.classList.remove('fade-in', 'fade-out');
    this.zoomIndicator?.classList.remove('bounce-in', 'bounce-out');
    this.dragIndicator?.classList.remove('bounce-in', 'bounce-out');
    this.clickIndicator?.classList.remove('bounce-in', 'bounce-out');
  }

  private static clear() {
    clearTimeout(this.inactivityTimeout);
    sphereEventHandler.off([EVENTS.CAMERA.ZOOM_TO_POINT, EVENTS.TILT_AND_PAN_TO].join(' '), this.clear);
  }

  private static onSphereInit() {
    clearTimeout(this.clickIndicatorCloseTimeout);
    clearTimeout(this.clickInactivityTimeout);
    sphereEventHandler.off(EVENTS.SPHERE.INIT, this.onSphereInit);
    this.destroy();
    this.start();
  }

  private static async show() {
    this.clear();

    this.wrapper.classList.remove('is-hidden');
    this.wrapper.classList.add('fade-in');

    for (const animation of this.settings.entrance_animation_fonts) {
      if (animation.hidden) { continue; }
      if (this.settings.with_animation && animation.action_type === ANIMATION_TYPE.ZOOM) {
        sphereEventHandler.emit(EVENTS.ENTRANCE_ANIMATION.ZOOM_ANIMATION);
      }
      if (this.settings.with_animation && animation.action_type === ANIMATION_TYPE.DRAG) {
        sphereEventHandler.emit(EVENTS.ENTRANCE_ANIMATION.DRAG_ANIMATION);
      }
      await this.guideStep(
        this[`${animation.action_type}Indicator`],
          resolve => window.setTimeout(resolve, this.INDICATOR_DISPLAY_MS),
        this.onElementHandler.bind(
          this,
          this[`${animation.action_type}Indicator`],
          animation.complete,
          animation.action_type
        )
      );
    }
  }

  private static onElementHandler(elem, complete) {
    if (complete) {
      return () => {
        Cookies.set('entrance_animation', 'shown', {
          expires: 365,
          domain: WebUtils.getCookieDomain(),
          sameSite: COOKIES_ATTRIBUTES.SAME_SITE_LAX
        });
        setTimeout(this.completeAnimationHandler.call(this, elem), this.INDICATOR_DISPLAY_MS, () =>
          sphereEventHandler.off(EVENTS.SPHERE.INIT, this.onSphereInit)
        );
      };
    }
    return resolve => {
      elem.classList.add('bounce-out');
      elem.addEventListener(
        'animationend',
        () => {
          elem.classList.add('is-hidden');
          resolve();
        },
        {once: true}
      );
    };
  }

  private static completeAnimationHandler(elem) {
    return (onComplete = Function()) => {
      elem.classList.remove('bounce-in');
      this.wrapper.classList.remove('fade-in');
      this.wrapper.classList.add('fade-out');
      this.wrapper.addEventListener(
        'animationend',
        e => {
          if (e.target === this.wrapper) {
            onComplete();
            this.destroy();
          }
        },
        {once: true}
      );
    };
  }

  private static guideStep(el: HTMLElement, before = Function(), onComplete = Function()): Promise<any> {
    let savedListener;
    return new Promise(resolve => {
      savedListener = resolve;
      el.classList.remove('is-hidden');
      el.classList.add('bounce-in');
      before(resolve);
    }).then(() => {
      return new Promise(resolve => {
        onComplete(savedListener)(resolve);
      });
    });
  }
}
