import {Vector2} from 'three';

const reusableProjectionVector = new Vector2(0, 0);

/**
 * Takes a vector to project and returns the vector component
 * in the direction of the projection vector
 *
 * @param {Vector2} vectorToProject Vector to project onto projectionDirection
 * @param {Vector2} projectionDirection Direction of the projection
 * @param {Vector2} outputVector Vector to apply the final projection coordinates to
 */

export const createVector2Projection = (
  vectorToProject: Vector2,
  projectionDirection: Vector2,
  outputVector: Vector2
) => {
  reusableProjectionVector.copy(projectionDirection).divideScalar(projectionDirection.length());

  outputVector.copy(reusableProjectionVector).normalize().multiplyScalar(vectorToProject.dot(reusableProjectionVector));
};

/**
 * Returns a 3/2 longer array than inputed, inserting the zValue into every third place
 * of the array, effectively returning a 3D representation out of a 2D input.
 *
 * @param {number[] | Float32Array} array2D Array of numbers, representing two dimensions (length of 2 * n)
 * @param {Vector2} zValue A value to insert as the z-value in the three-dimensional representation
 *
 * @returns {number []} Array of numbers, representing three dimensions (length of 3 * n)
 */
export const array2Dto3D = (array2D: number[] | Float32Array, zValue = 0) => {
  // inserts the static Z value to every third place in the array, outputing a 3/2 longer array
  const array3D = new Array((array2D.length / 2) * 3).fill(0);

  for (let i = 0; i < array3D.length; i++) {
    array3D[i] = array2D[Math.ceil((2 / 3) * i)];

    if ((i + 1) % 3 === 0) {
      array3D[i] = zValue;
    }
  }

  return array3D;
};

/**
 * Creates a fan of multiple Vector2 directions by inserting a new vector in between
 * the two given start and end directions. For each division the function recursively
 * adds another vector in between each two adjacent vectors in the current fan of vectors.
 * The number of final vectors is 2 to the number of divisions.
 * 'endDirection' is not included in the returned array.
 *
 * @param {Vector2} startDirection The first direction in a fan of vectors
 * @param {Vector2} endDirection The final direction in a fan of vectors
 * @param {number} divisions The number of times the vectors should be doubled in density
 *
 * @returns {Vector2[]} Array of vectors representing a fan of vectors, exclusive endDirection
 */
export const makeFanOfVectors2 = (startDirection: Vector2, endDirection: Vector2, divisions: number): Vector2[] => {
  if (divisions <= 0) {
    return [startDirection.clone()];
  }

  const midDirection = new Vector2().copy(startDirection).add(endDirection).divideScalar(2).normalize();

  if (divisions <= 1) {
    return [startDirection.clone(), midDirection];
  }
  return [
    ...makeFanOfVectors2(startDirection, midDirection, divisions - 1),
    ...makeFanOfVectors2(midDirection, endDirection, divisions - 1)
  ];
};

/**
 * Makes a triangle index for rectangle corner vertices. The corner vertices are arranged
 * into athe triangle strip pattern. Due to mirroring of corners, the odd corners have reveresed
 * triangle edge directions.
 *
 * @param {Vector2} cornerVertexCount Number of triangle-strip-arranged corner vertices
 * @param {Vector2} cornerNumber Number of the corner, starting with bottom left, going clockwise
 *
 * @returns {number[]} Index for vertices of a single corner
 */
export const makeRectangleCornerIndex = (cornerVertexCount: number, cornerNumber: number) => {
  const triangleCount = cornerVertexCount - 2;
  const isOddCorner = cornerNumber % 2 === 1;

  return makeTriangleStripIndex(triangleCount, 0, isOddCorner);
};

/**
 * Makes a triangle index for rectangle side-line vertices. Lines can be segmented into multiple shorter
 * rectangular segments (so they can be curved to sphere). Vertices and are arranged into triangle strips.
 * The function returns index for all four lines, correctly offset one after the other.
 *
 * @param {number} xSegmentCount Number of segments (quads) in the horizontal side-lines
 * @param {number} ySegmentCount Number of segments (quads) in the vertical side-lines
 *
 * @returns {number[]} Index for vertices of all four sidelines
 */
export const makeRectangleLineIndex = (xSegmentCount: number, ySegmentCount: number) => {
  const xTriangleCount = xSegmentCount * 2;
  const yTriangleCount = ySegmentCount * 2;

  const offsetX = xSegmentCount * 2 + 2;
  const offsetY = ySegmentCount * 2 + 2;

  return [
    ...makeTriangleStripIndex(yTriangleCount),
    ...makeTriangleStripIndex(xTriangleCount, offsetY),
    ...makeTriangleStripIndex(yTriangleCount, offsetY + offsetX),
    ...makeTriangleStripIndex(xTriangleCount, offsetY + offsetX + offsetY)
  ];
};

/**
 * Creates an index for an arbitrary number of triangles arranged into a triangle strip.
 *
 * @param {number} triangleCount Number of triangles to index
 * @param {number} offset Integer offset for all values of the index
 * @param {boolean} reverseEdgeDirections Revereses the edge directions for each triangle
 *
 * @returns {number[]} Triangle strip index
 */
export const makeTriangleStripIndex = (triangleCount: number, offset = 0, reverseEdgeDirections = false) => {
  const index: number[] = new Array(triangleCount * 3);

  for (let triangleNumber = 0; triangleNumber < triangleCount; triangleNumber++) {
    const triangleIndex = [0 + triangleNumber, 1 + triangleNumber, 2 + triangleNumber];

    const isOddTriangle = triangleNumber % 2 === 1;
    const shouldReverseIndexOrder = isOddTriangle !== reverseEdgeDirections;

    if (shouldReverseIndexOrder) {
      triangleIndex.reverse();
    }

    index[triangleNumber * 3] = triangleIndex[0] + offset;
    index[triangleNumber * 3 + 1] = triangleIndex[1] + offset;
    index[triangleNumber * 3 + 2] = triangleIndex[2] + offset;
  }

  return index;
};

/**
 * Creates the UV attribute for all four rectangle side-lines. Shader expects UV's to go from 0 to 4
 * in the line's length dimension and from -0.5 to -1.5 in the line's thickness dimension.
 *
 * @param {number} xSegmentCount Number of segments (quads) in the horizontal side-lines
 * @param {number} ySegmentCount Number of segments (quads) in the vertical side-lines
 *
 * @returns {number[]} Array of UV's for segmented rectangle side-lines
 */
export const makeRectangleLineUvs = (xSegmentCount: number, ySegmentCount: number) => {
  const xVertexCount = (xSegmentCount + 1) * 2;
  const yVertexCount = (ySegmentCount + 1) * 2;

  const xLineUvs: number[] = new Array(xVertexCount * 2);
  const yLineUvs: number[] = new Array(yVertexCount * 2);

  for (let segmentNumber = 0; segmentNumber < xSegmentCount + 1; segmentNumber++) {
    // outer vertex
    xLineUvs[segmentNumber * 4] = (segmentNumber / xSegmentCount) * 4;
    xLineUvs[segmentNumber * 4 + 1] = -0.5;

    // inner vertex
    xLineUvs[segmentNumber * 4 + 2] = (segmentNumber / xSegmentCount) * 4;
    xLineUvs[segmentNumber * 4 + 3] = -1.5;
  }

  for (let segmentNumber = 0; segmentNumber < ySegmentCount + 1; segmentNumber++) {
    // outer vertex
    yLineUvs[segmentNumber * 4] = (segmentNumber / ySegmentCount) * 4;
    yLineUvs[segmentNumber * 4 + 1] = -0.5;

    // inner vertex
    yLineUvs[segmentNumber * 4 + 2] = (segmentNumber / ySegmentCount) * 4;
    yLineUvs[segmentNumber * 4 + 3] = -1.5;
  }

  return [...yLineUvs, ...xLineUvs, ...yLineUvs, ...xLineUvs];
};

/**
 * Creates the lineLength attribute for all four rectangle side-lines. All vertices in a line have the
 * same lineLength value. The value is used by the fragment shader when drawing dashes.
 *
 * @param {number} xLineLength Length of the rectangle border's horizontal side-lines
 * @param {number} yLineLength  Length of the rectangle border's vertical side-lines
 * @param {number} xSegmentCount Number of segments (quads) in the horizontal side-lines
 * @param {number} ySegmentCount Number of segments (quads) in the vertical side-lines
 *
 * @returns {number[]} Array of lineLength values for vertices of all four side-lines of the rectangle
 */
export const makeRectangleLineLengths = (
  xLineLength: number,
  yLineLength: number,
  xSegmentCount: number,
  ySegmentCount: number
) => {
  const xVertexCount = (xSegmentCount + 1) * 2;
  const yVertexCount = (ySegmentCount + 1) * 2;

  const xLineLengths: number[] = new Array(xVertexCount).fill(xLineLength);
  const yLineLengths: number[] = new Array(yVertexCount).fill(yLineLength);

  return [...yLineLengths, ...xLineLengths, ...yLineLengths, ...xLineLengths];
};

/**
 * Creates the vertex positions for a segmented rectangle border side-line. Lines are split into segments
 * in order to be able to be projected onto curved sphere. The output positions are centered - the result of
 * the function are positions which need to be moved onto the correct side of the rectangle.
 *
 * @param {number} width The width of the line (= 2 * thickness)
 * @param {number} length The length of the line
 * @param {number} segmentCount Number of segments (quads) in the line
 * @param {boolean} isVertical Draws a vertical line if true, a horizontal if false
 *
 * @returns {number[]} Centered vertex positions of a single rectangle side-line (2D representation)
 */
export const makeRectangleLinePoints = (width: number, length: number, segmentCount: number, isVertical: boolean) => {
  const positions2D: number[] = new Array((segmentCount + 1) * 2 * 2);

  for (let i = 0; i <= segmentCount; i++) {
    const fraction = i / segmentCount;
    const lengthOffset = fraction * length - length / 2;
    const widthOffset = width / 2;

    if (isVertical) {
      positions2D[i * 4] = widthOffset;
      positions2D[i * 4 + 1] = lengthOffset;

      positions2D[i * 4 + 2] = -widthOffset;
      positions2D[i * 4 + 3] = lengthOffset;
    } else {
      positions2D[i * 4] = lengthOffset;
      positions2D[i * 4 + 1] = widthOffset;

      positions2D[i * 4 + 2] = lengthOffset;
      positions2D[i * 4 + 3] = -widthOffset;
    }
  }

  return positions2D;
};
