// @ts-ignore
import {OverlayScrollbars} from 'overlayscrollbars';
import {WebUtils} from '../utils/web_utils';

function getScrollData(scrollInstance: OverlayScrollbars) {
  const {viewport} = scrollInstance.elements();
  return {
    position: {
      y: viewport.scrollTop
    },
    max: {
      y: viewport.scrollHeight - viewport.clientHeight
    }
  };
}

export class OverlayScrollbar {
  constructor(private selector: string, options = {}) {
    this.initScroll(options);
  }

  private initScroll(options) {
    if (!this.selector) {
      return;
    }

    // Add a custom scroll bar to the scroll areas
    document.querySelectorAll(this.selector).forEach((wrapper: HTMLElement) => {
      let scrollInstance: OverlayScrollbars;
      scrollInstance = OverlayScrollbars(
        wrapper,
        {
          paddingAbsolute: true,
          overflow: {
            x: 'hidden'
          },
          scrollbars: {
            autoHide: options.autoHide ?? 'scroll'
          }
        },
        {
          scroll(osInstance) {
            const scrollData = getScrollData(osInstance);
            WebUtils.handleTextFadeOnScroll(wrapper, scrollData);
          },
          updated(osInstance) {
            const scrollData = getScrollData(osInstance);
            WebUtils.handleTextFadeOnScroll(wrapper, scrollData);
          }
        }
      );
      WebUtils.handleTextFadeOnScroll(wrapper, getScrollData(scrollInstance));
    });
  }
}
