import {SphereItem} from './sphere_item';
import {VTPipeline} from './vt/vt_pipeline';
import {Planogram} from './planogram';
import {ProductSlides} from './product_slides';
import {CLUSTER_NAME_REGEX, SPHERE_ITEM_TYPES} from './shared/constants';
import {TextComponent} from './components/text';
import {VideoComponent} from './components/video';
import {
  ACTION_TYPE,
  AnimateActionData,
  ClusterActionData,
  CurvesMetaData,
  ImageMetaData,
  ItemData,
  MetaDataType,
  ClusterMetaData,
  ContentOverlayActionData,
  MediaMetaData
} from './interfaces/planogram.interface';
import {CurveComponent} from './components/curve';
import {RectangleComponent} from './components/rectangle';
import {AppUtils} from './utils/app_utils';
import loadingProgress, {LOADING_STAGES} from './api/services/loading_progress.service';
import {disposeObject3D} from './utils/disposeThree';
import {Group, Object3D, Scene} from 'three';

function fetchVideoAspectRatio(url: string): Promise<number> {
  const videoElm = document.createElement('video');
  videoElm.preload = 'metadata';
  videoElm.src = url;
  videoElm.load();
  return new Promise(resolve => {
    videoElm.onloadedmetadata = () => {
      videoElm.remove();
      resolve(videoElm.videoWidth / videoElm.videoHeight);
    };
  });
}

async function fitFeedContent(item: SphereItem) {
  if (item.itemData.instagram_feed !== true) return;

  const size = item.getSize();
  const position = item.getPosition().addScaledVector(size, 0.5);

  let newAspectRatio: number = 1;

  if (item.type === SPHERE_ITEM_TYPES.VIDEO)
    newAspectRatio = await fetchVideoAspectRatio((item.data as MediaMetaData).videoUrl);
  else if (item.type === SPHERE_ITEM_TYPES.IMAGE) {
    const resolution = (item.data as ImageMetaData).naturalResolution;
    newAspectRatio = resolution[0] / resolution[1];
  } else {
    throw new Error(`Unknown instagram feed object of type ${item.type}`);
  }

  const containerAspectRatio = size.x / size.y;
  const contentToContainer = newAspectRatio / containerAspectRatio;

  if (contentToContainer > 1) size.y /= contentToContainer;
  else size.x *= contentToContainer;

  position.addScaledVector(size, -0.5);
  item.overridePosition(position.x, position.y);
  item.overrideSize(size.x, size.y);
}

export class SphereItems {
  readonly items: Array<SphereItem> = [];
  private itemsGroup: Group;

  constructor(private planogram: Planogram, private scene: Scene, private vtPipeline: VTPipeline) {
    this.itemsGroup = new Group();
    this.itemsGroup.layers.enable(2);
    this.scene.add(this.itemsGroup);

    this.items = this.planogram.items.map(item => this.createSphereItem(item));
  }

  loadSphereItems(): Promise<void> {
    const newPickingItemsMap = new Map<string, Object3D>();
    if (this.items.length === 0) loadingProgress.progressStage(LOADING_STAGES.ITEM_MESHES, 1);
    const itemPromises = this.items.map(sphereItem =>
      fitFeedContent(sphereItem)
        .then(() => sphereItem.createMesh())
        .catch(err => {
          console.error(`Sphere item mesh creation failed: ${err}`);
        })
        .then(() => {
          loadingProgress.progressStage(LOADING_STAGES.ITEM_MESHES, this.items.length);
          if (sphereItem.type === SPHERE_ITEM_TYPES.CLUSTER || sphereItem.action?.type === ACTION_TYPE.CLUSTER) {
            return;
          }

          this.itemsGroup.add(sphereItem.object3D);
          const item = sphereItem.object3D.userData.component as SphereItem;
          if (item) {
            newPickingItemsMap.set(String(item.id), sphereItem.object3D);
          }
        })
    );

    this.vtPipeline.setItems(newPickingItemsMap);

    return Promise.all(itemPromises).then(); // discard void[], thanks Typescript
  }

  dispose() {
    this.items.forEach(it => it.dispose());
    disposeObject3D(this.scene);
  }

  private createSphereItem(item: ItemData<MetaDataType>) {
    switch (item.type) {
      case SPHERE_ITEM_TYPES.VIDEO:
        return new VideoComponent(item, this.planogram);
      case SPHERE_ITEM_TYPES.TEXT:
      case SPHERE_ITEM_TYPES.TEXT_AREA:
        return new TextComponent(item, this.planogram);
      case SPHERE_ITEM_TYPES.SHAPE:
        return new RectangleComponent(item, this.planogram);
      case SPHERE_ITEM_TYPES.CURVE:
        return new CurveComponent(item as ItemData<CurvesMetaData>, this.planogram);
      case SPHERE_ITEM_TYPES.IMAGE:
      case SPHERE_ITEM_TYPES.PRODUCT:
        const sphereItem = new SphereItem(item, this.planogram);
        const lodMaterial = this.vtPipeline.createLODMaterial(item);
        sphereItem.material = lodMaterial;
        return sphereItem;
      default:
        return new SphereItem(item, this.planogram);
    }
  }

  findClusterByClusterName(clusterName: string): SphereItem | undefined {
    // handle case with raw value as cluster name
    if (clusterName.match(CLUSTER_NAME_REGEX)) {
      clusterName = AppUtils.extractClusterName(clusterName);
    }
    const foundClusters = this.items.filter(item => {
      return (
        ((item.action?.data as ClusterActionData)?.clusterLink === clusterName &&
          item.action?.type === ACTION_TYPE.CLUSTER) ||
        ((item.action?.data as ClusterActionData)?.clusterLink === clusterName &&
          item.type === SPHERE_ITEM_TYPES.CLUSTER) ||
        ((item.data as ClusterMetaData).clusterLink === clusterName && item.type === SPHERE_ITEM_TYPES.CLUSTER)
      );
    });
    if (foundClusters.length) {
      if (foundClusters.length > 1) {
        console.error('There are more than one cluster with the same name');
      }
      return foundClusters[0];
    }
  }

  findSphereItemByIdentifier(identifier: string): SphereItem | undefined {
    const sphereItems = this.items.filter(item => {
      return item.identifier === identifier || item.id?.toString() === identifier;
    });
    const sphereItem = sphereItems.find(item => item.imageName === ProductSlides.BOX_ART_NAME(item.identifier));

    if (!sphereItem && sphereItems.length > 0) {
      return sphereItems[0];
    }
    return sphereItem;
  }

  findSphereItemByActionIdentifier(identifier: string) {
    return this.items.find(item => {
      return (
        (item.action?.data as AnimateActionData)?.itemUuid === identifier ||
        item.identifier === identifier ||
        item.id?.toString() === identifier
      );
    });
  }

  findSphereItemByImageId(imageId: string) {
    return this.items.find(
      item => ((item.data as ImageMetaData).picture?.id || item.id)?.toString() === imageId
    );
  }

  findSphereItemByContentLink(link: string) {
    return this.items.find(it => (it.action?.data as ContentOverlayActionData)?.iframeLink === link);
  }
}
