export const SPHERE_EVENT_NAMES = {
  CAMERA: {
    ZOOM_BY: 'camera-zoom_by',
    ZOOM_TO_POINT: 'camera-zoom_to_point'
  },
  OVERLAY: {
    SHOW_CONTENT: 'overlay-show_content',
    CLOSE_CONTENT: 'overlay-close_content',
    SHOW_INFO_OVERLAY_CONTENT: 'overlay-show_info_overlay_content'
  },
  CONTROL: {
    CLICK: 'control-click',
    TAP: 'control-click',
    CLICK_AND_TAP: 'control-click control-click',
    CLICK_ITEM: 'control-click-item'
  },
  SPHERE: {
    RESET: 'sphere-reset',
    INIT: 'sphere-init',
    UPDATE: 'sphere-update'
  },
  DEBUG: {
    CAMERA_POSITION: 'debug-camera_position'
  },
  VIDEO: {
    PLAY: 'play',
    PLAY_WITH_AUDIO: 'play-with-audio',
    STOP_WITH_AUDIO: 'stop',
    SHARE: 'video-share'
  },
  TILT_AND_PAN_TO: 'tilt_and_pan_to',
  ON_MOVEMENT_START: 'on_movement_start',
  ANIMATE_ZOOM_FOV: 'animate_zoom_fov',
  AUTOROTATE: 'autorotate',
  PLANOGRAM_CHANGED: 'planogram-changed',
  PERMANENT_TILES_LOAD: 'permanent_tiles-load',
  ACCOUNT: {
    OPEN: 'account-open',
    CLOSE: 'account-close'
  },
  CART: {
    OPEN: 'cart-open',
    CLOSE: 'cart-close'
  },
  COOKIES: {
    ALLOW_AUDIO: 'allow-audio',
    DISABLE_AUDIO: 'disable-audio',
    ALLOW_FULLSCREEN: 'allow-fullscreen',
    DISABLE_FULLSCREEN: 'disable-fullscreen'
  },
  ENTRANCE_ANIMATION: {
    ZOOM_ANIMATION: 'zoom_animation',
    DRAG_ANIMATION: 'drag_animation'
  }
};

export const SEARCH_EVENT_NAMES = {
  UPDATE_SEARCH: 'updateSearch',
  SHOW_SEARCH: 'showSearch',
  CLOSE_SEARCH: 'closeSearch',
  REQUEST_STATUS: 'requestStatus'
};
