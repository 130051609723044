import {Product} from '../../interfaces/product.interface';
import {URLS} from '../../api/urls';

export const productInfoSothebysView = (product: Product, planogramName: string) => {
  const shippingIcon = URLS.OVERLAY_SOTHEBYS_SHIPPING_ICON;
  const authenticityIcon = URLS.OVERLAY_SOTHEBYS_AUTHENTICITY_ICON;

  return `
    <div id="product-details-container" class="app-sothebys-template is-view-gallery">
        <div class="app-product-overlay ${planogramName}-overlay">
            <div class="app-gallery-gradient"></div>
            <div class="app-product-overlay-controls">
              <div class="overlay-button overlay-button-back overlay-back" role="button" tabindex="0">
                <span></span>
              </div>
              <div class="overlay-button overlay-button-close" role="button" tabindex="0">
                <span></span>
              </div>
            </div>
            <div class="app-product-content-wrapper">
              <div class="app-product-details-header">
                <div class="app-product-title">
                  ${product.brand ? product.brand : product.pdp_alternative_title}
                </div>
                <div class="app-product-description">${product.brand ? product.pdp_alternative_title : ''}</div>
                <div class="app-product-shipping ${product.shipping ? '' : 'is-hidden'}"><span class="app-product-shipping-label">Free shipping</span></div>
                <div class="app-product-separator"></div>
              </div>
              <div class="app-product-carousel">
                <div id="app-image-carousel" class="splide">
                  <div class="splide__arrows">
                    <button class="splide__arrow splide__arrow--prev">
                      <span class="overlay-arrow overlay-arrow-prev"></span>
                    </button>
                    <button class="splide__arrow splide__arrow--next">
                      <span class="overlay-arrow overlay-arrow-next"></span>
                    </button>
                  </div>
                  <div class="splide__track">
                    <ul class="splide__list">
                    </ul>
                  </div>
                </div>
                <div class="app-thumbnail-carousel-container">
                  <div class="app-product-gallery-buttons-container">
                    <div id="info-gallery-button" class="no-checkout" role="button" tabindex="0">
                      <div>
                        <img alt="Info icon">
                      </div>
                    </div>
                    <div id="checkout-gallery-button" class="is-completely-hidden" role="button" tabindex="0">
                      <div>
                        <img alt="Checkout icon">
                      </div>
                    </div>
                  </div>
                  <div id="app-thumbnail-carousel" class="splide">
                    <div id="app-thumbnail-splide-arrows" class="splide__arrows">
                      <button class="splide__arrow splide__arrow--prev">
                        <span class="overlay-arrow overlay-arrow-prev"></span>
                      </button>
                      <button class="splide__arrow splide__arrow--next">
                        <span class="overlay-arrow overlay-arrow-next"></span>
                      </button>
                    </div>
                    <div class="splide__track">
                      <ul class="splide__list">
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="app-product-details-container">
                <div class="app-product-price is-hidden">
                  <span class="product-price-title">Price:</span>
                  <span class="product-price-value"><span class="product-price-currency"> USD</span></span>
                </div>
                <div class="app-buy-buttons-wrapper"></div>
                <div class="app-product-information-wrapper">
                  <div class="app-product-information-item">
                    <div class="app-product-information-logo">
                      <img src="${shippingIcon}" alt="product-information-logo">
                    </div>
                    <div class="app-product-information-text">
                      <p class="app-product-information-label">This item ships within the US</p>
                      <p>International shipping coming soon.</p>
                    </div>
                  </div>
                  <div class="app-product-information-item ${product.pdp_title ? '' : 'is-hidden'}">
                    <div class="app-product-information-logo">
                      <img src="${authenticityIcon}" alt="product-information-logo">
                    </div>
                    <div class="app-product-information-text">
                      <p class="app-product-information-label">Authenticity Guaranteed</p>
                      <p>We guarantee the authenticity of this item.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  `;
};
