import {AccountService} from '../../api/services/account.service';

export abstract class AccountBaseOverlay {
  readonly formEl: HTMLFormElement;
  readonly errorEl: HTMLElement;
  protected abstract init(): void;
  protected abstract onSubmit(e): void;

  constructor(public accountService: AccountService, readonly wrapperEl: HTMLElement) {
    this.formEl = this.wrapperEl.querySelector('.account-overlay-form') as HTMLFormElement;
    this.errorEl = this.wrapperEl.querySelector('.account-overlay-error');

    this.formEl.addEventListener('submit', e => {
      this.onSubmit(e);
    });

    this.init();
  }

  protected onError(err) {
    err.json().then(error => this.showError(error.errors.base));
  }

  protected showError(errorText) {
    this.errorEl.classList.remove('is-hidden');
    this.errorEl.innerText = errorText;
  }

  protected hideError() {
    this.errorEl.classList.add('is-hidden');
    this.errorEl.innerText = '';
  }

  clearForm() {
    this.formEl.querySelectorAll('input').forEach(formInput => formInput.value = '');
    this.hideError();
  }

  toggleFields(active: boolean) {
    this.formEl.querySelectorAll('input').forEach(formInput => {
      active ?
        formInput.removeAttribute('disabled') :
        formInput.setAttribute('disabled', 'true');
    })
  }
}
