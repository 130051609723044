import {Vector2} from 'three';
import {InputEventUtils} from '../utils/input_event_utils';
import {CameraControls} from './camera_controls';
import {sphereEventHandler} from '../custom_event_utils';
import {SPHERE_EVENT_NAMES} from '../event-names';
import {InputHandler} from '../input_handler';

const CLICK_MARGIN_OF_ERROR = 5;

export class TouchControls {
  private startPointer: Vector2;
  private previousTouchDistance: number;
  private previousEvent: TouchEvent;
  private pinched: boolean;

  constructor(
    private domElement: HTMLElement,
    private cameraControls: CameraControls,
    private inputHandler: InputHandler
  ) {
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.domElement.addEventListener('touchstart', this.onTouchStart, {passive: false});
    this.domElement.addEventListener('touchend', this.onTouchEnd, {capture: true});
    this.domElement.addEventListener('touchmove', this.onTouchMove, {passive: false});
  }

  private onTouchStart = (event: TouchEvent) => {
    if (InputEventUtils.onSphereSurface(event)) {
      this.startPointer = InputEventUtils.getTouchPosition(event);
      if (event.touches.length > 1) {
        this.previousTouchDistance = InputEventUtils.calculateTouchDistance(event.touches[0], event.touches[1]);
        const pinchCenter = InputEventUtils.getPinchCentre(event.touches[0], event.touches[1]);
        this.cameraControls.onMovementStart(pinchCenter);
      } else {
        this.cameraControls.onMovementStart(this.startPointer);
      }
    }
    this.previousEvent = event;
  };

  private onTouchMove(event: TouchEvent) {
    if (!this.startPointer) {
      return;
    }
    if (this.previousEvent.touches.length !== event.touches.length) {
      this.onTouchStart(event);
      return;
    }
    if (event.cancelable) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.cameraControls.clearAnimation();
    if (event.touches.length > 1) {
      this._pinch(event);
      this.pinched = true;
    } else {
      this.inputHandler.isClusterSelected = false;
      const latestPointer = InputEventUtils.getTouchPosition(event);
      this.cameraControls.tiltAndPanTo(latestPointer);
    }
    this.previousEvent = event;
  }

  private onTouchEnd(event: TouchEvent) {
    if (!this.startPointer) {
      return;
    }
    if (this._isTap(event)) {
      event.preventDefault();
      const position = InputEventUtils.getEndTouchPosition(event);
      sphereEventHandler.emit(SPHERE_EVENT_NAMES.CONTROL.TAP, event);
      this.inputHandler.handleClick(position.x, position.y);
    } else {
      event.stopPropagation();
    }
    if (event.touches.length === 0) {
      this.pinched = false;
      this.cameraControls.onMovementEnd();
      this.startPointer = undefined;
      this.previousEvent = undefined;
    }
  }

  private _pinch(event: TouchEvent) {
    const currentTouchDistance = InputEventUtils.calculateTouchDistance(event.touches[0], event.touches[1]);
    const pinchCenter = InputEventUtils.getPinchCentre(event.touches[0], event.touches[1]);
    const zoomScaleFactor = this.previousTouchDistance / currentTouchDistance;
    this.cameraControls.zoomToPoint(pinchCenter, zoomScaleFactor);
    this.previousTouchDistance = currentTouchDistance;
    this.inputHandler.isClusterSelected = false;
  }

  private _isTap(event: TouchEvent) {
    if (this.pinched || event.touches.length > 0) {
      return false;
    }
    const currentPointer = InputEventUtils.getEndTouchPosition(event);
    const moveDistance = InputEventUtils.distance(this.startPointer, currentPointer);
    return moveDistance < CLICK_MARGIN_OF_ERROR;
  }

  dispose() {
    this.domElement.removeEventListener('touchstart', this.onTouchStart);
    this.domElement.removeEventListener('touchend', this.onTouchEnd, {capture: true});
    this.domElement.removeEventListener('touchmove', this.onTouchMove);
  }
}
