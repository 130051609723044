import makeBehaviorSubject from 'callbag-behavior-subject';
import pipe from 'callbag-pipe';
import take from 'callbag-take';
import skip from 'callbag-skip';
import subscribe from 'callbag-subscribe';
import dropRepeats from 'callbag-drop-repeats';
import {EntranceGuide} from '../entrance-guide';
import {WindowOrientationPopup} from './window-orientation.popup';
import Router from '../router';
import {Metrics} from '../metrics';
import {AppState} from '../shared/app.state';
import {MATOMO_EVENT_NAMES} from '../metric-events';

const sessionStorageKey = 'windowOrientationOverlay';

export enum WINDOW_ORIENTATION {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape'
}

export class WindowOrientationSupervisor {
  static orientationSubject = makeBehaviorSubject<WINDOW_ORIENTATION | null>(null);
  private static popup: WindowOrientationPopup;
  private static readonly INIT_DELAY = 1500;

  static trackWindowOrientation() {
    // Check current state and detect do we need to show popup
    pipe(
      this.orientationSubject,
      take(1),
      subscribe({
        next: (value) => {
          if (!Router.NAVIGATION_PAGES.PLANOGRAM.test(window.location.pathname)
            || value !== WINDOW_ORIENTATION.PORTRAIT) {
            EntranceGuide.init(true);
            return;
          }
          setTimeout(() => {
            this.popup = new WindowOrientationPopup(this.overlayClosed);
            this.handleRotationToLandscape();
          }, this.INIT_DELAY);
        }
      })
    );
  }

  private static handleRotationToLandscape() {
    pipe(
      this.orientationSubject,
      dropRepeats(),
      skip(1) as any,
      subscribe({
        next: (value) => {
          if (this.popup && value !== WINDOW_ORIENTATION.PORTRAIT) {

            Metrics.storeTheEvent(
              AppState.planogramName,
              'rotate',
              `${MATOMO_EVENT_NAMES.ROTATE_MOBILE_ORIENTATION_POPUP}`
            );

            this.popup.closeOverlay();
          }
        }
      })
    );
  }

  static changeWindowOrientation(value: WINDOW_ORIENTATION | null) {
    this.orientationSubject(1, value);
  }

  static isOverlayShown(): boolean {
    return window.sessionStorage.getItem(sessionStorageKey) === 'shown';
  }

  static overlayClosed() {
    this.popup = null;
    window.sessionStorage.setItem(sessionStorageKey, 'shown');
    EntranceGuide.init();
  }
}
