import {InputFieldComponent} from '../../components/input-field/input-field-component';
import {Product} from '../../../../../interfaces/product.interface';
import {SelectFieldComponent} from '../../components/select-field/select-field-component';

export class InputFieldsBaseComponent {
  private fields = new Map<string, InputFieldComponent | SelectFieldComponent | string>();

  constructor(protected container: HTMLElement, protected product: Product, protected shopifyService?) {
  }

  get values() {
    return Array.from(this.fields.keys()).map((key) => ({
      key,
      value: key.startsWith('_') ?
        this.getField(key) :
        (this.getField(key) as InputFieldComponent | SelectFieldComponent).value
    }));
  }

  getField(key: string) {
    return this.fields.get(key);
  }

  setField(key: string, value: InputFieldComponent | SelectFieldComponent | string) {
    this.fields.set(key, value);
  }

  validate() {
    for (const input of this.fields.values()) {
      const isValid = input instanceof (InputFieldComponent || SelectFieldComponent) ? input.validate() : true;

      if (!isValid) {
        return false;
      }
    }

    return true;
  }

  dispose() {}
}
