import {shopifyOverlayHandler} from '../../../../../custom_event_utils';

interface InputFieldComponentOptions {
  onChange?: (value: string) => void;
  lettersOnly?: boolean;
  uppercase?: boolean;
  validationPattern?: string | RegExp;
  liveValidation?: boolean;
}

export class InputFieldComponent {
  private readonly inputEl: HTMLInputElement;
  private options: InputFieldComponentOptions;
  private validationPattern: string | RegExp;
  private liveValidation = true;

  constructor(
    private inputWrapper: Element,
    options?: InputFieldComponentOptions
  ) {
    this.options = options || {};
    this.validationPattern = this.options?.validationPattern || /[^a-zA-Z .'/-]/g;

    if (this.options.liveValidation === false) {
      this.liveValidation = this.options.liveValidation;
    }

    this.inputEl = this.inputWrapper.querySelector('input');
    this.inputEl.addEventListener('input', (e) => this.onInputChange(e));

    this.inputEl.addEventListener('focusin', () => shopifyOverlayHandler.emit('inputFieldFocus'));
    this.inputEl.addEventListener('focusout', () => shopifyOverlayHandler.emit('inputFieldFocus'));
  }

  get value() {
    return this.inputEl.value;
  }

  validate() {
    return this.inputEl.reportValidity();
  }

  setMaxLength(value: number) {
    this.inputEl.setAttribute('maxlength', value.toString());
    this.inputEl.value = this.inputEl.value.slice(0, value);
  }

  private onInputChange(event: Event) {
    const el = event.target as HTMLInputElement;

    if (this.liveValidation) {
      // @ts-ignore
      el.value = el.value.replaceAll(this.validationPattern, '');
    }

    if (this.options.uppercase) {
      el.value = el.value.toUpperCase();
    }

    if (this.options.onChange) {
      this.options.onChange(el.value);
    }

    el.value = el.value.slice(0, this.inputEl.maxLength);
  }
}
