import {Product} from '../../../../interfaces/product.interface';
import {productFieldsComponentView} from './product-fields_component.view';
import {Metrics} from '../../../../metrics';
import {MATOMO_EVENT_NAMES} from '../../../../metric-events';
import {AppState} from '../../../../shared/app.state';
import {UrlUtils} from '../../../../api/urls';
import {EcommerceOverlaySettings} from '../../../../interfaces/ecommerce-overlay-settings';

export class ProductFields {
  private productFields: NodeListOf<HTMLElement>;

  constructor(
    private product: Product,
    private mainContainer: HTMLElement,
    private overlaySettings: EcommerceOverlaySettings
  ) {
    this.initComponent();
  }

  private initComponent() {
    const { show_details } = this.overlaySettings;
    const productFieldsContainer = this.mainContainer.querySelector('.app-shopify-description-container');
    const productFields = document.createElement('div');
    productFieldsContainer.append(productFields);
    productFields.outerHTML = !this.product.description ? '' : productFieldsComponentView(this.product, show_details);
    this.handleClicksOnAdditionalFields();
  }

  private handleClicksOnAdditionalFields() {
    this.productFields = this.mainContainer.querySelectorAll('.app-shopify-product-additional-field-title-wrapper');

    this.productFields.forEach(el => {
      el.addEventListener('click', () => this.addClassToSelectedField(el));
    });
  }

  private addClassToSelectedField(field: HTMLElement) {
    this.productFields.forEach(el => {
      if (el === field) {
        return;
      }
      el.parentElement.classList.remove('active');
    });

    Metrics.storeTheEvent(
      AppState.planogramName,
      'open',
      MATOMO_EVENT_NAMES
        .CLICK_ECOMMERCE_OVERLAY_PRODUCT_DETAILS_DESCRIPTION(
          this.product?.identifier,
          UrlUtils.slugify(this.product?.name)
        )
    );
    field.parentElement.classList.toggle('active');
  }
}
